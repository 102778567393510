import blog_HK from '../blogsContent/blogs_HK';
import pricing_content from "../blogsContent/pricing";
import terms_content from "../blogsContent/terms";

export default {
  title: "暢意科技 - 創新及專案管理系統",
  header: {
    home: "首頁",
    features: "產品功能",
    howitworks: "How It Works",
    testimonials: "Testimonials",
    blog: "資源",
    pricing: "價格方案",
    tryFree: "免費體驗",
    contactus: "Contact Us"
  },
  modal: {
    try: `免費體驗暢意系統`,
    totry: `填寫下方表格，提交後即可免費體驗暢意創新管理系統。我們將盡快與您聯絡為您提供登入信息。請放心，您的信息是絕對安全的。`,
    fullname: `姓名`,
    workemail: `工作電郵`,
    company: `公司名稱`,
    phone: `聯絡電話`,
    optional: `可選`,
    submit: `提交`,
    close: `關閉`
  },
  seo: {
    homePage: {
      title: "暢意科技 | 創新及專案管理系統",
      description: "暢意創新及專案管理系統，幫助企業收集，管理和實施來自員工和客戶的創新想法，助力企業創新發展。",
      keyword: "創新管理, 創新想法管理, 創新管理工具, 創新想法管理工具, 企業創新, 創新管理軟件, 創新管理軟體, 專案管理, 專案管理系統, 專案管理軟件, 專案管理軟體, 專案管理工具",
    },
    blogPage: {
      title: "部落格 | 暢意創新及專案管理系統",
      description: "暢意創新管理系統，幫助企業收集，管理和實施來自員工和客戶的創新想法，助力企業創新發展。",
      keyword: "創新管理, 創新想法管理, 創新管理工具, 創新想法管理工具, 企業創新, 創新管理軟件, 創新管理資源, 創新管理軟體, 專案管理, 專案管理系統, 專案管理軟件, 專案管理軟體, 專案管理工具",
    },
    pricingPage: {
      title: "價格方案 | 暢意創新及專案管理系統",
      description: "暢意創新管理系統，幫助企業收集，管理和實施來自員工和客戶的創新想法，助力企業創新發展。",
      keyword: "創新管理, 創新想法管理, 創新管理工具, 創新想法管理工具, 企業創新, 創新管理軟件, 創新管理軟體, 專案管理, 專案管理系統, 專案管理軟件, 專案管理軟體, 專案管理工具",
    },
    termsPage: {
      title: "條款與條件 | 暢意創新及專案管理系統",
      description: "暢意創新管理系統，幫助企業收集，管理和實施來自員工和客戶的創新想法，助力企業創新發展。",
      keyword: "創新管理, 創新想法管理, 創新管理工具, 創新想法管理工具, 企業創新, 創新管理軟件, 創新管理軟體, 專案管理, 專案管理系統, 專案管理軟件, 專案管理軟體, 專案管理工具",
    },
    aboutUsPage: {
      title: "關於我們 | 暢意創新及專案管理系統",
      description: "暢意創新管理系統，幫助企業收集，管理和實施來自員工和客戶的創新想法，助力企業創新發展。",
      keyword: "創新管理, 創新想法管理, 創新管理工具, 創新想法管理工具, 企業創新, 創新管理軟件, 創新管理軟體, 專案管理, 專案管理系統, 專案管理軟件, 專案管理軟體, 專案管理工具",
    },
    projectMgntPage: {
      title: "專案管理 | 暢意創新及專案管理系統",
      description: "暢意創新管理系統，幫助企業收集，管理和實施來自員工和客戶的創新想法，助力企業創新發展。",
      keyword: "創新管理, 創新想法管理, 創新管理工具, 創新想法管理工具, 企業創新, 創新管理軟件, 創新管理軟體, 專案管理, 專案管理系統, 專案管理軟件, 專案管理軟體, 專案管理工具",
    },
  },
  footer: {
    idex: "暢意科技",
    turnIdea: "將想法轉化為創新成果",
    startCollecting: "現在就開始，在企業內部收集並實施有價值的創新想法！",
    getStarted: "即刻體驗",
    productTitle: "產品",
    productPricing: "價格方案",
    productProjectMgnt: "專案管理",
    productIdeaMgnt: "想法與創新管理",
    companyTitle: "公司",
    companyBlog: "部落格",
    companyAboutUs: "關於我們",
    companyTerms: "條款與條件",
  },
  headSection: {
    moveForward: "推動企業創新發展",
    transform: " 暢意創新及專案管理系統，幫助企業收集，管理和實施創新想法",
    expIDEX: "即刻免費體驗",
    video: "https://www.youtube.com/watch?v=Jb8lDt6EaxE"
  },
  benefits: {
    trial_info: "您可以免費試用商業版 14 天，並隨時降級到基礎版。",
    employee: "收集更多想法，創造更好的產品",
    engage:
      "讓企業的所有利益相關者，包括企業的員工，股東，供應商等合作，完善和選擇最佳想法。培養企業創新氛圍和文化，提升產品，研發過程和客戶滿意度。",
    continuous: "保持有序運營，增強團隊凝聚力",
    stakeholder:
      "在同一個空間裡發散思維和管理專案，打破由部門，語言和地理帶來的障礙。通過全面的路線圖，掌握不同團隊和專案的最新情況。",
    high: "高投資回報率",
    crowdsource:
      "在管理日常任務​​的同時心懷企業的願景。在同一平台裡完成任務，同時與最了解企業的人一起開創未來。逐步的變化外加突破性的創新，實現高投資回報率。"
  },
  pricing_info: {
    save30: "節省30%",
    monthly: "按月收費",
    annual: "按年收費",
    select_team_size: "選擇您的團隊規模"
  },
  howworks: {
    title: "產品概覽",
    ideate: "發散思維 & 評估篩選",
    harness:
      "充分利用集體的創新力量，讓每個員工都能提出創新想法，並選擇公開或匿名提交。發掘並選擇最佳想法，並把它們轉化為任務來實施。",
    engage: "制定計劃 & 高效管理",
    easysearch:
      "創建專案來管理您的工作流程。您可將專案用於任何事情，從管理敏捷計劃、日程安排、正在進行的流程或產品代辦需求。",
    evaluate: "多維可視 & 高效實施",
    prioritize:
      "在列表、看板或日曆視圖之間切換以讓您的工作高度可視。使用列表視圖確定任務的優先級並分配它們。使用看板視圖確定任務的階段並專注於手頭的事情。使用日曆視圖來規劃一段時間內的任務，並為整個團隊提供一個共同的工作願景。",
  },
  trusty: {
    title: "我們的客戶",
    identify:
      "我們使用暢意軟體來捕捉我們員工的創新想法，將這些想法轉化為可操作的專案，並使用專案管理進行任務的分配及進度的跟進。將我們所有的信息集中在一個平台，這為我們節省了大量時間。",
    beforeIDEX:
      "在使用暢意創新及專案系統之前，我們一直都在使用電郵交換和管理創新想法。現在我們的員工可以非常輕鬆地在一個平台上提交他們的想法。暢意平台確實幫我們在收集想法和選擇可實施的想法上提高了不少效率。",
    card1Name: "Matt Stewart",
    card1Position: "總經理",
    card2Name: "Jason Peterson",
    card2Position: "產品總監"
  },
  about_us: {
    title: "關於我們",
    description1: "我們是一家以創新為導向的公司，為新的想法和技術而生，並支持大大小小的團隊進行創新並讓他們的想法變為現實。", 
    description2: "我們是一支由創新專家和技術達人組成的團隊，我們重視個人貢獻和團隊合作。就個人而言，我們精通於自己的領域；組合在一起，我們是一個敏捷的團隊，可以適應任何變化。", 
    description3: "我們整個團隊都在遠程工作。我們中的一些人素未謀面，但我們朝著共同的願景，即，讓創新發揮作用，讓工作更有效率而共同努力。",
    valueTitle: "我們的核心價值觀",
    valueSubtitle1: "多元的角度",
    valueSubtitle2: "包容透明的文化",
    valueSubtitle3: "提供即時的解決方案",
    valueDescription1: "我們說著不同的語言，但我們能夠相互理解。在開發暢意軟體時，我們總是考慮來自世界各地用戶的多元文化。",
    valueDescription2: "當我們朝著目標努力時，我們不會忽視了周圍的人，我們互相幫助以實現目標。",
    valueDescription3: "我們知道尊重彼此和建設性反饋的價值。我們永遠不會滿足於“足夠好”，因為我們知道我們可以做得更好。",
  },
  projectMgnt: {
    title: "專案管理",
    description: "從小任務到大願景，暢意專案管理讓您可以在同一空間內計劃，跟踪和交付團隊的最佳工作。簡潔明了的界面讓您可以輕輕鬆松便管理多項任務和工作流程。",
    listviewTitle: "列表視圖",
    listviewDescription: "通過列表視圖，團隊可以立即看到他們需要做什麼，何時需要開始和完成，哪些任務具有優先級，它們的狀態以及完成這些任務的預估時間。",
    boardviewTitle: "看板視圖",
    boardviewDescription: "確定任務的階段並輕鬆地專注於手頭的事情。及時關注到重要的進展及任務面對的阻礙。專注於您的任務，同時了解整個專案的全貌。",
    calendarviewTitle: "日曆視圖",
    calendarviewDescription: "把所有任務直觀的呈現在甘特圖上。直觀地查看已經完成，正在進行，以及即將發生的任務，給整個團隊一個共同的願景來努力。",
  },
  ideaMgnt: {
    title: "想法與創新管理",
    description: "暢意系統讓您可以從任何地方收集和管理創新想法 - 從客戶的功能請求，有助於簡化內部流程的措施，到您所在行業的突破性創新 - 然後將這些想法轉化為可操作的專案。",
    teamIdeaTitle: "收集來自各處的想法",
    teamIdeaDescription: "從企業的每一個利益相關者那裡收集想法。暢意易於使用的界面能幫助您的團隊在幾分鐘內添加有價值的想法。",
    ideaTaskTitle: "輕鬆完善想法",
    ideaTaskDescription: "利用員工的集體智慧來評論想法，完善它們，並將它們轉化為任務來實施。",
    ideasOverviewTitle: "評估和選擇最佳創新想法",
    ideasOverviewDescription1: "通過直觀的方式選擇最佳想法，並通過",
    ideasOverviewDescription2: "暢意專案管理",
    ideasOverviewDescription3: "实施这些想法。",
  },
  trial: {
    title: "為何不即刻試用？",
    button: "免費試用",
    phrae: "免費試用14天 | 無需付款信息 | 片刻即可開始使用",
  },
  resource: {
    title: "創新及專案管理資源",
    learn: "通過我們的部落格，進一步了解如何在企業內有效地管理和發展創新。",
    by: "作者",
    articles: "查看所有文章"
  },
  contact: {
    keepTouch: "與我們聯絡",
    connect: "與我們的創新專家聯絡，獲取更多創新及專案管理資源。",
    name: "姓名",
    company: "公司名稱",
    workEmail: "工作電郵",
    message: "訊息",
    submit: "提交",
    empty_all: "請提供您的資料之後再提交。",
    empty_email: "請提交有效的電郵。",
    contact_success_message: "您的訊息已成功發送，我們將儘快聯絡您。",
    message_sent_successfull:
      "您的試用請求已成功提交。我們將把系統邀請連結發至您的電郵，請在1分鐘後至您的電郵查收。"
  },
  pricing: pricing_content.HK,
  terms: terms_content.HK,

  blog: {
    blog: `部落格`,
    recent_articles: `最新文章`,
    read: `閱讀`,
    read_more: `閱讀更多`,
    previous_articles: `過往文章`,
    articles: blog_HK,
    next_article: "下一篇文章",
    previous: "以前",
    by: "作者",
    share: "分享"
  },
  modalForm: {
    header: "免費試用 - 暢意創新及專案管理系統",
    try_free: "免費體驗暢意創新及專案管理系統",
    para1: "填寫下方表格，提交後我們將為您創建專屬於貴企業的空間。",
    para2:
      "無需等待，從提交到系統設置完畢只需要短短一分鐘時間。",
    fullName: "姓名",
    emailAddress: "工作電郵",
    company: "公司名稱",
    phoneNumber: "聯絡電話",
    optional: "可選",
    submit: "提交",
    close: "關閉",
    required: "*必填項",
    passwordNotMatch: "密碼不正確",
    password: "密碼",
    confirmPassword: "確認新密碼",
    email_exists_error: "電郵已存在，請登入或重新註冊賬號。",
    field_length_long: "%{field}長度過長",
    field_length_short: "%{field}必須至少%{number}個字符",
    loadingTexts: {
      message0: "正在檢查您的資料⋯",
      message1: "註冊您的信息...",
      message2: "創建您的創新及專案管理空間...",
      message3: "應用顏色...",
      message4: "微調像素...",
      message5: "啟動服務器...",
      message6: "將您設置為管理員...",
      message7: "定制系統...",
      message8: "完成設置..."
    },
    password_placeholder: "8個字符或以上",
    subdomain: "子域名",
    subdomain_in_use: "子域名已存在，請登入或使用其他子域名"
  }
};
