import React, { useEffect } from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/all.css";
import Footer from "../components/Footer/Footer";
import HeadSection from "../components/HeadSection/HeadSection";
import Benefits from "../components/Benefits/Benefits";
import Howworks from "../components/Howworks/Howworks";
import TrustBy from "../components/TrustedBy/TrustedBy";
import ContactUs from "../components/ContactUs/ContactUs";
import Trial from "../components/Trial/Trial";

function Homepage(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { locale } = props.i18n;

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>{I18n.t("seo.homePage.title")}</title>
        {/* Google */}
        <meta property="og:title" itemprop="name" content={I18n.t("seo.homePage.title")} />
        <meta property="og:image" itemprop="image" content={`https://idexinnovation.com/imageIdex.png`} />
        {/* Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={I18n.t("seo.homePage.title")} />
        <meta property="og:image:url" content={`https://idexinnovation.com/imageIdex.png`} />
        <meta property="og:image" content={`https://idexinnovation.com/imageIdex.png`} />
        <meta property="og:image:secure_url" content={`https://idexinnovation.com/imageIdex.png`} />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="520" />
        <meta property="og:description" content={I18n.t("seo.homePage.description")} />
        <meta name="description" content={I18n.t("seo.homePage.description")} />
        {/* twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={I18n.t("seo.homePage.title")} />
        <meta name="twitter:image" itemprop="image" content={`https://idexinnovation.com/imageIdex.png`} />
        <meta name="twitter:description" content={I18n.t("seo.homePage.description")} />
      </Helmet>
      <HeadSection locale={locale} key={1} />
      <Benefits key={2} />
      <Howworks key={3} />
      <Trial key={4} />
      <TrustBy key={5} />
      <ContactUs key={6} />
      <Footer key={7} />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    i18n: state.i18n
  };
};

export default connect(mapStateToProps)(Homepage);
