import React, { useState } from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import Collapsible from "react-collapsible";

import RangeBox from '../RangeBox/RangeBox';
import ContacUsModal from "../ContactUs/ContactUsModal";
import checkIcon from "../../assets/images/icons/check-feature.svg";
import arrowUp from "../../assets/images/icons/chevronb.svg";
import arrowDown from "../../assets/images/icons/chevronr.svg";
import "./Pricing.scss";
import 'rc-slider/assets/index.css';

const marks = {
  1: '5',
  2: '10',
  3: '15',
  4: '25',
  5: '40',
  6: '60',
  7: '80',
  8: '100',
  9: '120+',
};

const marksKey = Object.keys(marks);
const marksLength = marksKey.length;

function Pricing() {
  const [show, setShow] = useState(false);
  const [subject, setSubject] = useState('');
  const first = useState(false)[0];
  const second = useState(false)[0];
  const third = useState(false)[0];
  const fourth = useState(false)[0];
  const [profits, setProfits] = useState({ min: 1, max: 5 });
  const [annual, setAnnual] = useState(false);

  const handelShowModal = sub => {
    let subject = "";
    if (sub === "plan") {
      subject = "Plans & Pricing Enquiry";
    } else {
      subject = " Enterprise Pricing Enquiry ";
    };
    setShow(true);
    setSubject(subject);
  };

  const getCollapsableIcon = (isOpen, text) => {
    return (
      <div>
        {text}
        <img src={isOpen ? arrowDown : arrowUp} alt="arrow" className="pull-right" />
      </div>
    );
  };

  const handleChangePricingPeriod = () => {
    setAnnual(!annual)
  };

  const handleTeamSizeChange = value => {
    setProfits({ min: 1, max: value.max })
  };

  const round = (value, precision) => {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  };

  const closeModalHanlder = () => {
    setShow(false)
  };

  return (
    <div className="pricing-wrapper">
      <h2>{I18n.t("pricing.price1.plans_pricing")}</h2>
      <p>{I18n.t("pricing.price1.choose_plan")}</p>

      <p className="team-size-info">{I18n.t('pricing_info.select_team_size')}</p>

      <div className="row mt-1">
        <div className="col-12 col-md-10 col-lg-8 text-center mx-auto">
          <RangeBox
            title={I18n.t('ideas.modal.impact')}
            field="impact"
            from={1}
            to={9}
            value={profits}
            min={profits.min}
            max={profits.max}
            handleChange={handleTeamSizeChange}
          />
        </div>
      </div>

      <div className="w-100 d-flex alig-items-center justify-content-center">
        <div className="d-flex align-items-center text-secondary ml-5">
          <span className="m-1"><strong>{I18n.t('pricing_info.monthly')}</strong></span>
        </div>
        <div className="d-flex align-items-center mx-1">
          <label className="switch m-0 p-0">
            <input type="checkbox" checked={annual} onChange={handleChangePricingPeriod} />
            <span className="slider round" />
          </label>
        </div>
        <div className="d-flex align-items-center">
          <span className="m-1"><strong>{I18n.t('pricing_info.annual')}</strong></span>
        </div>
        <div className="d-flex align-items-center">
          <span className="badge badge-pill badge-primary">{I18n.t('pricing_info.save30')}</span>
        </div>
      </div>

      <div className="container pricing-plans">
        <div className="pricing-card">
          <h5 className="pricing-card-title">
            {I18n.t("pricing.price1.starter")}
          </h5>
          <div className="pricing-details">
            <span className="price">
              {I18n.t("pricing.price1.currency")}
              {I18n.t("pricing.price1.99")}
            </span>
            <span className="pricing-unit">
              {" "}
              {I18n.t("pricing.price1.month")}
            </span>
            <span>
            </span>
          </div>
          <div className="starter-info">
            <span>{I18n.t('benefits.trial_info')}</span>
          </div>
          <ul className="plan-features">
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price1.unlimited_members")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price1.xx_teams")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price1.xx_projects")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price1.xx_ideas")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price1.xx_tasks")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price1.your_own_workspace")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price1.collect_analyse")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price1.gantt_chart")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price1.assign_prioritize")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price1.view")}
            </li>
            <li className="feature-na">
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price1.custom_branding")}
            </li>
            <li className="feature-na">
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price1.performance_analytics")}
            </li>
            <li className="feature-na">
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price1.single_sign_on")}
            </li>
            <li className="feature-na">
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price1.dedicated_manager")}
            </li>
            <li className="feature-na">
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price1.sla_tech")}
            </li>
            <button
              onClick={() => {
                window.open("/free-trial");
              }}
            >
              {I18n.t("pricing.price1.try_for_free")}
            </button>
          </ul>
        </div>
        <div className={`pricing-card ${profits.max !== Number(marksKey[marksLength - 1]) && 'most-popular'}`}>
          <div className="popular">
            <span>{I18n.t("pricing.price2.most_popular")}</span>
          </div>

          <h5 className="pricing-card-title">
            {I18n.t("pricing.price2.business")}
          </h5>
          <div className="pricing-details two-pricings d-block text-left">
            <span className="price">
              {I18n.t("pricing.price1.currency")}
              {annual ? `${I18n.t("pricing.price2.annual_price")}` : `${I18n.t("pricing.price2.monthly_price")}`}
            </span>
            <span className="pricing-unit">
              {annual ? `${I18n.t('pricing.price2.annually')}` : `${I18n.t('pricing.price2.monthly')}`}
            </span><br></br>
            <span className="pricing-unit d-block text-left">
              {profits.max !== Number(marksKey[marksLength - 1]) ? (
                <>
                  {annual ? (
                    <>{I18n.t("pricing.price2.annual_breakdown", {
                      annually: `${I18n.t('pricing.price1.currency')}${round(marks[profits.max] * I18n.t('pricing.price2.annual_price') * 12, 2)}`,
                      monthly: `${I18n.t('pricing.price1.currency')}${round(marks[profits.max] * I18n.t('pricing.price2.annual_price'), 2)}`
                    })}</>
                  ) : (
                    <>{I18n.t('pricing.price2.monthly_breakdown', {
                      monthly: `${I18n.t('pricing.price1.currency')}${round(marks[profits.max] * I18n.t('pricing.price2.monthly_price'), 2)}`
                    })}</>
                  )}
                </>
              ) : (
                <span className="contact-us" onClick={handelShowModal}>{I18n.t("pricing.price4.contact_us")}</span>
              )}
            </span>
          </div>
          <ul className="plan-features">
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price2.unlimited_members")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price2.xx_teams")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price2.xx_projects")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price2.xx_ideas")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price2.xx_tasks")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price2.your_own_workspace")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price2.collect_analyse")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price2.gantt_chart")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price2.assign_prioritize")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price2.view")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price2.custom_branding")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price2.performance_analytics")}
            </li>
            <li className="feature-na">
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price2.single_sign_on")}
            </li>
            <li className="feature-na">
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price2.dedicated_manager")}
            </li>
            <li className="feature-na">
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price2.sla_tech")}
            </li>
            <button
              className={`${profits.max !== Number(marksKey[marksLength - 1]) && 'inverse'}`}
              onClick={() => {
                window.open("/free-trial");
              }}
            >
              {I18n.t("pricing.price2.try_for_free")}
            </button>
          </ul>
        </div>
        <div className={`pricing-card ${profits.max === Number(marksKey[marksLength - 1]) && 'most-popular'}`}>
          <h5 className="pricing-card-title">
            {I18n.t("pricing.price4.enterprise")}
          </h5>
          <div className="pricing-details-enterprise">
            <span>
              {I18n.t("pricing.price4.for_custom")}{" "}
              <span className="cursor-pointer" onClick={handelShowModal}>
                {I18n.t("pricing.price4.contact_us")}
              </span>
            </span>
          </div>
          <ul className="plan-features">
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price4.unlimited_members")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price4.xx_teams")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price4.xx_projects")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price4.xx_ideas")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price4.xx_tasks")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price4.your_own_workspace")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price4.collect_analyse")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price4.gantt_chart")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price4.assign_prioritize")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price4.view")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price4.custom_branding")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price2.performance_analytics")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price4.single_sign_on")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price4.dedicated_manager")}
            </li>
            <li>
              <img src={checkIcon} alt="..." />
              {I18n.t("pricing.price4.sla_tech")}
            </li>
            <button className={`${profits.max === Number(marksKey[marksLength - 1]) && 'inverse'}`} onClick={handelShowModal}>
              {I18n.t("pricing.price4.contact_us")}
            </button>
          </ul>
        </div>
      </div>

      <div className="faq-wrapper">
        <h2 className="faq_heading">{I18n.t("pricing.price4.faq")}</h2>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <div className="faq">
                <Collapsible
                  trigger={getCollapsableIcon(
                    first,
                    I18n.t("pricing.price4.can_i_cancel")
                  )}
                >
                  <p>{I18n.t("pricing.price4.our_goal")}</p>
                </Collapsible>
              </div>
            </div>
            <div className="col-md-6">
              <div className="faq">
                <Collapsible
                  trigger={getCollapsableIcon(
                    second,
                    I18n.t("pricing.price4.payment_options")
                  )}
                >
                  <p>{I18n.t("pricing.price4.payments_made_via")}</p>
                </Collapsible>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="faq">
                <Collapsible
                  trigger={getCollapsableIcon(
                    third,
                    I18n.t("pricing.price4.data_protection")
                  )}
                >
                  <p>{I18n.t("pricing.price4.serious_secutiry")}</p>
                </Collapsible>
              </div>
            </div>
            <div className="col-md-6">
              <div className="faq">
                <Collapsible
                  trigger={getCollapsableIcon(
                    fourth,
                    I18n.t("pricing.price4.more_ides")
                  )}
                >
                  <p>{I18n.t("pricing.price4.dont_worry")}</p>
                </Collapsible>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="stay_in_touch_div">
              <h3 className="stay_in_touch">
                {I18n.t("pricing.price4.stay_in_touch")}
              </h3>

              <ContacUsModal
                show={show}
                subject={subject}
                handleCloseModal={closeModalHanlder}
              />

              <button
                className="btn_contact"
                onClick={handelShowModal}
              >
                {I18n.t("pricing.price4.contact_us")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


function mapStateToProps(state) {
  return {
    i18n: state.i18n
  };
};

export default connect(mapStateToProps)(Pricing);
