import { createAction } from 'redux-actions';
import * as constants from "./constants"

export const sendContactDetailRequest = createAction(constants.SEND_CONTACT_DETAIL_REQUEST)
export const sendContactDetailSuccess = createAction(constants.SEND_CONTACT_DETAIL_SUCCESS)
export const sendContactDetailError = createAction(constants.SEND_CONTACT_DETAIL_ERROR)

export const sendTryItDetailRequest = createAction(constants.SEND_TRY_IT_DETAIL_REQUEST)
export const sendTryItDetailSuccess = createAction(constants.SEND_TRY_IT_DETAIL_SUCCESS)
export const sendTryItDetailError = createAction(constants.SEND_TRY_IT_DETAIL_ERROR)

export const getPollProgressRequest = createAction(constants.GET_POLL_PROGRESS_REQUEST);
export const getPollProgressSuccess = createAction(constants.GET_POLL_PROGRESS_SUCCESS);
export const getPollProgressError = createAction(constants.GET_POLL_PROGRESS_ERROR);
export const setPollProgressCounter = createAction(constants.SET_POLL_PROGRESS_COUNTER);
