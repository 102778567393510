import React from "react";
import { hydrate, render } from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { loadTranslations, setLocale, syncTranslationWithStore } from "react-redux-i18n";

import Routes from "./Routes";
import { getLocales } from './utils';
import pipelineApp from "./reducers/index";
import * as serviceWorker from "./serviceWorker";
import history from "./history";
import { GB, ES, CN, HK } from './languages';
import "./fonts/Ubuntu/Ubuntu-Regular.ttf";
import "./index.scss";

const isProduction = process.env.NODE_ENV === "production";
let store = null;
const translationObject = { GB, ES, CN, HK };

if (isProduction) {
  const middleware = applyMiddleware(thunk);
  const enhancer = compose(middleware);
  store = createStore(pipelineApp, enhancer);
} else {
  const middleware = applyMiddleware(thunk);
  const enhancer = compose(middleware);
  store = createStore(pipelineApp, enhancer);
};

syncTranslationWithStore(store);
store.dispatch(loadTranslations(translationObject));
const localLang = localStorage.getItem("lang");

if (localLang !== null) {
  const locale = getLocales(localLang);
  store.dispatch(setLocale(locale));
  localStorage.setItem('lang', locale);
} else if (navigator.language) {
  const lang = navigator.language;
  const locale = getLocales(lang);
  store.dispatch(setLocale(locale));
  localStorage.setItem('lang', locale);
} else if (navigator.languages && navigator.languages.length) {
  const lang = navigator.languages[0];
  const locale = getLocales(lang);
  store.dispatch(setLocale(locale));
  localStorage.setItem('lang', locale);
} else {
  const locale = getLocales('en');
  store.dispatch(setLocale(locale));
  localStorage.setItem('lang', locale);
};

const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
  history.replace(path);
};

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <Routes />
    </Provider>, rootElement);
} else {
  render(
    <Provider store={store}>
      <Routes />
    </Provider>, rootElement);
};

serviceWorker.unregister();
