import React from 'react';
import { Range } from 'rc-slider';

import 'rc-slider/assets/index.css';
import './RangeBox.scss';

const RangeBox = ({
  to,
  from,
  value,
  handleChange,
}) => {
  const marks = {
    1: '5',
    2: '10',
    3: '15',
    4: '25',
    5: '40',
    6: '60',
    7: '80',
    8: '100',
    9: '120+',
  };
  return (
    <div className="filters-range">
      <div className="range-data-2">
        <div className="range-holder">
          <Range
            max={to}
            min={from}
            marks={marks}
            step={1}
            value={[value.min, value.max]}
            onChange={([min, max]) => handleChange({ min, max })}
            onAfterChange={([min, max]) => handleChange({ min, max })}
          />
        </div>
      </div>
    </div>
  );
};

export default RangeBox;
