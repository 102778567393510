import React, { useEffect } from 'react';
import { I18n } from "react-redux-i18n";
import Helmet from "react-helmet";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/all.css';
import Footer from '../components/Footer/Footer';
import ProjectMgnt from '../components/ProjectMgnt/ProjectMgnt'
import Trial from '../components/Trial/Trial';

function ProjectMgntPage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
      <Helmet>
        <title>{I18n.t("seo.ideaMgntPage.title")}</title>
        {/* Google */}
        <meta property="og:title" itemprop="name" content={I18n.t("seo.ideaMgntPage.title")} />
        <meta property="og:image" itemprop="image" content={`https://idexinnovation.com/imageIdex.png`} />
        {/* Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={I18n.t("seo.ideaMgntPage.title")} />
        <meta property="og:image:url" content={`https://idexinnovation.com/imageIdex.png`} />
        <meta property="og:image" content={`https://idexinnovation.com/imageIdex.png`} />
        <meta property="og:image:secure_url" content={`https://idexinnovation.com/imageIdex.png`} />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="520" />
        <meta property="og:description" content={I18n.t("seo.ideaMgntPage.description")} />
        <meta name="description" content={I18n.t("seo.ideaMgntPage.description")} />
        {/* twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={I18n.t("seo.ideaMgntPage.title")} />
        <meta name="twitter:image" itemprop="image" content={`https://idexinnovation.com/imageIdex.png`} />
        <meta name="twitter:description" content={I18n.t("seo.ideaMgntPage.description")} />
      </Helmet>
      <ProjectMgnt />
      <Trial />
      <Footer />
    </div>
  );
};

export default ProjectMgntPage;