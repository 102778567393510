import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import pleasedImg from '../../assets/images/customer_logos/pleased.png';
import unlokkImg from '../../assets/images/customer_logos/unlokk.png';
import freshlinkImg from '../../assets/images/customer_logos/freshlink.png';
import quoteImg from '../../assets/images/icons/quote.svg';
import './TrustedBy.scss';

function TrustedBy() {
  return (
    <div className="trustby-wrapper">
      <h2>{I18n.t('trusty.title')}</h2>
      <div className="trusty-brand">
        <div className="trusty-brand-item pleased-img">
          <img src={pleasedImg} alt="Pleased" name="pleased" />
        </div>
        <div className="trusty-brand-item unlokk-img">
          <img src={unlokkImg} alt="Unlokk" name="unlokk" />
        </div>
        <div className="trusty-brand-item freshlink-img">
          <img src={freshlinkImg} alt="Fresh Link" name="freshlink" />
        </div>
      </div>
      <div className="trusty-feedback">
        <div className="trusty-feedback-item">
          <div className="feedback-wrapper">
            <p>{I18n.t('trusty.identify')}</p>
          </div>
          <span>Matt Stewart,{" "}{I18n.t('trusty.card1Position')}</span>
          <label>Pleased.com</label>
          <label className="feedback-quote"><img src={quoteImg} alt="..." /></label>

        </div>
        <div className="trusty-feedback-item">
          <div className="feedback-wrapper">
            <p>{I18n.t('trusty.beforeIDEX')}</p>
          </div>
          <span>Jason Peterson,{" "}{I18n.t('trusty.card2Position')}</span>
          <label>Fresh Link</label>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    i18n: state.i18n
  };
};

export default connect(mapStateToProps)(TrustedBy);