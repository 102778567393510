import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Col, Row } from "react-bootstrap";
import { connect } from 'react-redux';

import listView from '../../assets/images/features/projectMgnt/en/listView.svg';
import boardView from '../../assets/images/features/projectMgnt/en/boardView.svg';
import calendarView from '../../assets/images/features/projectMgnt/en/calendarView.svg';
import './ProjectMgnt.scss';

function ProjectMgnt() {
  return (
    <div className="projectMgnt-container">
      <div className="projectMgnt-header-text">
        <h2>{I18n.t('projectMgnt.title')}</h2>
        <p>{I18n.t('projectMgnt.description')}</p>
      </div>

      <Row className="projectMgnt-item">
        <Col 
          md={{ span: 2, order: 1 }}
          xs={{ span: 0, order: 3 }}
        />
        <Col 
          md={{ span: 4, order: 1 }}
          xs={{ span: 12, order: 2 }}
          className="projectMgnt-text-wrapper"
        >
          <h2>{I18n.t('projectMgnt.listviewTitle')}</h2>
          <p>{I18n.t('projectMgnt.listviewDescription')}</p>
        </Col>
        <Col 
          md={{ span: 5, order: 1 }}
          xs={{ span: 12, order: 2 }}
          className="projectMgnt-image-container"
        >
          <img src={listView} alt="list view" />
        </Col>
        <Col 
          md={{ span: 1, order: 1 }}
          xs={{ span: 0, order: 2 }}
        />
      </Row>

      <Row className="projectMgnt-item reverse">
        <Col 
          md={{ span: 2, order: 1 }}
          xs={{ span: 0, order: 2 }}
        />
        <Col 
          className="projectMgnt-text-wrapper"
          md={{ span: 4, order: 1 }}
          xs={{ span: 12, order: 2 }}
        >
          <h2>{I18n.t('projectMgnt.boardviewTitle')}</h2>
          <p>{I18n.t('projectMgnt.boardviewDescription')}</p>
        </Col>
        <Col 
          className="projectMgnt-image-container"
          md={{ span: 5, order: 1 }}
          xs={{ span: 12, order: 2 }}
        >
          <img src={boardView} alt="board view" />
        </Col>
        <Col 
          md={{ span: 1, order: 1 }}
          xs={{ span: 0, order: 2 }}
        />
      </Row>

      <Row className="projectMgnt-item ">
        <Col 
          md={{ span: 2, order: 1 }}
          xs={{ span: 0, order: 2 }}
        />
        <Col 
          className="projectMgnt-text-wrapper"
          md={{ span: 4, order: 1 }}
          xs={{ span: 12, order: 2 }}
        >
          <h2>{I18n.t('projectMgnt.calendarviewTitle')}</h2>
          <p>{I18n.t('projectMgnt.calendarviewDescription')}</p>
        </Col>
        <Col 
          className="projectMgnt-image-container"
          md={{ span: 5, order: 1 }}
          xs={{ span: 12, order: 2 }}
        >
          <img src={calendarView} alt="calendar view" />
        </Col>
        <Col 
          md={{ span: 1, order: 1 }}
          xs={{ span: 0, order: 2 }}
        />
      </Row>      
    </div>
  );
};

function mapStateToProps(state) {
  return {
    i18n: state.i18n
  };
};

export default connect(mapStateToProps)(ProjectMgnt);