import React from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { Experiment, Variant, emitter, experimentDebugger } from "@marvelapp/react-ab-test";
import { variantArr } from "../../utils";
import ReactGA from "react-ga";

import logoImage from "../../assets/images/footer/logo.png";
import "./Footer.scss";

let trackingId = "UA-137478659-1";
ReactGA.initialize(trackingId);
ReactGA.set({ dimension14: "Sports" });

experimentDebugger.enable();
emitter.defineVariants(
  "Footer-test",
  ["Try-It-For-Free", "Experience-IDEX", "Get-Started"],
  [33, 33, 33]
);

function Footer() {
  return (
    <div className="footer-wrapper">
      <Row className="footer-content">
        <Col
          md={{ span: 1, order: 1 }}
          xs={{ span: 0, order: 2 }}
        />

        <Col
          md={{ span: 4, order: 1 }}
          xs={{ span: 12, order: 2 }}
          className="footer-hyperlinks pt-2 pb-3 mt-3"
        >
          <Col className="footer-links">
            <p className="footer-link-titles">{I18n.t("footer.productTitle")}</p>
            <div className="links-display">
              <Link 
                to="/project-management"
                className="footer-link"
              >
                {I18n.t("footer.productProjectMgnt")}
              </Link>
              <Link 
                to="/idea-management"
                className="footer-link"
              >
                {I18n.t("footer.productIdeaMgnt")}
              </Link>
              <Link 
                to="/pricing"
                className="footer-link"
              >
                {I18n.t("footer.productPricing")}
              </Link>
            </div>
          </Col>
          
          <Col className="footer-links">
            <p className="footer-link-titles">{I18n.t("footer.companyTitle")}</p>
            <div className="links-display">
              <Link 
                to="/blog"
                className="footer-link"
              >
                {I18n.t("footer.companyBlog")}
              </Link>
              <Link 
                to="/about-us"
                className="footer-link"
              >
                {I18n.t("footer.companyAboutUs")}
              </Link>
              <Link 
                to="/terms"
                className="footer-link"
              >
                {I18n.t("footer.companyTerms")}
              </Link>
            </div>
          </Col>
        </Col>

        <Col
          md={{ span: 2, order: 2 }}
          xs={{ span: 12, order: 1 }}
          className="footer-logo"
        >
          <img src={logoImage} alt=" " className="mb-3" />
          <div className="pt-2 pb-3 footer-address mt-3">
            <p>{I18n.t("footer.idex")}</p>
            <p>info@idexltd.com</p>
          </div>
        </Col>  

        <Col
          md={{ span: 4, order: 3 }}
          xs={{ span: 12, order: 1 }}
          className="footer-fn"
        >
          <p className="footer-align">{I18n.t("footer.turnIdea")}</p>
          <div className="footer-collect">
            {I18n.t("footer.startCollecting")}
          </div>
          <Experiment name="Footer-test">
            {variantArr.map(val => {
              return (
                <Variant name={val.name} key={val.name}>
                  <Link
                    to="/free-trial"
                    className="footer-start-btn"
                    target="_blank"
                  >
                    {I18n.t(val.content)}
                  </Link>
                </Variant>
              );
            })}
          </Experiment>
        </Col> 

        <Col
          md={{ span: 1, order: 3 }}
          xs={{ span: 12, order: 1 }}
        />                
      </Row>
    
      <Row className="footer-line" />

      <Row className="footer-content footer-copyright">
        <Col
          md={{ span: 11, order: 1 }}
          xs={{ span: 12, order: 2 }}
          className="footer-icons"
        >
          <div className="pt-2 pb-3 footer-address copyright-align mt-2">
            &copy; {(new Date().getFullYear())} - IDEX | Jouster FZE
          </div>
        </Col>
      </Row>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    i18n: state.i18n
  };
};

export default connect(mapStateToProps)(Footer);

emitter.addPlayListener("Test-Addl-Info", function (
  experimentName,
  variantName
) {
});

emitter.addWinListener("Footer-test", function (experimentName, variantName) {
  ReactGA.event({
    category: variantName,
    action: experimentName
  });
});
