import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, InputGroup, Dropdown, DropdownButton, FormControl } from "react-bootstrap";
import { I18n } from "react-redux-i18n";
import { Alert } from "react-bootstrap";
import isEqual from "lodash/isEqual";

import { sendTryItDetail } from "../../actions/tryItFree";
import Loader from "../Loader/CustomLoader";
import Country from "./Country";
import "./ModalForm.scss";
import "react-phone-input-2/lib/style.css";

class ModalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: {},
      name: "",
      email: "",
      company: "",
      phone: "",
      password: "",
      subdomain: "",
      subdomainChosen: false,
      isError: false,
      errorMessage: this.getDefaultErrMessage(),
      email_error: false
    };
  };

  componentDidMount() {
    document.title = I18n.t("modalForm.header");
    this.setState({ selectedCountry: this.getDefaultCountry() })
    window.addEventListener("beforeunload", this.handleWindowBeforeUnload);
  };

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleWindowBeforeUnload);
  }

  handleWindowBeforeUnload(e) {
    e.preventDefault();
    e.stopPropagation();
    e.returnValue = '';
    return null;
  }

  getDefaultCountry = () => {
    const { locale } = this.props.i18n;
    if (locale === "CN") {
      return {
        iso2: "CN",
        name: "China",
        iso3: "CHN",
        unicode: "🇨🇳",
        dial: "+86",
        currency: "CNY",
        capital: "Beijing",
        continent: "AS",
        traditional_cn: "中國",
        simplified_cn: "中国"
      };
    } else if (locale === "HK") {
      return {
        iso2: "TW",
        name: "Taiwan",
        iso3: "TWN",
        unicode: "🇹🇼",
        dial: "+886",
        currency: "TWD",
        capital: "Taipei",
        continent: "AS",
        traditional_cn: "台灣",
        simplified_cn: "台湾"
      };
    } else if (locale === "ES") {
      return {
        iso2: "ES",
        name: "Spain",
        iso3: "ESP",
        unicode: "🇪🇸",
        dial: "+34",
        currency: "EUR",
        capital: "Madrid",
        continent: "EU",
        traditional_cn: "西班牙",
        simplified_cn: "西班牙"
      };
    };
    return {
      iso2: "GB",
      name: "United Kingdom",
      iso3: "GBR",
      unicode: "🇬🇧",
      dial: "+44",
      currency: "GBP",
      capital: "London",
      continent: "EU",
      traditional_cn: "英國",
      simplified_cn: "英国"
    };
  };

  getDefaultErrMessage = () => {
    return {
      email: "",
      company: "",
      name: "",
      password: "",
      message: "",
      subdomain: ""
    };
  };

  handleSelectCountry = e => {
    let selectedCountryObject = Country.find(curr => {
      return curr.dial === e;
    });
    this.setState({
      selectedCountry: selectedCountryObject
    });
  };

  handleSubmitClick = e => {
    e.preventDefault();
    const { locale } = this.props.i18n;

    const { name, email, company, phone, password, subdomain } = this.state;
    const errorMessage = this.getDefaultErrMessage();

    const emailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email.length === 0) {
      errorMessage.email = I18n.t("contact.empty_all");
    } else if (!emailReg.test(email)) {
      errorMessage.email = I18n.t("contact.empty_email");
    }

    if (name.length === 0) {
      errorMessage.name = I18n.t("contact.empty_all");
    } else if (name.length < 2) {
      errorMessage.name = I18n.t("modalForm.field_length_short", {
        field: I18n.t("modalForm.fullName"),
        number: "2"
      });
    } else if (name.length > 100) {
      errorMessage.name = I18n.t("modalForm.field_length_long", {
        field: I18n.t("modalForm.fullName")
      });
    };

    if (company.length === 0) {
      errorMessage.company = I18n.t("contact.empty_all");
    } else if (company.length < 2) {
      errorMessage.company = I18n.t("modalForm.field_length_short", {
        field: I18n.t("modalForm.company"),
        number: "2"
      });
    } else if (company.length > 100) {
      errorMessage.company = I18n.t("modalForm.field_length_long", {
        field: I18n.t("modalForm.company")
      });
    };

    if (password.length === 0) {
      errorMessage.password = I18n.t("contact.empty_all");
    } else if (password.length < 8) {
      errorMessage.password = I18n.t("modalForm.field_length_short", {
        field: I18n.t("modalForm.password"),
        number: "8"
      });
    } else if (password.length > 50) {
      errorMessage.password = I18n.t("modalForm.field_length_long", {
        field: I18n.t("modalForm.password")
      });
    };

    const availabilityChecks = [];
    if (this.props.i18n.locale !== 'CN') {
      if (subdomain.length > 0) {
        availabilityChecks.push(fetch(`https://api.idexinnovation.com/ims-api/trial?subdomain=${subdomain}`)
          .then(response => {
            if (!response.ok) {
              errorMessage.subdomain = I18n.t("modalForm.subdomain_in_use");
            }
          }));
      } else {
        errorMessage.subdomain = I18n.t("contact.empty_all");
      };

      if (email.length > 0) {
        availabilityChecks.push(fetch(`https://api.idexinnovation.com/ims-api/trial?email=${encodeURIComponent(email)}`)
          .then(response => {
            if (!response.ok) {
              errorMessage.email = I18n.t("modalForm.email_exists_error");
            }
          }));
      }
    };

    Promise.all(availabilityChecks).then(() => {
      this.setState({ errorMessage });
      if (!isEqual(errorMessage, this.getDefaultErrMessage())) {
        return;
      };
  
      const phoneCountry = this.state.selectedCountry.dial + phone;
      const lang = this.getLangFromLocale();
      
      this.props.sendTryItDetail(
        { name, email, company, phone: phoneCountry, password, lang, subdomain },
        locale
      );
    });
  };

  handleChange = e => {
    const { errorMessage } = this.state;
    const { name } = e.currentTarget;
    let { subdomainChosen } = this.state;
    let { value } = e.currentTarget;
    let companyName = null;

    switch (name) {
      case "email":
        const emailReg = /^[^s@]+@[^s@]+.[^s@]+$/;
        if (emailReg.test(value)) {
          errorMessage.email = "";
        }
        break;
      case "phone":
        const re = /^$|[0-9\b]+$/;
        if (!re.test(e.currentTarget.value)) {
          return;
        }
        break;
      case "name":
        if (value.length > 2 || value.length < 100) {
          errorMessage.name = "";
        }
        break;
      case "password":
        if (value.length > 8 || value.length < 50) {
          errorMessage.password = "";
        }
        break;
      case "company":
        if (value.length > 2 || value.length < 100) {
          errorMessage.company = "";
          companyName = value;
        }
        break;
      case "subdomain":
        subdomainChosen = true;
        errorMessage.subdomain = "";
        value = (value || "").trim().toLowerCase();
        break;
      default:
        break;
    }
    this.setState({
      errorMessage,
      [e.currentTarget.name]: value,
      subdomainChosen
    });

    if (!subdomainChosen && this.props.i18n.locale !== 'CN' && companyName !== null) {
      if (companyName.length > 0) {
        errorMessage.subdomain = "";
      }

      this.setState({
        errorMessage,
        subdomain: companyName.trim().toLowerCase().replace(/ +/g, '-')
      });
    };
  };

  handleBlur = ({ currentTarget: { name, value } }) => {
    switch (name) {
      case "subdomain":
        if (!value || value.length === 0) {
          this.setState({
            subdomainChosen: false,
            subdomain: (this.state.company || "").trim().toLowerCase().replace(/ +/g, '-')
          });
        }
        break;
      default:
    }
  };

  getLangFromLocale = () => {
    const { locale } = this.props.i18n;
    let lang = "en";
    if (locale === "CN") {
      lang = "zh_CN";
    } else if (locale === "HK") {
      lang = "zh_TW";
    } else if (locale === "ES") {
      lang = "es"
    };
    return lang;
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.tryIt.isSuccess &&
      this.props.tryIt.data.subdomain &&
      this.props.tryIt.isPollLoading !== prevProps.tryIt.isPollLoading &&
      this.props.tryIt.isReady !== prevProps.tryIt.isReady &&
      this.props.tryIt.pollingCount === 0
    ) {
      this.setState({
        name: "",
        email: "",
        company: "",
        phone: "",
        password: "",
        subdomain: "",
        isError: false,
        email_error: false
      });
      if (this.props.tryIt.isReady && this.props.tryIt.redirectionUrl) {
        window.gtag('event', 'conversion', { 'send_to': 'AW-659275822/t2yYCMuTicoBEK6Ar7oC' });
        setTimeout(() => {
          window.removeEventListener("beforeunload", this.handleWindowBeforeUnload);
          window.location.href = `${this.props.tryIt.redirectionUrl}`;
        });
      };
    };
  };

  loadingTexts = [
    I18n.t("modalForm.loadingTexts.message0"),
    I18n.t("modalForm.loadingTexts.message1"),
    I18n.t("modalForm.loadingTexts.message2"),
    I18n.t("modalForm.loadingTexts.message3"),
    I18n.t("modalForm.loadingTexts.message4"),
    I18n.t("modalForm.loadingTexts.message5"),
    I18n.t("modalForm.loadingTexts.message6"),
    I18n.t("modalForm.loadingTexts.message7"),
    I18n.t("modalForm.loadingTexts.message8")
  ];

  render() {
    const { locale } = this.props.i18n;
    const { tryIt } = this.props;
    const { name, email, company, phone, errorMessage, password, subdomain } = this.state;
    return (
      <div className="modal-wrapper try-free-modal">
        <Form onSubmit={this.handleSubmitClick}>
          <div className="content">
            <h2>{I18n.t("modalForm.try_free")}</h2>
            <div>{I18n.t("modalForm.para1")}</div>
            <div>{I18n.t("modalForm.para2")}</div>
          </div>
          <div className="form-content">
            <span key="name">
              <Form.Group controlId="formBasicName">
                <Form.Label>{I18n.t("modalForm.fullName")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="name"
                  onChange={this.handleChange}
                  value={name}
                />
                <Form.Label className="error">{errorMessage.name}</Form.Label>
              </Form.Group>
            </span>
            <span key="email">
              <Form.Group controlId="formBasicEmail" key="email">
                <Form.Label>{I18n.t("modalForm.emailAddress")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                />

                <Form.Label className="error">
                  {errorMessage.email}
                </Form.Label>
              </Form.Group>
            </span>
            <span key="password">
              <Form.Group controlId="formBasicPassword" key="password">
                <Form.Label>
                  {I18n.t("modalForm.password")}{" "}
                  <span className="optional">
                    ({I18n.t("modalForm.password_placeholder")})
                  </span>
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder=""
                  name="password"
                  onChange={this.handleChange}
                  value={password}
                />
                <Form.Label className="error">
                  {errorMessage.password}
                </Form.Label>
              </Form.Group>
            </span>
            <span key="company">
              <Form.Group controlId="formBasicCompany" key="company">
                <Form.Label>{I18n.t("modalForm.company")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="company"
                  value={company}
                  onChange={this.handleChange}
                />
                <Form.Label className="error">
                  {errorMessage.company}
                </Form.Label>
              </Form.Group>
            </span>
            {
              this.props.i18n !== 'CN' &&
                <span key="subdomain">
                  <Form.Group controlId="formBasicSubdomain" key="subdomain">
                    <Form.Label>{I18n.t("modalForm.subdomain")}</Form.Label>
                    <span className="subdomain-input">
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="subdomain"
                        value={subdomain}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                      />
                      <span>&nbsp;&nbsp;.getidex.com</span>
                    </span>
                    <Form.Label className="error">
                      {errorMessage.subdomain}
                    </Form.Label>
                  </Form.Group>
                </span>
                
            }
            <span key="phone">
              <Form.Group controlId="formBasicPhone" key="phone">
                <Form.Label>
                  {I18n.t("modalForm.phoneNumber")}{" "}
                  <span className="optional">
                    ({I18n.t("modalForm.optional")})
                  </span>
                </Form.Label>
                <InputGroup className="mb-3">
                  <DropdownButton
                    as={InputGroup.Prepend}
                    variant="outline-secondary"
                    className="code-dropdown-menu"
                    title={
                      <span>
                        <span className="code">
                          {this.state.selectedCountry.iso2 &&
                            React.createElement("img", {
                              src: require("./flags/" +
                                this.state.selectedCountry.iso2.toLowerCase() +
                                ".svg"),
                              className: "country-flag"
                            })}

                          {this.state.selectedCountry.dial}
                        </span>
                      </span>
                    }
                    id="input-group-dropdown-1"
                    onSelect={this.handleSelectCountry}
                  >
                    {Country.map(val => {
                      return (
                        <Dropdown.Item
                          eventKey={val.dial}
                          href="#"
                          key={val.name}
                        >
                          <span name="en">
                            {React.createElement("img", {
                              src: require("./flags/" +
                                val.iso2.toLowerCase() +
                                ".svg"),
                              className: "country-flag"
                            })}
                            <span>
                              {locale === "GB"
                                ? val.name
                                : locale === "CN"
                                  ? val.simplified_cn
                                  : val.traditional_cn}
                            </span>{" "}
                            <span>{val.dial}</span>
                          </span>
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>

                  <FormControl
                    aria-describedby="basic-addon1"
                    name="phone"
                    onChange={this.handleChange}
                    type="text"
                    value={phone}
                  />
                </InputGroup>
              </Form.Group>
            </span>
            <Form.Label className="error">
              {errorMessage.message}
            </Form.Label>
            {tryIt.message && (
              <div>
                <Alert variant={"warning"}>
                  {I18n.t("modalForm.email_exists_error")}
                </Alert>
              </div>
            )}
            <div className="button-container">
              <Button
                variant="primary"
                type="submit"
                disabled={tryIt.isLoading}
                className="trial-submit-btn"
              >
                {I18n.t("modalForm.submit")}
              </Button>
            </div>
          </div>

          {tryIt.isPollLoading && (
            <Loader
              loaded={!tryIt.isPollLoading}
              name="double-bounce"
              text={
                tryIt.pollingCount > this.loadingTexts.length - 1
                  ? this.loadingTexts[this.loadingTexts.length - 1]
                  : this.loadingTexts[tryIt.pollingCount]
              }
            />
          )}
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    i18n: state.i18n,
    tryIt: state.tryItFree.tryItData
  };
};

const mapDispatchToProps = dispatch => ({
  sendTryItDetail: (data, locale) => dispatch(sendTryItDetail(data, locale))
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalForm);
