export default {
  GB:{
    title:`Terms & Conditions`,
	introduction:`Introduction`,
	introduction_content1:`This is a legally binding agreement (“T&C”) governing Your (“You”, “Your”) access and use of our sites, mobile apps, products and services (collectively the “Service”) and all data, files, content or material uploaded, downloaded, produced, viewed or otherwise accessed through our Service (collectively the “Content”).`,
	introduction_content2:`The Service is provided by IDEX Innovation Software Trading LLC, with offices at 1101-19 Crystal Tower, Business Bay, Dubai, United Arab Emirates, doing business as IDEX Innovation & Project Management Software  - in short referred to as “IDEX Innovation”, “IDEX”,  “Us”, or “We”. Together You and IDEX make out the “Parties” of these T&C (individually the “Party”). By visiting one of our websites or otherwise making use of parts of Our Service, You are considered a “User” of our Services.`,
	introduction_content3:`By using IDEX, you agree to these T&C. If You do not agree or comply with these T&C, You must not accept them and must not start or continue to use IDEX.`,
	introduction_content4:`We reserve the right to adjust and change these T&C and any documents included by reference at any time. If a change is material we will let you know in advance. By continuing to use IDEX after the effective date, you agree to the new version.`,
	access:`Access to the Service`, 
	access_account:`Account`, 
	account_content1:`IDEX will provide You with the ability to create and maintain a username, email, and password for accessing the Service (an IDEX “Account”). By accessing an IDEX Account, You agree to provide true, accurate, and complete information during registration and to update Your information during use to keep it current.`, 
	account_content2:`By accessing IDEX through an Account, you warrant (i) that you are 13 years of age or older, (ii) if you are the age of majority in your jurisdiction or over, that you have read, understood, and accept to be bound by the Terms, and (iii) if you are between 13 and the age of majority in your jurisdiction, that your legal guardian has reviewed and agrees to these Terms.`, 
	account_content3:`As Your email and password are personal and considered to be confidential information, You are at all times entirely liable for all acts and omissions by the people You have allowed to access the platform through Your Account. You are responsible for keeping Your IDEX Account safe and secure from unauthorized access and must notify Us promptly of any unauthorized use or security breach to Your Account.`, 
	access_join:`Joining a Space`, 
	join_content1:`The Service might allow You to access a registered subdomain (a “Space”) identified with an URL (e.g. company.idexinnovation.com) and a name (e.g. Company Inc). A Space may also be hosted on your company’s subdomain. For example, if your company uses the domain acme.com, the Space URL might be ideas.acme.com or idex.acme.com. Spaces allow groups of participants who have a common interest or affiliation to share Content and collaborate.`, 
	join_content2:`You might access IDEX Spaces by logging in to or creating an Account (in which case You are referred to as a “Member”) or without logging in to or creating an Account for the Service (in which case You are referred to as a “Visitor”).`, 
	join_content3:`Any Space may contain Content not suitable for You. You can always choose to stop visiting a Space if You find it to be unsuitable in any way.`, 
	access_ownership: `Space Ownership`,
	ownership_content1: `IDEX might provide You with the ability to create and/or maintain a Space. Every such Space has one specific Member with more access and editing rights as well as overall responsibility, referred to in the T&C as the “Primary Owner Account”, “Primary Owner” or “Owner”.  When registering a Space, You will be made a Member of the Space and assigned to be the Primary Owner Account. At any point in time there is one Primary Owner Account on every Space.`,
	ownership_content2:`At the same time, every Space has one person or entity that is responsible for the activity on that Space, known as the “Customer”. You acknowledge and agree that by becoming a Member of a Space You can be assigned or have preassigned the role of an Owner Account and can be made responsible as a Customer.`,
	ownership_content3:`If a Space is registered through an Account with a personal email and/or is registered for a personal cause that is not affiliated with a business, organization, brand or another entity, the Owner Account of the Space is personally responsible for the activity on the Space. In this case the Owner Account on the Space is considered to be the Customer and is also referred to as a “Personal Customer”.`,
	ownership_content4:`If a Space is registered from an Account with a business email and/or for a company, business, organization, brand or similar, the Owner Account of the Space agrees to these T&C on behalf of the entity and acts as a representative for the entity. In this case the entity is considered to be the Customer and is also referred to as a “Business Customer”.`,
	access_inviting:`Inviting People`,
	inviting_content1:`In using Our Service it may be possible for You to share invitation links, inviting people by email or in other ways make the Space available to more people. By sharing these invitation links or inviting people, or in other ways making it possible for people to join a Space, You accept that You can be held responsible for their conduct and the Content posted by them. You accept that You are responsible for keeping people that you allow access to the Space aware of their rights and these T&C, as well as ensuring the legality and lawfulness of them using the Service.`,
	access_party:`Third Party Services`,
	party_content1:`IDEX reserves the right to use know-how, people or technology provided by a third-party to deliver parts of the Service under these T&C. This may include but is not limited to for example third-party payment processors, email infrastructure providers or User authentication. Please be advised that third-party Services might have their own terms and policies that govern Your conduct through terms, conditions and privacy policies in addition to these T&C. When working with third-party providers we only use established and industry leading service providers with good practices. We make sure we have written agreements that bind all third party Services used in Our Service to comply with data protection obligations not less protective than those in our Agreements.`,
	party_content2:`Our Service may include links to websites and Content maintained by third-parties. IDEX is not responsible and is not liable for any connection to or reliance on Content of third-party websites or services. Our links to third-party websites or Content do not imply that We support, promote, guarantee or recommend any such Content or website, their views or their authors or owners.`,
	party_content3:`In using our Service it may be possible for You to connect Content, Accounts or Spaces with third-party services. If You choose to connect Content, Accounts or Spaces on the Service with a third-party service (e.g. through an API, Webhook, Authentication, SSO or similar integration) You are responsible for what these third-party services do with this Content, including wanted or unwanted use, disclosure, loss, modification or deletion of any or all such Content, Accounts or Spaces. Please be advised that third-party Services might have their own terms and policies that govern Your rights and conduct.`,
	party_content4:`If You are an individual located in jurisdictions outside of European Economic Area with laws and regulations governing personal data collection, use, and disclosure that differ from European Economic Area laws, please be aware that information we collect (including through the use of methods such as cookies and other web technologies) might be processed and stored in the European Economic Area or in other countries where we or our third-party services providers have operations.`,
	party_content5:`By submitting personal information to IDEX and using IDEX, You expressly consent to having Your personal data transferred to, processed, and stored in the European Economic Area or another jurisdiction which may not offer the same level of privacy protection as those in the country where You reside or are a citizen.`,
	content:`Content on the Service`,
	content_content1:`We may, at our sole discretion, from time to time allow You to upload, store, share, send or otherwise make available Content through our Service - for example by submitting a comment, creating an idea or uploading a file attachment.`,
	content_who:`Who Owns Content?`,
	who_content1:`Content that You upload, store, share or send through our Service remains Your, or the original owner’s, intellectual property. In the event that you have an agreement with your employer or company that you are contracting with, and it is this employer/business that is the Primary Owner of the Space, then the intellectual property is assigned to the employer/business that you have an agreement with. IDEX does not receive ownership of any Content You or others submit to the Service in the course of using the Service. These T&C do not give us any rights to Your Content apart from the limited rights that enable us to run and improve our Service that are described in the following sections about Content on the Service.`,
	who_content2:`We are not liable or responsible for any actions You take with Your or other Content in connection with the Service. IDEX does not generally monitor Spaces to review Content posted in them and is not responsible for any such Content.`,
	who_content3:`By submitting Content to the Service You warrant that (i) You have the necessary ownership rights, licenses and consent to grant us the necessary rights to run and improve Our Service (ii) the Content itself, Your action of uploading, posting, communicating or otherwise making it available via the Service is in no way infringing or violating a third-party’s rights in any way and does not result in the violation of any law or regulation.`,
	who_content4:`You acknowledge that You have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use any and all Content that You submit. Furthermore, You are fully liable if such Content is infringing upon third-party rights, and accordingly agree to indemnify IDEX for all claims and losses related to such infringement and/or illegality.`,
	who_content5:`You are aware that by submitting Content to IDEX You might be sharing it with other people. You are also aware that other Users on the Space with access to your content might choose to share, edit, make available to third-parties or otherwise take action on Content You submit to IDEX.`,
	content_rights:`We reserve some rights to run the Service`,
	rights_content1:`IDEX allows You to upload, submit, store, send and receive Content. You retain ownership of any intellectual property rights that You hold in that Content. In short, what belongs to You stays Yours. But, to be able to serve the Content and to run the Service, we reserve a set of standardized rights.`,
	rights_content2:`You give Us the worldwide, royalty-free, non-exclusive, perpetual right to store, display, modify, edit, send, delete, scan, analyze, track, repackage and reproduce Your Content to the extent necessary to provide our Service - without further notice or consent from You. This right extends to partners and third-party services that we work together with to allow for a smooth operation of the Service. This allows the Service, for example, to display Your idea to other people after You have posted it, or to crop an image.`,
	content_improve:`We reserve some rights to improve the Service`,
	improve_content1:`You consent that IDEX employees can use Your activities and Content on the Service to continuously optimize the performance and presentation of the Service. We reserve the right to publish our findings on an anonymized aggregate level. An example of an anonymized finding would be study of how many people, in general, comment on an idea they have also liked.`,
	improve_content2:`We also retain the right, but not the obligation, to directly access Your account data or a Space on invitation by a Member of a Space for purposes of technical maintenance, content oversight or investigation as well as general Customer support.`,
	improve_content3:`Any feedback or circumstantial analytical evidence knowingly given or unknowingly resulting from usage of using our Service can freely be exploited and shared by Us to improve Our Service or technology without this resulting in You having or receiving any rights or ownership of them.`,
	content_protecting:`Protecting Your Content`,
	protecting_content1:`Protecting Your Content is a top-priority for Us. While we do try to minimize access to your Content in general We retain the right, but not the obligation, to generally monitor individual Spaces, Member, activity and Content.`,
	protecting_content2:`We will only investigate individually the Content of Members or Spaces to determine if people comply with these T&C or to satisfy any law, regulation or valid authorized government request. Activity is monitored and analysed more frequently and can be used to improve Our Service, including Customer Support.`,
	protecting_content3:`IDEX and its Employees shall retain the right, but not the obligation, to generally monitor and observe Your activities on Our Service to determine compliance with these T&C and to investigate Content to determine compliance with these T&C and any operating rules established by IDEX to satisfy any law, regulation or valid authorized government request.`,
	customer:`Customer Responsibilities`,
	customer_content1:`You are solely responsible for what happens with your Space you own on the Service. As a Customer (having control of the Owner Account) it might, for example, be possible to enable, disable or otherwise change settings, options, access and editing rights, roles of Members and properties of the Space, invite people, share access, add third-party integrations and much more. The Customer of a Space is responsible for actions concerning the Space, including causing wanted or unwanted use, disclosure, loss, modification or deletion of any Content, Accounts or Spaces.`,
	customer_content2:`As a Customer you warrant that You are a human (not a bot) and at least 18 years old. You also warrant that You have sufficient capacity to enter into legally binding contracts and that You do not break any laws or regulations by entering into the Customer Agreement.`,
	customer_content3:`The Customer on a Space is considered an intermediary with the obligation to regulate actions and Content of Accounts with access to the Space. The Customer on a Space is at all times entirely liable for all acts and omissions by people actively or passively allowed to access the Space.`,
	subscriptions:`Subscriptions`,
	subscriptions_content1:`Some of our Services are billed on a subscription basis (fittingly called “Subscription” and “Subscriptions”).`,
	subscriptions_content2:`You agree to pay for Subscriptions that You purchase or use, according to the pricing and payment terms presented to You on an order form, on our Service, or in, in special circumstances, on a Subscription offer sent by one of Our employees (an “Offer”).`,
	subscriptions_billing:`Billing Cycle and Billing Periods`,
	billing_content1:`You can choose to pay for a Subscription either on a monthly basis (“Monthly Billing”) or on a yearly basis (“Yearly Billing”). This “Billing Cycle” governs the length of the “Billing Period”, the time for which a Subscription is paid.`,
	billing_content2:`A Billing Period is entered when a Subscription begins or is modified or when You change Your Billing Cycle. The day a Billing Period commences is called the “Start Date” of a Billing Period. The “End Date” of a Billing Period depends on the Billing Cycle.`,
	billing_content3:`With Monthly Billing, the End Date of a Billing Period is on, or close to the same date the next month. For example, if You are paying with Monthly Billing and You start a Subscription on the 15th of October, You will have the 15th of November as the End Date for the Billing Period.`,
	billing_content4:`With Yearly Billing, the End Date of a Billing Period is on, or close to the same date the next year. For example, if You are paying with Yearly Billing, starting a Subscription on the 15th of October will have the 15th of October in the next Year as the End Date for the Billing Period.`,
	billing_content5:`In the case that the Start Date is close to the end of the Month, You can experience that the End Date over time will shift back some days to stay within the Month. For example, a Customer with Monthly Billing  entering a Billing Period with a Start Date on October 31 will have a corresponding End Date on November 30. The End Date will not automatically shift forward again in longer months.`,
	subscriptions_renewal:`Automatic Renewal and Recurring Billing`,
	renewal_content1:`You hereby agree to automatic renewal of Subscriptions. This means that any Subscription You purchase will renew at the end of its Billing Period, unless You cancel it before.`,
	renewal_content2:`By registering a credit card with IDEX, You hereby agree to recurring billing. You authorized Us (or a designated third-party provider) to bill and automatically charge Your Credit Card on the (or close to the) same date every Billing Period, starting from the time a Subscription is bought or updated or You change Your Billing Cycle. What You are paying for depends on Your choice of Subscription and can be seen at checkout, on Our pricing page or specified in an Offer.`,
	renewal_content3:`Please be aware that the price of a Subscription might depend on certain usage parameters, for example the number of ideas collected on a Space. You hereby accept that any changes affecting a Subscription during a Billing Period (e.g upgrades, downgrades, additional or canceled subscriptions, additional usage, fees) are automatically reflected in the next bill sent to You.`,
	subscriptions_payment:`Payment Terms, Taxes and Functionality`,
	payment_content1:`Payments for Subscriptions must always be made in advance and payment is due immediately when Subscribing and will be considered unpaid if it remains unpaid (7) days past the invoice date. In special circumstances, other methods of payments (e.g. a bank transfer) might be requested by IDEX. Costs of Subscriptions may include fees for payment card processing of up to 4% of a Subscription price. What the Customer is paying for depends on his or her choice of Subscription and can be seen at the checkout date.`,
	payment_content2:`IDEX Subscriptions are pre-paid and non-refundable. IDEX does in general not provide refunds or exchange for any partial time of use of its Service, any remaining Credits, data or Content except when required by law or explicitly stated in an Offer. In the event that the credit card registered with IDEX is invalid for payment for any reason, or an invoice has otherwise been left unpaid then the Customer will remain responsible for any uncollected amounts. In case of non-payment, we reserve the right to apply a monthly late fee of up to 2% of the outstanding amount.`,
	payment_content3:`All prices might be stated exclusive of taxes (VAT or otherwise), which may or may not be added to the price, depending on applicable law and Your legal residence. You are responsible for paying any applicable taxes or fees.`,
	payment_content4:`From time to time we might give insight into when and which new features on Our Service are expected to be available. Buying decisions should not depend on this information, as we cannot guarantee them. We reserve the right to adjust the prices, fees, functionality, and usage limits or other limitations for the Service and Subscriptions from time to time. Adjusted prices for active Subscriptions shall take effect earliest upon Your subsequent Billing Period of the Service following the date of the price change.`,
	subscriptions_trials:`Trials`,
	trials_content1:`IDEX may offer limited-time access to paid Subscriptions (referred to as “Trials”). When using a Trial, You are granted access to the features of a given Subscription on a Space for a limited amount of time (the “Trial Period”), disclosed at the outset of the Trial.`,
	trials_content2:`If You have already registered payment information on the relevant Account or Space, the Trial will automatically turn into a paid Subscription with Monthly Billing at the end of the Trial period, if it is not canceled before.`,
	subscriptions_termination:`Termination of Subscriptions`,
	termination_content1:`As a Customer, if You wish to cancel a Subscription, You may do so by contacting us directly. You may cancel a Monthly Subscription at any time and a Yearly Subscription at any time up to 30 days in advance of the end of the Billing Period. Access to the additional features or limits of the Subscription will stay accessible until the end of the Billing Period. At any point in time, only the Owner Account of a Space can set in motion the start, end or change of a Subscription of his or her Space.`,
	termination_content2:`If You successfully cancel or otherwise terminate a Subscription in a Billing Period, You will not have to pay for the next upcoming Billing Period. For example, if You are paying with Monthly Billing from 14th to 14th of each month and terminate the Subscription on the 13th of one month You will not be billed on the 14th of that month for the next Billing Period.`,
	termination_content3:`In case of non-payment for any reason or any violation of this Customer Agreement, IDEX shall be entitled - without liability - to immediately bar access to the Service and/or parts of the Service and/or bar access to paid Subscriptions, and/or to terminate a Space You are in control of. In practice, IDEX will make reasonable efforts to inform You of unsuccessful, invalid or missing payments before taking such steps.`,
	suspension:`Change or Suspension of Service`,
	suspension_content1:`IDEX reserves the right, in its reasonable discretion, to suspend or end access to and use of the Service or parts of the Service for Accounts, Customers, Integrations and/or a Space (i) during planned downtime for upgrades and maintenance to the Service (known as “Planned Downtime”) (ii) during any unavailability caused by circumstances beyond IDEX’s reasonable control, such as, but not limited to, Events of Force Majeure, acts of government, acts of terror or civil unrest, technical failures beyond IDEX’s reasonable control (including, without limitation, inability to access the Internet), or acts undertaken by third parties, including, without limitation, distributed denial of service attacks (iii) if Accounts do not comply with the provisions laid out in these terms (iv) in the event that a Customer owes any amount to IDEX for any reason or (v) if We suspect or detect any Malicious Software connected to Your Account or a Space You are a part of.`,
	suspension_content2:`IDEX has the right at its sole discretion to discontinue the Service and delete all Content for any reason at any time. In that event, IDEX will notify You 30 days in advance and make available a copy of Your Content during the time of notice as far as We consider technically and legally feasible.`,
	warranty:`Disclaimer of Warranty`,
	warranty_content1:`We do Our best to build and operate a Service to high standards but we cannot guarantee everything.`,
	warranty_content2:`Therefore, to the fullest extent possible by law, our Service is provided “as is” without any representations or warranties, express or implied. In other words, IDEX is always in beta. IDEX makes no representations or warranties in relation to our Service, its fitness for a certain purpose or the information and materials provided through our Service.`,
	warranty_content3:`You agree that the Service may be updated, modified, changed, interrupted or discontinued at any time without notice or liability. We reserve the right to restrict or terminate Your access to the Service or parts of the Service at any time. We are not responsible or liable for the deletion, correction, destruction, damage, loss or failure to store any Content or personalization settings submitted to our Service.`,
	liability:`Limitation of Liability`,
	liability_content1:`You understand and agree that IDEX or any entity involved in creating, producing or distributing the Service will not under any circumstances be liable for any damages, including, without limitation, lost profits, direct, indirect, incidental, special, consequential or punitive damages arising out of the use of or inability to use or access of the Service or Content on the Service.`,
	liability_content2:`IDEX is not liable for the defamatory, offensive or illegal conduct of other people or third parties and that the risk of injury from these causes of actions rests entirely with You.`,
	liability_content3:`IDEX is not liable for any loss, damage, fees or misconduct resulting from unauthorized access of Your account caused by failure on Your side to safeguard the access to Your Account.`,
	liability_content4:`Neither IDEX nor any of its affiliates, information providers or other third party providers or partners are liable regardless of the cause or duration, for any errors, inaccuracies, omissions, or other defects in, or untimeliness of, the information contained within the Service or Content on the Service. No part of Our Service constitutes, or is meant to constitute, advice of any kind. If You require advice in relation to any legal or financial matter You should consult an appropriate professional.`,
	liability_content5:`IDEX may fully cooperate with any law enforcement requesting or directing IDEX to disclose confidential or private information or Content of any Account or Customer. You understand and agree that IDEX or any entity involved in creating, producing or distributing the Service will not under any circumstances be liable for any damages, including, without limitation, lost profits, direct, indirect, incidental, special, consequential or punitive damages arising out of such compliance or cooperation with law enforcement.`,
	liability_content6:`Any breach of the Community Guidelines with material consequences for IDEX will make You liable for damages suffered by IDEX and other people that it might cause harm to.`,
	liability_content7:`If IDEX is found liable for any event or circumstance resulting from these T&C with You, Our liability cannot exceed the amount charged to You by Us during the course of the last twelve (12) months.`,
	indemnification:`Indemnification`,
	indemnification_content1:`You agree to defend, indemnify and hold harmless IDEX, its affiliates and their respective directors, officers, employees and agents from and against all claims and expenses, including attorneys’ fees, arising out of Your use of IDEX. You are fully liable if Content You submit is infringing upon third party rights, and You accordingly agree to indemnify IDEX for all claims and losses related to such infringement and/or illegality. You acknowledge that the provisions of this section shall apply to all Content on the Service.`,
	general:`General Rules`,
	general_copyright:`Copyright and Ownership`,
	copyright_content1:`IDEX, its look and feel and its logos are owned by IDEX and we reserve all rights. All other trademarks appearing on Our Service are the property of their respective owners.`,
	copyright_content2:`You acknowledge that IDEX and its licensors retain all intellectual property rights and title, in and to, all of Our confidential Information and/or other proprietary information. This includes but is not limited to: products, services, concepts, techniques, inventions, processes, software or writing part of the Services provided by IDEX.`,
	copyright_content3:`You understand that although We might use terms like “sell”, “own”, and “unlimited” - ownership of Our Service and technology remains with Us. No rights for IDEX technology and intellectual property are transferred. You understand that the Service is hosted online and You have no rights to access, copy and/or host the source code of Our technology. You are responsible for obtaining and maintaining, monetarily or otherwise, all telephone, computer hardware, software, internet service plans and other equipment needed for access to and use of IDEX and all charges related thereto.`,
	general_relationship:`Relationship of the Parties`,
	relationship_content1:`These T&C do not create a partnership, franchise, joint venture, agency, and fiduciary or employment relationship amongst You and IDEX.`,
	general_survival:`Survival of Terms`,
	survival_content1:`You agree that the provisions of these T&C that (by their nature) should survive termination will indeed survive any termination of these T&C. Any termination by either of the parties or the expiry of the term of these T&C shall only have effect for the future, and shall have no retroactive effects.`,
	general_severability:`Severability`,
	severability_content1:`Should any provision in these T&C be held by a court of competent jurisdiction to be unenforceable, such provision shall be modified by the court and interpreted so as to best accomplish the original provision to the fullest extent permitted by the applicable law. The remaining provisions of these T&C shall remain in full effect.`,
	severability_content2:`Without foregoing the above general statement, We want to make clear that some consumer protection laws or other legislation in Your jurisdiction may not allow certain limitations of liability, warranty restrictions and/or billing policies in these T&C. In the case that any limitation of liability, warranty restriction and/or billing policy is specifically prohibited by applicable law, such limitation of liability, warranty restriction and/or billing policy may not apply to You. In that case We kindly ask You to inform Us in advance, if You want to make use of a certain government regulation or provision that You see in conflict with these T&C.`,
	general_waiver:`No Waiver`,
	waiver_content1:`If IDEX does not explicitly exercise a particular right under these T&C, that does not waive them.`,
	general_entire:`Entire Agreement`,
	entire_content1:`These T&C (including all documents included by reference into them) are the whole agreement between You and Us concerning Our Service.`,
	entire_content2:`We might choose to run contests, promotions or marketing campaigns through our Service that may be governed by a separate set of terms and conditions. If You choose to participate in any such contests, promotions or marketing campaigns please review the conditions that apply to them as they might conflict and override these T&C. Likewise, by accepting a sales offer that specifies or extends this agreement, additional conditions and policies might apply.`,
	general_law:`Governing Law`,
	law_content1:`These T&C shall be governed by the substantive laws of England and Wales without regard to conflict of laws principles. You hereby expressly agree to submit to the exclusive jurisdiction of the United Kingdom, for the purpose of resolving any dispute relating to these T&C or access to or use of the Service.`,
	general_dispute:`Dispute Resolution`,
	dispute_content1:`If You are considering filing a dispute or claim We encourage you to try to settle Your claim with Us on an informal basis for 21 days before doing so through formal channels. To do so, please contact us by sending a mail to info@idexltd.com with the subject line “Dispute Notice”.`,
	dispute_content2:`Together we will try to settle on a solution within 21 days of Your email - if we fail to come to an agreement, You or IDEX are free to start a formal proceeding.`,
	dispute_content3:`Any dispute arising out of, or in connection with, the T&C, including any disputes regarding the existence, validity or termination thereof, shall be settled by arbitration administered by The Chartered Institute of Arbitrators (CIArb) in accordance with the rules of arbitration procedure adopted by The Chartered Institute of Arbitrators (CIArb) and in force at the time when such proceedings are commenced.`,
	dispute_content4:`You can only resolve disputes and arbitration with IDEX on an individual basis, not in a collective, consolidated or class action lawsuit. The arbitral tribunal shall be composed of one arbitrator. The place of arbitration shall be London, United Kingdom. The language to be used in the arbitral proceedings shall be English.`,
	general_notice:`Notice`,
	notice_content1:`All notices under the T&C will be served by mail to Your email address on record in Your Account information. Notices to IDEX shall be sent by email to info@idexltd.com. Any such notice shall be deemed to have been given upon the expiration of 72 hours after mailing or posting (if sent by mail or pre-paid post) or 24 hours after sending (if sent by email).`,
	general_majeure:`Force Majeure`,
	majeure_content1:`Neither party will be responsible for any failure or delay in performance due to circumstances beyond its reasonable control, including, without limitation, Events of Force Majeure, war, riot, embargoes, acts of civil or military authorities, fire, floods, accidents, service outages resulting from equipment and/or software failure and/or telecommunications failures, power failures, network failures, failures of third party service providers (including providers of internet services). The performance of these T&C shall then be suspended for as long as any such event will prevent the affected party from performing its required obligations under these T&C.`,
	general_question:`Questions?`,
	question_content1:`Please contact us at info@idexltd.com`,
	},
ES:{
	title:`Terms & Conditions`,
	introduction:`Introduction`,
	introduction_content1:`This is a legally binding agreement (“T&C”) governing Your (“You”, “Your”) access and use of our sites, mobile apps, products and services (collectively the “Service”) and all data, files, content or material uploaded, downloaded, produced, viewed or otherwise accessed through our Service (collectively the “Content”).`,
	introduction_content2:`The Service is provided by IDEX Innovation Software Trading LLC, with offices at 1101-19 Crystal Tower, Business Bay, Dubai, United Arab Emirates, doing business as IDEX Innovation & Project Management Software  - in short referred to as “IDEX Innovation”, “IDEX”,  “Us”, or “We”. Together You and IDEX make out the “Parties” of these T&C (individually the “Party”). By visiting one of our websites or otherwise making use of parts of Our Service, You are considered a “User” of our Services.`,
	introduction_content3:`By using IDEX, you agree to these T&C. If You do not agree or comply with these T&C, You must not accept them and must not start or continue to use IDEX.`,
	introduction_content4:`We reserve the right to adjust and change these T&C and any documents included by reference at any time. If a change is material we will let you know in advance. By continuing to use IDEX after the effective date, you agree to the new version.`,
	access:`Access to the Service`, 
	access_account:`Account`, 
	account_content1:`IDEX will provide You with the ability to create and maintain a username, email, and password for accessing the Service (an IDEX “Account”). By accessing an IDEX Account, You agree to provide true, accurate, and complete information during registration and to update Your information during use to keep it current.`, 
	account_content2:`By accessing IDEX through an Account, you warrant (i) that you are 13 years of age or older, (ii) if you are the age of majority in your jurisdiction or over, that you have read, understood, and accept to be bound by the Terms, and (iii) if you are between 13 and the age of majority in your jurisdiction, that your legal guardian has reviewed and agrees to these Terms.`, 
	account_content3:`As Your email and password are personal and considered to be confidential information, You are at all times entirely liable for all acts and omissions by the people You have allowed to access the platform through Your Account. You are responsible for keeping Your IDEX Account safe and secure from unauthorized access and must notify Us promptly of any unauthorized use or security breach to Your Account.`, 
	access_join:`Joining a Space`, 
	join_content1:`The Service might allow You to access a registered subdomain (a “Space”) identified with an URL (e.g. company.idexinnovation.com) and a name (e.g. Company Inc). A Space may also be hosted on your company’s subdomain. For example, if your company uses the domain acme.com, the Space URL might be ideas.acme.com or idex.acme.com. Spaces allow groups of participants who have a common interest or affiliation to share Content and collaborate.`, 
	join_content2:`You might access IDEX Spaces by logging in to or creating an Account (in which case You are referred to as a “Member”) or without logging in to or creating an Account for the Service (in which case You are referred to as a “Visitor”).`, 
	join_content3:`Any Space may contain Content not suitable for You. You can always choose to stop visiting a Space if You find it to be unsuitable in any way.`, 
	access_ownership: `Space Ownership`,
	ownership_content1: `IDEX might provide You with the ability to create and/or maintain a Space. Every such Space has one specific Member with more access and editing rights as well as overall responsibility, referred to in the T&C as the “Primary Owner Account”, “Primary Owner” or “Owner”.  When registering a Space, You will be made a Member of the Space and assigned to be the Primary Owner Account. At any point in time there is one Primary Owner Account on every Space.`,
	ownership_content2:`At the same time, every Space has one person or entity that is responsible for the activity on that Space, known as the “Customer”. You acknowledge and agree that by becoming a Member of a Space You can be assigned or have preassigned the role of an Owner Account and can be made responsible as a Customer.`,
	ownership_content3:`If a Space is registered through an Account with a personal email and/or is registered for a personal cause that is not affiliated with a business, organization, brand or another entity, the Owner Account of the Space is personally responsible for the activity on the Space. In this case the Owner Account on the Space is considered to be the Customer and is also referred to as a “Personal Customer”.`,
	ownership_content4:`If a Space is registered from an Account with a business email and/or for a company, business, organization, brand or similar, the Owner Account of the Space agrees to these T&C on behalf of the entity and acts as a representative for the entity. In this case the entity is considered to be the Customer and is also referred to as a “Business Customer”.`,
	access_inviting:`Inviting People`,
	inviting_content1:`In using Our Service it may be possible for You to share invitation links, inviting people by email or in other ways make the Space available to more people. By sharing these invitation links or inviting people, or in other ways making it possible for people to join a Space, You accept that You can be held responsible for their conduct and the Content posted by them. You accept that You are responsible for keeping people that you allow access to the Space aware of their rights and these T&C, as well as ensuring the legality and lawfulness of them using the Service.`,
	access_party:`Third Party Services`,
	party_content1:`IDEX reserves the right to use know-how, people or technology provided by a third-party to deliver parts of the Service under these T&C. This may include but is not limited to for example third-party payment processors, email infrastructure providers or User authentication. Please be advised that third-party Services might have their own terms and policies that govern Your conduct through terms, conditions and privacy policies in addition to these T&C. When working with third-party providers we only use established and industry leading service providers with good practices. We make sure we have written agreements that bind all third party Services used in Our Service to comply with data protection obligations not less protective than those in our Agreements.`,
	party_content2:`Our Service may include links to websites and Content maintained by third-parties. IDEX is not responsible and is not liable for any connection to or reliance on Content of third-party websites or services. Our links to third-party websites or Content do not imply that We support, promote, guarantee or recommend any such Content or website, their views or their authors or owners.`,
	party_content3:`In using our Service it may be possible for You to connect Content, Accounts or Spaces with third-party services. If You choose to connect Content, Accounts or Spaces on the Service with a third-party service (e.g. through an API, Webhook, Authentication, SSO or similar integration) You are responsible for what these third-party services do with this Content, including wanted or unwanted use, disclosure, loss, modification or deletion of any or all such Content, Accounts or Spaces. Please be advised that third-party Services might have their own terms and policies that govern Your rights and conduct.`,
	party_content4:`If You are an individual located in jurisdictions outside of European Economic Area with laws and regulations governing personal data collection, use, and disclosure that differ from European Economic Area laws, please be aware that information we collect (including through the use of methods such as cookies and other web technologies) might be processed and stored in the European Economic Area or in other countries where we or our third-party services providers have operations.`,
	party_content5:`By submitting personal information to IDEX and using IDEX, You expressly consent to having Your personal data transferred to, processed, and stored in the European Economic Area or another jurisdiction which may not offer the same level of privacy protection as those in the country where You reside or are a citizen.`,
	content:`Content on the Service`,
	content_content1:`We may, at our sole discretion, from time to time allow You to upload, store, share, send or otherwise make available Content through our Service - for example by submitting a comment, creating an idea or uploading a file attachment.`,
	content_who:`Who Owns Content?`,
	who_content1:`Content that You upload, store, share or send through our Service remains Your, or the original owner’s, intellectual property. In the event that you have an agreement with your employer or company that you are contracting with, and it is this employer/business that is the Primary Owner of the Space, then the intellectual property is assigned to the employer/business that you have an agreement with. IDEX does not receive ownership of any Content You or others submit to the Service in the course of using the Service. These T&C do not give us any rights to Your Content apart from the limited rights that enable us to run and improve our Service that are described in the following sections about Content on the Service.`,
	who_content2:`We are not liable or responsible for any actions You take with Your or other Content in connection with the Service. IDEX does not generally monitor Spaces to review Content posted in them and is not responsible for any such Content.`,
	who_content3:`By submitting Content to the Service You warrant that (i) You have the necessary ownership rights, licenses and consent to grant us the necessary rights to run and improve Our Service (ii) the Content itself, Your action of uploading, posting, communicating or otherwise making it available via the Service is in no way infringing or violating a third-party’s rights in any way and does not result in the violation of any law or regulation.`,
	who_content4:`You acknowledge that You have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use any and all Content that You submit. Furthermore, You are fully liable if such Content is infringing upon third-party rights, and accordingly agree to indemnify IDEX for all claims and losses related to such infringement and/or illegality.`,
	who_content5:`You are aware that by submitting Content to IDEX You might be sharing it with other people. You are also aware that other Users on the Space with access to your content might choose to share, edit, make available to third-parties or otherwise take action on Content You submit to IDEX.`,
	content_rights:`We reserve some rights to run the Service`,
	rights_content1:`IDEX allows You to upload, submit, store, send and receive Content. You retain ownership of any intellectual property rights that You hold in that Content. In short, what belongs to You stays Yours. But, to be able to serve the Content and to run the Service, we reserve a set of standardized rights.`,
	rights_content2:`You give Us the worldwide, royalty-free, non-exclusive, perpetual right to store, display, modify, edit, send, delete, scan, analyze, track, repackage and reproduce Your Content to the extent necessary to provide our Service - without further notice or consent from You. This right extends to partners and third-party services that we work together with to allow for a smooth operation of the Service. This allows the Service, for example, to display Your idea to other people after You have posted it, or to crop an image.`,
	content_improve:`We reserve some rights to improve the Service`,
	improve_content1:`You consent that IDEX employees can use Your activities and Content on the Service to continuously optimize the performance and presentation of the Service. We reserve the right to publish our findings on an anonymized aggregate level. An example of an anonymized finding would be study of how many people, in general, comment on an idea they have also liked.`,
	improve_content2:`We also retain the right, but not the obligation, to directly access Your account data or a Space on invitation by a Member of a Space for purposes of technical maintenance, content oversight or investigation as well as general Customer support.`,
	improve_content3:`Any feedback or circumstantial analytical evidence knowingly given or unknowingly resulting from usage of using our Service can freely be exploited and shared by Us to improve Our Service or technology without this resulting in You having or receiving any rights or ownership of them.`,
	content_protecting:`Protecting Your Content`,
	protecting_content1:`Protecting Your Content is a top-priority for Us. While we do try to minimize access to your Content in general We retain the right, but not the obligation, to generally monitor individual Spaces, Member, activity and Content.`,
	protecting_content2:`We will only investigate individually the Content of Members or Spaces to determine if people comply with these T&C or to satisfy any law, regulation or valid authorized government request. Activity is monitored and analysed more frequently and can be used to improve Our Service, including Customer Support.`,
	protecting_content3:`IDEX and its Employees shall retain the right, but not the obligation, to generally monitor and observe Your activities on Our Service to determine compliance with these T&C and to investigate Content to determine compliance with these T&C and any operating rules established by IDEX to satisfy any law, regulation or valid authorized government request.`,
	customer:`Customer Responsibilities`,
	customer_content1:`You are solely responsible for what happens with your Space you own on the Service. As a Customer (having control of the Owner Account) it might, for example, be possible to enable, disable or otherwise change settings, options, access and editing rights, roles of Members and properties of the Space, invite people, share access, add third-party integrations and much more. The Customer of a Space is responsible for actions concerning the Space, including causing wanted or unwanted use, disclosure, loss, modification or deletion of any Content, Accounts or Spaces.`,
	customer_content2:`As a Customer you warrant that You are a human (not a bot) and at least 18 years old. You also warrant that You have sufficient capacity to enter into legally binding contracts and that You do not break any laws or regulations by entering into the Customer Agreement.`,
	customer_content3:`The Customer on a Space is considered an intermediary with the obligation to regulate actions and Content of Accounts with access to the Space. The Customer on a Space is at all times entirely liable for all acts and omissions by people actively or passively allowed to access the Space.`,
	subscriptions:`Subscriptions`,
	subscriptions_content1:`Some of our Services are billed on a subscription basis (fittingly called “Subscription” and “Subscriptions”).`,
	subscriptions_content2:`You agree to pay for Subscriptions that You purchase or use, according to the pricing and payment terms presented to You on an order form, on our Service, or in, in special circumstances, on a Subscription offer sent by one of Our employees (an “Offer”).`,
	subscriptions_billing:`Billing Cycle and Billing Periods`,
	billing_content1:`You can choose to pay for a Subscription either on a monthly basis (“Monthly Billing”) or on a yearly basis (“Yearly Billing”). This “Billing Cycle” governs the length of the “Billing Period”, the time for which a Subscription is paid.`,
	billing_content2:`A Billing Period is entered when a Subscription begins or is modified or when You change Your Billing Cycle. The day a Billing Period commences is called the “Start Date” of a Billing Period. The “End Date” of a Billing Period depends on the Billing Cycle.`,
	billing_content3:`With Monthly Billing, the End Date of a Billing Period is on, or close to the same date the next month. For example, if You are paying with Monthly Billing and You start a Subscription on the 15th of October, You will have the 15th of November as the End Date for the Billing Period.`,
	billing_content4:`With Yearly Billing, the End Date of a Billing Period is on, or close to the same date the next year. For example, if You are paying with Yearly Billing, starting a Subscription on the 15th of October will have the 15th of October in the next Year as the End Date for the Billing Period.`,
	billing_content5:`In the case that the Start Date is close to the end of the Month, You can experience that the End Date over time will shift back some days to stay within the Month. For example, a Customer with Monthly Billing  entering a Billing Period with a Start Date on October 31 will have a corresponding End Date on November 30. The End Date will not automatically shift forward again in longer months.`,
	subscriptions_renewal:`Automatic Renewal and Recurring Billing`,
	renewal_content1:`You hereby agree to automatic renewal of Subscriptions. This means that any Subscription You purchase will renew at the end of its Billing Period, unless You cancel it before.`,
	renewal_content2:`By registering a credit card with IDEX, You hereby agree to recurring billing. You authorized Us (or a designated third-party provider) to bill and automatically charge Your Credit Card on the (or close to the) same date every Billing Period, starting from the time a Subscription is bought or updated or You change Your Billing Cycle. What You are paying for depends on Your choice of Subscription and can be seen at checkout, on Our pricing page or specified in an Offer.`,
	renewal_content3:`Please be aware that the price of a Subscription might depend on certain usage parameters, for example the number of ideas collected on a Space. You hereby accept that any changes affecting a Subscription during a Billing Period (e.g upgrades, downgrades, additional or canceled subscriptions, additional usage, fees) are automatically reflected in the next bill sent to You.`,
	subscriptions_payment:`Payment Terms, Taxes and Functionality`,
	payment_content1:`Payments for Subscriptions must always be made in advance and payment is due immediately when Subscribing and will be considered unpaid if it remains unpaid (7) days past the invoice date. In special circumstances, other methods of payments (e.g. a bank transfer) might be requested by IDEX. Costs of Subscriptions may include fees for payment card processing of up to 4% of a Subscription price. What the Customer is paying for depends on his or her choice of Subscription and can be seen at the checkout date.`,
	payment_content2:`IDEX Subscriptions are pre-paid and non-refundable. IDEX does in general not provide refunds or exchange for any partial time of use of its Service, any remaining Credits, data or Content except when required by law or explicitly stated in an Offer. In the event that the credit card registered with IDEX is invalid for payment for any reason, or an invoice has otherwise been left unpaid then the Customer will remain responsible for any uncollected amounts. In case of non-payment, we reserve the right to apply a monthly late fee of up to 2% of the outstanding amount.`,
	payment_content3:`All prices might be stated exclusive of taxes (VAT or otherwise), which may or may not be added to the price, depending on applicable law and Your legal residence. You are responsible for paying any applicable taxes or fees.`,
	payment_content4:`From time to time we might give insight into when and which new features on Our Service are expected to be available. Buying decisions should not depend on this information, as we cannot guarantee them. We reserve the right to adjust the prices, fees, functionality, and usage limits or other limitations for the Service and Subscriptions from time to time. Adjusted prices for active Subscriptions shall take effect earliest upon Your subsequent Billing Period of the Service following the date of the price change.`,
	subscriptions_trials:`Trials`,
	trials_content1:`IDEX may offer limited-time access to paid Subscriptions (referred to as “Trials”). When using a Trial, You are granted access to the features of a given Subscription on a Space for a limited amount of time (the “Trial Period”), disclosed at the outset of the Trial.`,
	trials_content2:`If You have already registered payment information on the relevant Account or Space, the Trial will automatically turn into a paid Subscription with Monthly Billing at the end of the Trial period, if it is not canceled before.`,
	subscriptions_termination:`Termination of Subscriptions`,
	termination_content1:`As a Customer, if You wish to cancel a Subscription, You may do so by contacting us directly. You may cancel a Monthly Subscription at any time and a Yearly Subscription at any time up to 30 days in advance of the end of the Billing Period. Access to the additional features or limits of the Subscription will stay accessible until the end of the Billing Period. At any point in time, only the Owner Account of a Space can set in motion the start, end or change of a Subscription of his or her Space.`,
	termination_content2:`If You successfully cancel or otherwise terminate a Subscription in a Billing Period, You will not have to pay for the next upcoming Billing Period. For example, if You are paying with Monthly Billing from 14th to 14th of each month and terminate the Subscription on the 13th of one month You will not be billed on the 14th of that month for the next Billing Period.`,
	termination_content3:`In case of non-payment for any reason or any violation of this Customer Agreement, IDEX shall be entitled - without liability - to immediately bar access to the Service and/or parts of the Service and/or bar access to paid Subscriptions, and/or to terminate a Space You are in control of. In practice, IDEX will make reasonable efforts to inform You of unsuccessful, invalid or missing payments before taking such steps.`,
	suspension:`Change or Suspension of Service`,
	suspension_content1:`IDEX reserves the right, in its reasonable discretion, to suspend or end access to and use of the Service or parts of the Service for Accounts, Customers, Integrations and/or a Space (i) during planned downtime for upgrades and maintenance to the Service (known as “Planned Downtime”) (ii) during any unavailability caused by circumstances beyond IDEX’s reasonable control, such as, but not limited to, Events of Force Majeure, acts of government, acts of terror or civil unrest, technical failures beyond IDEX’s reasonable control (including, without limitation, inability to access the Internet), or acts undertaken by third parties, including, without limitation, distributed denial of service attacks (iii) if Accounts do not comply with the provisions laid out in these terms (iv) in the event that a Customer owes any amount to IDEX for any reason or (v) if We suspect or detect any Malicious Software connected to Your Account or a Space You are a part of.`,
	suspension_content2:`IDEX has the right at its sole discretion to discontinue the Service and delete all Content for any reason at any time. In that event, IDEX will notify You 30 days in advance and make available a copy of Your Content during the time of notice as far as We consider technically and legally feasible.`,
	warranty:`Disclaimer of Warranty`,
	warranty_content1:`We do Our best to build and operate a Service to high standards but we cannot guarantee everything.`,
	warranty_content2:`Therefore, to the fullest extent possible by law, our Service is provided “as is” without any representations or warranties, express or implied. In other words, IDEX is always in beta. IDEX makes no representations or warranties in relation to our Service, its fitness for a certain purpose or the information and materials provided through our Service.`,
	warranty_content3:`You agree that the Service may be updated, modified, changed, interrupted or discontinued at any time without notice or liability. We reserve the right to restrict or terminate Your access to the Service or parts of the Service at any time. We are not responsible or liable for the deletion, correction, destruction, damage, loss or failure to store any Content or personalization settings submitted to our Service.`,
	liability:`Limitation of Liability`,
	liability_content1:`You understand and agree that IDEX or any entity involved in creating, producing or distributing the Service will not under any circumstances be liable for any damages, including, without limitation, lost profits, direct, indirect, incidental, special, consequential or punitive damages arising out of the use of or inability to use or access of the Service or Content on the Service.`,
	liability_content2:`IDEX is not liable for the defamatory, offensive or illegal conduct of other people or third parties and that the risk of injury from these causes of actions rests entirely with You.`,
	liability_content3:`IDEX is not liable for any loss, damage, fees or misconduct resulting from unauthorized access of Your account caused by failure on Your side to safeguard the access to Your Account.`,
	liability_content4:`Neither IDEX nor any of its affiliates, information providers or other third party providers or partners are liable regardless of the cause or duration, for any errors, inaccuracies, omissions, or other defects in, or untimeliness of, the information contained within the Service or Content on the Service. No part of Our Service constitutes, or is meant to constitute, advice of any kind. If You require advice in relation to any legal or financial matter You should consult an appropriate professional.`,
	liability_content5:`IDEX may fully cooperate with any law enforcement requesting or directing IDEX to disclose confidential or private information or Content of any Account or Customer. You understand and agree that IDEX or any entity involved in creating, producing or distributing the Service will not under any circumstances be liable for any damages, including, without limitation, lost profits, direct, indirect, incidental, special, consequential or punitive damages arising out of such compliance or cooperation with law enforcement.`,
	liability_content6:`Any breach of the Community Guidelines with material consequences for IDEX will make You liable for damages suffered by IDEX and other people that it might cause harm to.`,
	liability_content7:`If IDEX is found liable for any event or circumstance resulting from these T&C with You, Our liability cannot exceed the amount charged to You by Us during the course of the last twelve (12) months.`,
	indemnification:`Indemnification`,
	indemnification_content1:`You agree to defend, indemnify and hold harmless IDEX, its affiliates and their respective directors, officers, employees and agents from and against all claims and expenses, including attorneys’ fees, arising out of Your use of IDEX. You are fully liable if Content You submit is infringing upon third party rights, and You accordingly agree to indemnify IDEX for all claims and losses related to such infringement and/or illegality. You acknowledge that the provisions of this section shall apply to all Content on the Service.`,
	general:`General Rules`,
	general_copyright:`Copyright and Ownership`,
	copyright_content1:`IDEX, its look and feel and its logos are owned by IDEX and we reserve all rights. All other trademarks appearing on Our Service are the property of their respective owners.`,
	copyright_content2:`You acknowledge that IDEX and its licensors retain all intellectual property rights and title, in and to, all of Our confidential Information and/or other proprietary information. This includes but is not limited to: products, services, concepts, techniques, inventions, processes, software or writing part of the Services provided by IDEX.`,
	copyright_content3:`You understand that although We might use terms like “sell”, “own”, and “unlimited” - ownership of Our Service and technology remains with Us. No rights for IDEX technology and intellectual property are transferred. You understand that the Service is hosted online and You have no rights to access, copy and/or host the source code of Our technology. You are responsible for obtaining and maintaining, monetarily or otherwise, all telephone, computer hardware, software, internet service plans and other equipment needed for access to and use of IDEX and all charges related thereto.`,
	general_relationship:`Relationship of the Parties`,
	relationship_content1:`These T&C do not create a partnership, franchise, joint venture, agency, and fiduciary or employment relationship amongst You and IDEX.`,
	general_survival:`Survival of Terms`,
	survival_content1:`You agree that the provisions of these T&C that (by their nature) should survive termination will indeed survive any termination of these T&C. Any termination by either of the parties or the expiry of the term of these T&C shall only have effect for the future, and shall have no retroactive effects.`,
	general_severability:`Severability`,
	severability_content1:`Should any provision in these T&C be held by a court of competent jurisdiction to be unenforceable, such provision shall be modified by the court and interpreted so as to best accomplish the original provision to the fullest extent permitted by the applicable law. The remaining provisions of these T&C shall remain in full effect.`,
	severability_content2:`Without foregoing the above general statement, We want to make clear that some consumer protection laws or other legislation in Your jurisdiction may not allow certain limitations of liability, warranty restrictions and/or billing policies in these T&C. In the case that any limitation of liability, warranty restriction and/or billing policy is specifically prohibited by applicable law, such limitation of liability, warranty restriction and/or billing policy may not apply to You. In that case We kindly ask You to inform Us in advance, if You want to make use of a certain government regulation or provision that You see in conflict with these T&C.`,
	general_waiver:`No Waiver`,
	waiver_content1:`If IDEX does not explicitly exercise a particular right under these T&C, that does not waive them.`,
	general_entire:`Entire Agreement`,
	entire_content1:`These T&C (including all documents included by reference into them) are the whole agreement between You and Us concerning Our Service.`,
	entire_content2:`We might choose to run contests, promotions or marketing campaigns through our Service that may be governed by a separate set of terms and conditions. If You choose to participate in any such contests, promotions or marketing campaigns please review the conditions that apply to them as they might conflict and override these T&C. Likewise, by accepting a sales offer that specifies or extends this agreement, additional conditions and policies might apply.`,
	general_law:`Governing Law`,
	law_content1:`These T&C shall be governed by the substantive laws of England and Wales without regard to conflict of laws principles. You hereby expressly agree to submit to the exclusive jurisdiction of the United Kingdom, for the purpose of resolving any dispute relating to these T&C or access to or use of the Service.`,
	general_dispute:`Dispute Resolution`,
	dispute_content1:`If You are considering filing a dispute or claim We encourage you to try to settle Your claim with Us on an informal basis for 21 days before doing so through formal channels. To do so, please contact us by sending a mail to info@idexltd.com with the subject line “Dispute Notice”.`,
	dispute_content2:`Together we will try to settle on a solution within 21 days of Your email - if we fail to come to an agreement, You or IDEX are free to start a formal proceeding.`,
	dispute_content3:`Any dispute arising out of, or in connection with, the T&C, including any disputes regarding the existence, validity or termination thereof, shall be settled by arbitration administered by The Chartered Institute of Arbitrators (CIArb) in accordance with the rules of arbitration procedure adopted by The Chartered Institute of Arbitrators (CIArb) and in force at the time when such proceedings are commenced.`,
	dispute_content4:`You can only resolve disputes and arbitration with IDEX on an individual basis, not in a collective, consolidated or class action lawsuit. The arbitral tribunal shall be composed of one arbitrator. The place of arbitration shall be London, United Kingdom. The language to be used in the arbitral proceedings shall be English.`,
	general_notice:`Notice`,
	notice_content1:`All notices under the T&C will be served by mail to Your email address on record in Your Account information. Notices to IDEX shall be sent by email to info@idexltd.com. Any such notice shall be deemed to have been given upon the expiration of 72 hours after mailing or posting (if sent by mail or pre-paid post) or 24 hours after sending (if sent by email).`,
	general_majeure:`Force Majeure`,
	majeure_content1:`Neither party will be responsible for any failure or delay in performance due to circumstances beyond its reasonable control, including, without limitation, Events of Force Majeure, war, riot, embargoes, acts of civil or military authorities, fire, floods, accidents, service outages resulting from equipment and/or software failure and/or telecommunications failures, power failures, network failures, failures of third party service providers (including providers of internet services). The performance of these T&C shall then be suspended for as long as any such event will prevent the affected party from performing its required obligations under these T&C.`,
	general_question:`Questions?`,
	question_content1:`Please contact us at info@idexltd.com`,
	},
CN:{
	title:`条款与条件`,
	introduction:`简介`,
	introduction_content1:`这是一份具有法律约束力的条款和条件（“协议”），适用于您（“您的”）对我们网站，移动应用程序，产品和服务（统称“服务”）的访问或使用，以及通过我们的服务上传，下载，制作，查看或以其他方式访问的所有数据，文件，内容或材料（统称为“内容”）。`,
	introduction_content2:`该服务由深圳畅意创新发展有限公司提供，其办事处位于中国深圳，公司业务为畅意创新与项目管理系统 — 简称为“畅意科技”，“畅意系统”，“畅意”，“ IDEX”或“我们”。您和畅意共同为这些条款和条件的“当事人”。通过访问任一我们的网站或以其他方式使用我们的服务的一部分，您将被视为我们的服务的“用户”。`,
	introduction_content3:`使用畅意，即表示您同意这些条款和条件。如果您不同意或遵守这些条款和条件，您可以不接受它们，也不能开始或继续使用畅意。`,
	introduction_content4:`我们保留随时调整和更改这些条款和条件以及任何文中引用的文件的权利。如果有重大变化，我们将提前通知您。在生效日期之后继续使用畅意，即表示您同意新版本。`,
	access:`访问服务`, 
	access_account:`帐户`, 
	account_content1:`畅意将为您提供创建和维护用于访问服务的用户名，电子邮件和密码（畅意“帐户”）的功能。通过访问畅意帐户，您同意在注册过程中提供真实，准确和完整的信息，并在使用过程中更新您的信息以使其保持信息。`, 
	account_content2:`通过帐户访问畅意，您保证（i）您已年满13岁，（ii）如果您的年龄在您所在司法管辖区已成年，则表明您已阅读，理解并接受其约束（iii）如果您的年龄在您所在司法管辖区的13岁至成年年龄之间，则表明您的法定监护人已审查并同意这些条款。`, 
	account_content3:`由于您的电子邮件和密码是个人信息，被视为机密信息，因此您始终对您允许通过您的帐户访问平台的人员的所有作为和不作为承担全部责任。您有责任保护您的畅意帐户安全，防止未经授权的访问，并且必须将您帐户的任何未经授权的使用或安全漏洞立即通知我们。`, 
	access_join:`加入空间`, 
	join_content1:`该服务可能允许您访问网址（或URL，例如，company.idexinnovation.com）和名称（例如，Company Inc.）标识的注册子域（“空间”）。 空间也可以托管在您公司的子域中。 例如，如果您的公司使用acme.com域名，则空间网址可能是idea.acme.com或idex.acme.com。 空间允许具有共同兴趣或从属关系的参与者组共享内容并进行协作。`, 
	join_content2:`您可以通过登录或创建帐户（在这种情况下您被称为“成员”）或不登录或创建服务帐户（在这种情况下您称为“访客”）访问畅意空间。`, 
	join_content3:`任何空间都可能包含不适合您的内容。 如果您发现空间内容不合适，您可以随时选择停止访问该空间。`, 
	access_ownership: `空间所有权`,
	ownership_content1: `畅意可能为您提供创建和/或维护空间的能力。每个这样的空间都有一个特定的成员，该成员具有更多的访问和编辑权以及总体责任，在条款和条件中称为“主要所有者帐户”，“主要所有者”或“所有者”。注册空间时，您将成为该空间的成员，并被分配为主要所有者帐户。在任何时间点，每个空间上都有一个主要所有者帐户。`,
	ownership_content2:`同时，每个空间都有一个人或实体负责该空间上的活动，即“客户”。您承认并同意，成为空间成员可以为您分配或预先分配所有者帐户的角色，并可以使其成为客户的责任。`,
	ownership_content3:`如果某个空间是通过带有个人电子邮件的帐户注册的和/或出于与企业，组织，品牌或其他实体无关的个人原因而注册的，则该空间的所有者帐户应对该活动进行个人负责空间。在这种情况下，该空间上的所有者帐户被认为是客户，也被称为“个人客户”。`,
	ownership_content4:`如果空间是通过帐户向企业电子邮件和/或公司，企业，组织，品牌或类似机构注册的，则空间所有者账户代表实体同意这些条款和条件并充当实体的代表。在这种情况下，该实体被视为客户，也称为“商业客户”。`,
	access_inviting:`邀请成员`,
	inviting_content1:`使用我们的服务时，您可能会共享邀请链接，通过电子邮件或其他方式邀请他人，从而使更多人可以使用该空间。 通过共享这些邀请链接或邀请成员，或以其他方式使成员可以加入空间，即表示您接受对他们的行为和他们发布的内容负责。 您同意您有责任让允许您进入空间的成员了解他们的权利和这些条款和条件，并确保他们使用服务的合法性。`,
	access_party:`第三方服务`,
	party_content1:`畅意保留使用第三方提供的技术知识，人员或技术，并根据这些条款和条件交付部分服务的权利。这可能包括但不限于例如第三方支付处理系统，电子邮件基础架构提供商或用户身份验证。请注意，除这些条款和条件之外，第三方服务可能还有自己的条款和政策，通过条款，条件和隐私政策来管理您的行为。与第三方提供商合作时，我们仅使用具有良好实践的成熟且行业领先的服务提供商。我们确保我们已签订书面协议，将我们服务中使用的所有第三方服务约束在一起，以遵守数据保护义务，其保护性不低于我们协议中的义务。`,
	party_content2:`我们的服务可能包含指向第三方维护的网站和内容的链接。 畅意对与第三方网站或服务的内容的任何连接或依赖不承担任何责任。我们与第三方网站或内容的链接并不意味着我们支持，促进，保证或推荐任何此类内容或网站，其观点，其作者或所有者。`,
	party_content3:`在使用我们的服务时，您可能将内容，帐户或空间与第三方服务联系起来。如果您选择将服务上的内容，帐户或空间与第三方服务（例如通过API，Webhook，身份验证，单点登录或类似的集成）连接，则您应对这些第三方服务对该内容所做的一切负责，包括想要或不想要的使用，披露，丢失，修改或删除任何或所有此类内容，帐户或空间。请注意，第三方服务可能有自己的条款和政策来约束您的权利和行为。`,
	party_content4:`如果您是位于欧洲经济区以外司法管辖区的个人，且其法律和法规管理着与欧洲经济区法律不同的个人数据收集，使用和披露，请注意，我们收集的信息（包括通过使用诸如Cookie和其他网络技术）可能会在欧洲经济区或我们或我们的第三方服务提供商运营所在的其他国家/地区进行处理和存储。`,
	party_content5:`通过向畅意提交个人信息并使用畅意，您明确同意将您的个人数据传输，处理和存储在欧洲经济区或其他司法管辖区，而该司法管辖区可能无法提供与您所在国家/地区相同的隐私保护等级居住或是公民。`,
	content:`服务内容`,
	content_content1:`我们可能会全权酌情决定不时允许您通过我们的服务上载，存储，共享，发送或以其他方式提供内容 - 例如，通过提交评论，创建想法或上载文件附件。`,
	content_who:`谁拥有内容？`,
	who_content1:`您通过我们的服务上载，存储，共享或发送的内容仍然属于您或原始所有者所拥有的知识产权。如果您与您的雇主或签订协议的企业达成协议，并且该雇主/企业是该空间的主要所有者，那么知识产权将分配给您的雇主/您与之有协议的企业。 畅意不会获得您或其他人在使用服务过程中提交给服务的任何内容的所有权。这些条款和条件并未授予我们对您的内容的任何权利，只有以下有关服务内容的部分中所述使我们能够运行和改进我们的服务的有限权利。`,
	who_content2:`对于您对您或其他内容与服务有关的任何行为，我们概不负责。畅意通常不会监视空间来查看其中发布的内容，并且对任何此类内容概不负责。`,
	who_content3:`通过向服务提交内容，您保证（i）您拥有必要的所有权，许可和同意，以授予我们运行和改进我们服务的必要权利（ii）内容本身，您上载，发布，传达或通过“服务”提供该服务绝不会以任何方式侵犯或侵犯第三方的权利，并且不会导致违反任何法律或法规。`,
	who_content4:`您承认，您对准确性，质量，完整性，合法性，可靠性，适当性和知识产权所有权或使用您提交的任何及所有内容的权利负全责。此外，如果此类内容侵犯了第三方权利，您将承担全部责任，并因此同意就与此类侵权和/或违法行为有关的所有索赔和损失赔偿畅意。`,
	who_content5:`您知道，通过向畅意提交内容，您可能正在与其他人共享它。您还知道，空间上有权访问您内容的其他用户可能会选择共享，编辑，提供给第三方或对您提交给畅意的内容采取其他措施。`,
	content_rights:`我们保留一些运行服务的权利`,
	rights_content1:`畅意允许您上载，提交，存储，发送和接收内容。 您保留对该内容拥有的任何知识产权的所有权。 简而言之，您的东西属于您。 但是，为了能够提供内容和运行服务，我们保留了一组标准化权利。`,
	rights_content2:`您授予我们全球范围内的免版税，非专有，永久性的存储，显示，修改，编辑，发送，删除，扫描，分析，跟踪，重新包装和复制您的内容的权利，以提供我们的服务 - 我们无需进一步通知或取得您的同意。 这项权利延伸到我们与之合作的合作伙伴和第三方服务，以确保服务的平稳运行。 例如，这使服务可以在您发布想法后向他人显示您的想法，或裁剪图像。`,
	content_improve:`我们保留改善服务的某些权利`,
	improve_content1:`您同意畅意员工可以使用您在服务上的活动和内容来不断优化服务的性能和外观。我们保留在匿名汇总级别上发布调查结果的权利。匿名发现的一个例子是研究通常有多少人对他们点赞的想法发表评论。`,
	improve_content2:`我们也保留权利，但没有义务，应空间成员的邀请直接访问您的帐户数据或空间，以进行技术维护，内容监督或调查以及一般的客户支持。`,
	improve_content3:`我们可以自由地利用和共享因使用我们的服务而明知或不知提供的任何反馈或环境分析证据，以改进我们的服务或技术，而不会导致您拥有或获得其任何权利或所有权。`,
	content_protecting:`保护您的内容`,
	protecting_content1:`保护您的内容是我们的头等大事。 虽然我们确实会尝试尽量减少对您的内容的访问，但我们保留一般监视各个空间，成员，活动和内容的权利，但没有义务。`,
	protecting_content2:`我们仅会单独调查成员或空间的内容，以确定人们是否遵守这些条款和条件或满足任何法律，法规或有效的授权政府要求。活动被更频繁地监控和分析，可用于改善我们的服务，包括客户支持。`,
	protecting_content3:`畅意及其员工应保留（但没有义务）总体上监视和观察您在我们服务上的活动，以确定是否符合这些条款和条件，并调查内容以确定符合这些条款和条件以及畅意制定的任何操作规则，以满足任何法律，法规或有效的授权政府要求。`,
	customer:`客户责任`,
	customer_content1:`您对自己在服务上拥有的空间所发生的一切负全责。作为客户（可以控制所有者帐户），您可以，例如启用，禁用或以其他方式更改设置，选项，访问和编辑权限，成员的角色和空间的属性，邀请他人，共享访问权限，添加第三方应用等等。空间客户负责与空间有关的行为，包括引起任何想要的或不需要的使用，披露，丢失，修改或删除任何内容，帐户或空间。`,
	customer_content2:`作为客户，您保证您是人类（不是机器人）并且年满18岁。您还保证您有足够的能力订立具有法律约束力的合同，并且您不会通过签署“客户协议”而违反任何法律或法规。`,
	customer_content3:`空间的客户被认为是中介人，有义务对进入空间的行为和帐户内容进行规范。空间客户始终对主动或被动允许进入空间的人员的所有作为和不作为承担全部责任。`,
	subscriptions:`订阅`,
	subscriptions_content1:`我们的某些服务是按订阅计费的（称为“订阅”）。`,
	subscriptions_content2:`您同意根据订购单上的价格和付款政策为订阅付费，或者在某些特殊情况下，由我们的一位员工为您提供订阅提案（ “提案”）。`,
	subscriptions_billing:`计费周期和计费期`,
	billing_content1:`您可以选择按月（“按月付费”）或按年（“按年付费”）付费。该“计费周期”控制着“计费期”的长度，即订阅的支付时间。`,
	billing_content2:`当订阅开始或被修改时，或者当您更改计费周期时，将进入一个计费期。计费期开始的日期称为计费期的“开始日期”。计费期的“结束日期”取决于计费周期。`,
	billing_content3:`当“按月付费”时，计费周期的结束日期是或接近下个月的同一日期。例如，如果您选择“按月付费”，并且您在10月15日开始订阅，则11月15日作为计费周期的结束日期。`,
	billing_content4:`当“按年付费”时，计费周期的结束日期是或接近下一年的同一日期。例如，如果您选择“按年计费”，则从10月15日开始订阅将在下一年的10月15日作为计费周期的结束日期。`,
	billing_content5:`如果“开始日期”接近本月末，您会遇到“结束日期”随着时间的推移会向后移几天以停留在月内的情况。例如，一个按月计费的客户进入一个计费周期，其开始日期为10月31日，则其相应的结束日期为11月30日。结束日期不会在更长的月份后自动再次向前移动。`,
	subscriptions_renewal:`自动续订和定期付款`,
	renewal_content1:`您在此同意自动续订订阅。这意味着您购买的任何订阅都将在其结算期结束时续签，除非您之前取消了该订阅。`,
	renewal_content2:`通过向畅意注册信用卡，您在此同意定期付款。您已授权我们（或指定的第三方提供商）在每个计费期的同一天（或接近该日期）对信用卡进行收费，并自动从您的信用卡扣费，扣费在购买订阅时开始，或者在您更改计费周期时开始。您要支付的费用取决于您对订阅的选择，可以在结帐时，在我们的价格方案页面上看到，或在提案中指定。`,
	renewal_content3:`请注意，订阅的价格可能取决于某些使用参数，例如在空间上收集的创新想法数量。您在此同意，在结算期内影响订阅的任何更改（例如升级，降级，额外或取消的订阅，额外的使用情况，费用）将自动反映在下一个发送给您的账单中。`,
	subscriptions_payment:`付款条款，税收和功能`,
	payment_content1:`订阅费用必须始终提前支付，并且在订阅时应立即付款，如果在发票日期后（七）天仍未付款，则视为未付款。在特殊情况下，畅意可能会要求其他付款方式（例如银行转帐）。订阅费用可能包括最高不超过订阅价格4％的支付卡处理费用。客户要支付的费用取决于他或她选择的订购方式，可以在结帐日期看到。`,
	payment_content2:`畅意订阅为预付费且不可退款。除非法律要求或提案中明确规定，否则畅意通常不会就其部分使用服务，任何剩余的款项，数据或内容提供退款或交换。如果因任何原因在畅意上注册的信用卡因付款无效或发票未付清，则客户将对任何未收取的款项负责。如果未付款，我们保留每月收取未偿金额最高2％的滞纳金的权利。`,
	payment_content3:`所有价格均可能不包含税项（增值税或其他税项），取决于适用法律和您的合法居住地，税种可能会或可能不会增加。您有责任支付任何适用的税款或费用。`,
	payment_content4:`我们可能会不时提供有关我们服务何时以有望提供新功能的预期。购买决定不应依赖于此信息，因为我们无法保证它们。我们保留不时调整价格，费用，功能和使用限制或服务和订阅的其他限制的权利。有效订阅的调整价格应自价格更改之日起您随后的服务计费期开始生效。`,
	subscriptions_trials:`试用`,
	trials_content1:`畅意可能会提供限时访问付费订阅的服务（称为“试用”）。 使用试用版时，您将获得在有限的时间内（“试用期”）访问某个空间上给定订阅的功能的权利，该时限在试用期开始时会披露。`,
	trials_content2:`如果您已经在相关的帐户或空间上注册了付款信息，则如果没有取消订阅，该试用版将在试用期结束时自动变成按月计费的付费订阅。`,
	subscriptions_termination:`终止订阅`,
	termination_content1:`作为客户，如果您希望取消订阅，可以直接与我们联系。您可以在结算期结束前的30天内随时取消按月付费，并在任何时候取消按年付费。在计费期结束之前，可以继续访问订阅的其他功能。在任何时间点，只有空间所有者帐户才能开启，结束或更改其空间订阅。`,
	termination_content2:`如果您在结算期内成功取消或以其他方式终止了订阅，则您不必为下一个即将到来的结算期付款。例如，如果您是从每月的14日到14日使用月度结算付款，并在一个月的13日终止订阅，则在下一个结算期，该月的14日将不会向您收费。`,
	termination_content3:`如果出于任何原因未付款或违反本客户协议，畅意有权，不承担责任，立即禁止访问服务和/或服务的一部分和/或禁止访问付费订阅，和/或终止您控制的空间。实际上，畅意将采取合理的措施在采取此类步骤之前通知您付款失败，无效或延迟。`,
	suspension:`变更或暂停服务`,
	suspension_content1:`畅意保留根据其合理酌情决定权，在以下情况下暂停或终止对帐户，客户，应用和/或空间的访问（i）在计划内停机期间以升级和维护服务（称为“计划内停机”）（ii）在由于畅意在合理控制范围内的情况，例如但不限于不可抗力事件，政府行为，恐怖袭击或内乱，畅意控制范围内的技术故障（包括但不限于无法访问互联网），或第三方采取的行动，包括但不限于分布式拒绝服务攻击（iii）如果帐户不符合这些条款中规定的规定（iv ），如果客户出于任何原因欠畅意款项，或者（v）如果我们怀疑或检测到与您的帐户或您所属的空间相关的任何恶意软件。`,
	suspension_content2:`畅意有权单方面决定随时终止服务并删除所有内容。在这种情况下，畅意将在我们认为在技术和法律上可行的范围内，提前30天通知您，并在通知期间提供您的内容副本。`,
	warranty:`免责声明`,
	warranty_content1:`我们竭尽全力以高标准构建和运营服务，但我们不能保证一切。`,
	warranty_content2:`因此，在法律允许的最大范围内，我们的服务“按原样”提供，没有任何明示或暗示的陈述或保证。 换句话说，畅意始终处于beta状态。 畅意不对我们的服务，其对特定目的的适用性或通过我们的服务提供的信息和材料做出任何陈述或保证。`,
	warranty_content3:`您同意本服务可以进行更新，修改，更改，中断或终止，恕不另行通知或承担责任。 我们保留随时限制或终止您对服务或部分服务的访问的权利。 对于删除，更正，销毁，损坏，丢失或无法存储提交给我们服务的任何内容或个性化设置，我们概不负责。`,
	liability:`责任限制`,
	liability_content1:`您理解并同意畅意或任何参与创建，生产或分发服务的任何实体在任何情况下均不对任何不使用或无法使用或访问服务或服务上的内容带来的损害负责，包括但不限于利润损失，直接，间接，偶然，特殊，结果性或惩罚性损害。`,
	liability_content2:`畅意对他人或第三方的诽谤，冒犯或非法行为不承担任何责任，并且由这些原因引起的伤害风险完全由您承担。`,
	liability_content3:`畅意对于因您一方无法保护您的帐户访问权而导致的未经授权访问您的帐户而造成的任何损失，损坏，费用或不当行为概不负责。`,
	liability_content4:`畅意或其任何关联公司，信息提供商或其他第三方提供商或合作伙伴均不对服务或以下服务中包含的信息的任何错误，不准确性，遗漏或其他缺陷或不及时性负责，无论原因或持续时间。服务上的内容。我们服务的任何部分均不构成或旨在构成任何形式的建议。如果您需要有关任何法律或财务事项的建议，则应咨询适当的专业人员。`,
	liability_content5:`畅意可以与任何要求或指示畅意的执法部门充分合作，以披露任何帐户或客户的机密或私人信息或内容。您理解并同意畅意或任何参与创建，生产或分发服务的任何实体在任何情况下均不对出于与执法部门的合规或合作带来的损害负责，包括但不限于利润损失，直接，间接，偶然，特殊，结果性或惩罚性损害。`,
	liability_content6:`对畅意造成重大后果的任何违反社区准则的行为，将使您对畅意和可能对他人造成伤害的其他人造成的损失负责。`,
	liability_content7:`如果发现畅意对与您进行的这些条款和条件引起的任何事件或情况承担责任，我们的责任不得超过我们在过去十二（12）个月内向您收取的金额。`,
	indemnification:`保障`,
	indemnification_content1:`您同意就因使用畅意而引起的所有索赔和费用（包括律师费）抗辩，赔偿并使畅意，其分支机构及其各自的董事，管理人员，雇员和代理商免受伤害。 如果您提交的内容侵犯了第三方权利，您将承担全部责任，因此，您同意就与这种侵权和/或违法行为有关的所有索赔和损失赔偿畅意。 您承认本节的规定应适用于服务上的所有内容。`,
	general:`通用规则`,
	general_copyright:`版权和所有权`,
	copyright_content1:`畅意及其外观和商标归畅意所有，我们保留所有权利。我们服务上出现的所有其他商标均为其各自所有者的财产。`,
	copyright_content2:`您承认畅意及其许可人保留我们所有机密信息和/或其他专有信息中的所有知识产权和所有权。这包括但不限于：产品，服务，概念，技术，发明，过程，软件或畅意提供的部分服务编写。`,
	copyright_content3:`您了解，尽管我们可能使用诸如“出售”，“拥有”和“无限”之类的术语，但我们对我们的服务和技术的所有权仍归我们所有。不转让畅意技术和知识产权的权利。您了解该服务是在线托管的，您无权访问，复制和/或托管我们技术的源代码。您有责任通过金钱或其他方式获取和维护访问和使用畅意所需的所有电话，计算机硬件，软件，互联网服务计划和其他设备以及与之相关的所有费用。`,
	general_relationship:`双方关系`,
	relationship_content1:`这些条款与条件不会在您和畅意之间建立合伙关系，专营权，合资企业，代理以及受托或雇佣关系。`,
	general_survival:`条款的保留`,
	survival_content1:`您同意，这些条款与条件（根据其性质）应在终止后仍然有效。 任何一方的任何终止或本条款与条件的期限届满仅对未来有效，而无追溯效力。`,
	general_severability:`可分割性`,
	severability_content1:`如果这些条款和条件中的任何规定被具有管辖权的法院裁定为不可执行，则该规定应由法院进行修改和解释，以在适用法律允许的最大范围内最佳地完成原始规定。 这些条款和条件的其余规定应保持全部效力。`,
	severability_content2:`在不违反上述一般说明的前提下，我们想明确指出，您的管辖范围内的某些消费者保护法或其他法律可能不允许在这些条款和条件中对责任，保修限制和/或计费政策进行某些限制。 如果适用法律明确禁止任何责任限制，保修限制和/或计费政策，则此类责任限制，保修限制和/或计费政策可能不适用于您。 在这种情况下，如果您想利用某些与这些条款和条件相抵触的政府法规或规定，我们恳请您提前通知我们。`,
	general_waiver:`无豁免`,
	waiver_content1:`即使畅意没有明确行使这些条款和条件下的特定权利，并不代表我们会放弃它们。`,
	general_entire:`整个协议`,
	entire_content1:`这些条款和条件（包括其中包含的所有参考文献）是您与我们之间关于我们服务的全部协议。`,
	entire_content2:`我们可能选择通过我们的服务进行竞赛，促销或市场营销活动，这些活动可能受另一套条款和条件的约束。 如果您选择参加任何此类竞赛，促销或市场营销活动，请查看适用于它们的条件，因为它们可能会发生冲突，并覆盖这些条款和条件。 同样，通过接受指定或扩展此协议的销售报价，可能会适用其他条件和政策。`,
	general_law:`适用法律`,
	law_content1:`这些条款与条件应受英格兰和威尔士的实体法管辖，而不考虑法律冲突原则。 您在此明确同意接受英国的专属管辖权，以解决与这些条款和条件或访问或使用服务有关的任何争议。`,
	general_dispute:`纠纷解决`,
	dispute_content1:`如果您正在考虑提出争议或索赔，我们建议您在通过正式渠道索赔前21提案先通过非正式渠道与我们沟通。为此，请发送电子邮件至info@idexltd.com与我们联系，主题行为“争议通知”。`,
	dispute_content2:`我们将共同努力，在收到您的电子邮件后21天内确定解决方案 - 如果我们未能达成协议，您或畅意可自由启动正式程序。`,
	dispute_content3:`因条款和条件引起或与之相关的任何争议，包括与存在，有效性或终止有关的任何争议，均应根据所采用的仲裁程序规则，由特许仲裁员协会（CIArb）进行仲裁，以仲裁方式解决。由特许仲裁员协会（CIArb）提出，并在此类程序开始时生效。`,
	dispute_content4:`您只能通过个人方式解决争议和仲裁，而不能通过集体，联合或集体诉讼来解决。仲裁庭应由一名仲裁员组成。仲裁地点应为英国伦敦。仲裁程序中使用的语言应为英语。`,
	general_notice:`通知`,
	notice_content1:`条款和条件下的所有通知将通过邮件发送到您的帐户信息中记录的电子邮件地址。 对畅意的通知应通过电子邮件发送至info@idexltd.com。 任何此类通知均应视为在邮寄或发布后72小时（如果以邮件或预付费邮寄方式发送）或发送后24小时（如果以电子邮件发送）时已发出。`,
	general_majeure:`不可抗力`,
	majeure_content1:`由于不可抗力因素导致的任何失败或延误，任何一方均不承担任何责任，包括但不限于不可抗力事件，战争，骚乱，禁运，文职或军事当局的行为，火灾，洪水，事故，服务设备和/或软件故障和/或电信故障，电源故障，网络故障，第三方服务提供商（包括互联网服务提供商）的故障所导致的中断。 只要任何此类事件将阻止受影响的方履行这些条款和条件下的要求义务，则这些条款和条件的执行应被暂停。`,
	general_question:`问题`,
	question_content1:`如有任何问题请通过info@idexltd.com与我们联系。`,
	},
HK:{
	title:`條款和條件`,
	introduction:`簡介`,
	introduction_content1:`這是一份具有法律約束力的條款和條件（“協議”），適用於您（“您的”）對我們網站，移動應用程序，產品和服務（統稱“服務”）的訪問或使用，以及通過我們的服務上載，下載，製作，查看或以其他方式訪問的所有數據，文件，內容或材料（統稱為“內容”）。`,
	introduction_content2:`該服務由深圳暢意創新發展有限公司提供，其辦事處位於中國深圳，公司業務為暢意創新與項目管理系統—簡稱為“暢意科技”，“暢意系統”，“暢意”，” 該服務由深圳暢意創新發展有限公司提供，其辦事處位於中國深圳，公司業務為暢意創新與項目管理系統— 簡稱為“暢意科技”，“暢意系統”，“暢意”，“ IDEX”或“我們”。您和暢意共同為這些條款和條件的“當事人”。通過訪問任一我們的網站或以其他方式使用我們的服務的一部分，您將被視為我們的服務的“用戶”。`,
	introduction_content3:`使用暢意，即表示您同意這些條款和條件。如果您不同意或遵守這些條款和條件，您可以不接受它們，也不能開始或繼續使用暢意。`,
	introduction_content4:`我們保留隨時調整和更改這些條款和條件以及任何文中引用的文件的權利。如果有重大變化，我們將提前通知您。在生效日期之後繼續使用暢意，即表示您同意新版本。`,
	access:`訪問服務`, 
	access_account:`帳戶`, 
	account_content1:`暢意將為您提供創建和維護用於訪問服務的用戶名，電郵和密碼（暢意“帳戶”）的功能。通過訪問暢意帳戶，您同意在註冊過程中提供真實，準確和完整的信息，並在使用過程中更新您的信息以使其保持信息。`, 
	account_content2:`通過帳戶訪問暢意，您保證（i）您已年滿13歲，（ii）如果您的年齡在您所在司法管轄區已成年，則表明您已閱讀，理解並接受其約束（iii）如果您的年齡在您所在司法管轄區的13歲至成年年齡之間，則表明您的法定監護人已審查並同意這些條款。`, 
	account_content3:`由於您的電郵和密碼是個人信息，被視為機密信息，因此您始終對您允許通過您的帳戶訪問平台的人員的所有作為和不作為承擔全部責任。您有責任保護您的暢意帳戶安全，防止未經授權的訪問，並且必須將您帳戶的任何未經授權的使用或安全漏洞立即通知我們。`, 
	access_join:`加入空間`, 
	join_content1:`該服務可能允許您訪問網址（或URL，例如，company.idexinnovation.com）和名稱（例如，Company Inc.）標識的註冊子域（“空間”）。空間也可以託管在您公司的子域中。例如，如果您的公司使用acme.com域名，則空間網址可能是idea.acme.com或idex.acme.com。空間允許具有共同興趣或從屬關係的參與者組共享內容並進行協作。`, 
	join_content2:`您可以通過登入或創建帳戶（在這種情況下您被稱為“成員”）或不登入或創建服務帳戶（在這種情況下您稱為“訪客”）訪問暢意空間。`, 
	join_content3:`任何空間都可能包含不適合您的內容。如果您發現空間內容不合適，您可以隨時選擇停止訪問該空間。`, 
	access_ownership: `空間所有權`,
	ownership_content1: `暢意可能為您提供創建和/或維護空間的能力。每個這樣的空間都有一個特定的成員，該成員具有更多的訪問和編輯權以及總體責任，在條款和條件中稱為“主要所有者帳戶”，“主要所有者”或“所有者” 。註冊空間時，您將成為該空間的成員，並被分配為主要所有者帳戶。在任何時間點，每個空間上都有一個主要所有者帳戶。`,
	ownership_content2:`同時，每個空間都有一個人或實體負責該空間上的活動，即“客戶”。您承認並同意，成為空間成員可以為您分配或預先分配所有者帳戶的角色，並可以使其成為客戶的責任。`,
	ownership_content3:`如果某個空間是通過帶有個人電郵的帳戶註冊的和/或出於與企業，組織，品牌或其他實體無關的個人原因而註冊的，則該空間的所有者帳戶應對該活動進行個人負責空間。在這種情況下，該空間上的所有者帳戶被認為是客戶，也被稱為“個人客戶”。`,
	ownership_content4:`如果空間是通過帳戶向企業電郵和/或公司，企業，組織，品牌或類似機構註冊的，則空間所有者賬戶代表實體同意這些條款和條件並充當實體的代表。在這種情況下，該實體被視為客戶，也稱為“商業客戶”。`,
	access_inviting:`邀請成員`,
	inviting_content1:`使用我們的服務時，您可能會共享邀請鏈接，通過電郵或其他方式邀請他人，從而使更多人可以使用該空間。通過共享這些邀請鏈接或邀請成員，或以其他方式使成員可以加入空間，即表示您接受對他們的行為和他們發布的內容負責。您同意您有責任讓允許您進入空間的成員了解他們的權利和這些條款和條件，並確保他們使用服務的合法性。`,
	access_party:`第三方服務`,
	party_content1:`暢意保留使用第三方提供的技術知識，人員或技術，並根據這些條款和條件交付部分服務的權利。這可能包括但不限於例如第三方支付處理系統，電子郵件基礎架構提供商或用戶身份驗證。請注意，除這些條款和條件之外，第三方服務可能還有自己的條款和政策，通過條款，條件和隱私政策來管理您的行為。與第三方提供商合作時，我們僅使用具有良好實踐的成熟且行業領先的服務提供商。我們確保我們已簽訂書面協議，將我們服務中使用的所有第三方服務約束在一起，以遵守數據保護義務，其保護性不低於我們協議中的義務。`,
	party_content2:`我们的服务可能包含指向第三方维护的网站和内容的連結。 畅意对与第三方网站或服务的内容的任何连接或依赖不承担任何责任。我们与第三方网站或内容的連結并不意味着我们支持，促进，保证或推荐任何此类内容或网站，其观点，其作者或所有者。`,
	party_content3:`在使用我们的服务时，您可能将内容，帐户或空间与第三方服务联系起来。如果您选择将服务上的内容，帐户或空间与第三方服务（例如通过API，Webhook，身份验证，单点登录或类似的集成）連結，则您应对这些第三方服务对该内容所做的一切负责，包括想要或不想要的使用，披露，丢失，修改或删除任何或所有此类内容，帐户或空间。请注意，第三方服务可能有自己的条款和政策来约束您的权利和行为。`,
	party_content4:`如果您是位於歐洲經濟區以外司法管轄區的個人，且其法律和法規管理著與歐洲經濟區法律不同的個人數據收集，使用和披露，請注意，我們收集的信息（包括通過使用諸如Cookie和其他網絡技術）可能會在歐洲經濟區或我們或我們的第三方服務提供商運營所在的其他國家/地區進行處理和存儲。/地區進行處理和存儲。`,
	party_content5:`通過向暢意提交個人信息並使用暢意，您明確同意將您的個人數據傳輸，處理和存儲在歐洲經濟區或其他司法管轄區，而該司法管轄區可能無法提供與您所在國家/地區相同的隱私保護等級居住或是公民。`,
	content:`服務內容`,
	content_content1:`我們可能會全權酌情決定不時允許您通過我們的服務上載，存儲，共享，發送或以其他方式提供內容 - 例如，通過提交評論，創建想法或上載文件附加檔案。`,
	content_who:`誰擁有內容？`,
	who_content1:`您通過我們的服務上載，存儲，共享或發送的內容仍然屬於您或原始所有者所擁有的知識產權。如果您與您的雇主或簽訂協議的企業達成協議，並且該雇主/企業是該空間的主要所有者，那麼知識產權將分配給您的雇主/您與之有協議的企業。暢意不會獲得您或其他人在使用服務過程中提交給服務的任何內容的所有權。這些條款和條件並未授予我們對您的內容的任何權利，只有以下有關服務內容的部分中所述使我們能夠運行和改進我們的服務的有限權利。`,
	who_content2:`對於您對您或其他內容與服務有關的任何行為，我們概不負責。暢意通常不會監視空間來查看其中發布的內容，並且對任何此類內容概不負責。`,
	who_content3:`通過向服務提交內容，您保證（i）您擁有必要的所有權，許可和同意，以授予我們運行和改進我們服務的必要權利（ii）內容本身，您上載，發布，傳達或通過“服務”提供該服務絕不會以任何方式侵犯或侵犯第三方的權利，並且不會導致違反任何法律或法規。`,
	who_content4:`您承認，您對準確性，質量，完整性，合法性，可靠性，適當性和知識產權所有權或使用您提交的任何及所有內容的權利負全責。此外，如果此類內容侵犯了第三方權利，您將承擔全部責任，並因此同意就與此類侵權和/或違法行為有關的所有索賠和損失賠償暢意。`,
	who_content5:`您知道，通過向暢意提交內容，您可能正在與其他人共享它。您還知道，空間上有權訪問您內容的其他用戶可能會選擇共享，編輯，提供給第三方或對您提交給暢意的內容採取其他措施。`,
	content_rights:`我們保留一些運行服務的權利`,
	rights_content1:`暢意允許您上載，提交，存儲，發送和接收內容。您保留對該內容擁有的任何知識產權的所有權。簡而言之，您的東西屬於您。但是，為了能夠提供內容和運行服務，我們保留了一組標準化權利。`,
	rights_content2:`您授予我們全球範圍內的免版稅，非專有，永久性的存儲，顯示，修改，編輯，發送，刪除，掃描，分析，跟踪，重新包裝和復制您的內容的權利，以提供我們的服務 - 我們無需進一步通知或取得您的同意。這項權利延伸到我們與之合作的合作夥伴和第三方服務，以確保服務的平穩運行。例如，這使服務可以在您發布想法後向他人顯示您的想法，或裁剪圖像。`,
	content_improve:`我們保留改善服務的某些權利`,
	improve_content1:`您同意暢意員工可以使用您在服務上的活動和內容來不斷優化服務的性能和外觀。我們保留在匿名匯總級別上發布調查結果的權利。匿名發現的一個例子是研究通常有多少人對他們點讚的想法發表評論。`,
	improve_content2:`我們也保留權利，但沒有義務，應空間成員的邀請直接訪問您的帳戶數據或空間，以進行技術維護，內容監督或調查以及一般的客戶支持。`,
	improve_content3:`我們可以自由地利用和共享因使用我們的服務而明知或不知提供的任何反饋或環境分析證據，以改進我們的服務或技術，而不會導致您擁有或獲得其任何權利或所有權。`,
	content_protecting:`保護您的內容`,
	protecting_content1:`保護您的內容是我們的頭等大事。雖然我們確實會嘗試盡量減少對您的內容的訪問，但我們保留一般監視各個空間，成員，活動和內容的權利，但沒有義務。`,
	protecting_content2:`我們僅會單獨調查成員或空間的內容，以確定人們是否遵守這些條款和條件或滿足任何法律，法規或有效的授權政府要求。活動被更頻繁地監控和分析，可用於改善我們的服務，包括客戶支持。`,
	protecting_content3:`暢意及其員工應保留（但沒有義務）總體上監視和觀察您在我們服務上的活動，以確定是否符合這些條款和條件，並調查內容以確定符合這些條款和條件以及暢意制定的任何操作規則，以滿足任何法律，法規或有效的授權政府要求。`,
	customer:`客戶責任`,
	customer_content1:`您對自己在服務上擁有的空間所發生的一切負全責。作為客戶（可以控制所有者帳戶），您可以，例如啟用，禁用或以其他方式更改設置，選項，訪問和編輯權限，成員的角色和空間的屬性，邀請他人，共享訪問權限，添加第三方應用等等。空間客戶負責與空間有關的行為，包括引起任何想要的或不需要的使用，披露，丟失，修改或刪除任何內容，帳戶或空間。`,
	customer_content2:`作為客戶，您保證您是人類（不是機器人）並且年滿18歲。您還保證您有足夠的能力訂立具有法律約束力的合同，並且您不會通過簽署“客戶協議”而違反任何法律或法規。`,
	customer_content3:`空間的客戶被認為是中介人，有義務對進入空間的行為和帳戶內容進行規範。空間客戶始終對主動或被動允許進入空間的人員的所有作為和不作為承擔全部責任。`,
	subscriptions:`訂閱`,
	subscriptions_content1:`我們的某些服務是按訂閱計費的（稱為“訂閱”）。`,
	subscriptions_content2:`您同意根據訂購單上的價格和付款政策為訂閱付費，或者在某些特殊情況下，由我們的一位員工為您提供訂閱提案（ “提案”）。`,
	subscriptions_billing:`计费周期和计费期`,
	billing_content1:`您可以選擇按月（“按月付費”）或按年（“按年付費”）付費。該“計費周期”控制著“計費期”的長度，即訂閱的支付時間。`,
	billing_content2:`當訂閱開始或被修改時，或者當您更改計費周期時，將進入一個計費期。計費期開始的日期稱為計費期的“開始日期”。計費期的“結束日期”取決於計費周期。`,
	billing_content3:`當“按月付費”時，計費周期的結束日期是或接近下個月的同一日期。例如，如果您選擇“按月付費”，並且您在10月15日開始訂閱，則11月15日作為計費周期的結束日期。`,
	billing_content4:`當“按年付費”時，計費周期的結束日期是或接近下一年的同一日期。例如，如果您選擇“按年計費”，則從10月15日開始訂閱將在下一年的10月15日作為計費周期的結束日期。`,
	billing_content5:`如果“開始日期”接近本月末，您會遇到“結束日期”隨著時間的推移會向後移幾天以停留在月內的情況。例如，一個按月計費的客戶進入一個計費周期，其開始日期為10月31日，則其相應的結束日期為11月30日。結束日期不會在更長的月份後自動再次向前移動。`,
	subscriptions_renewal:`自動續訂和定期付款`,
	renewal_content1:`您在此同意自動續訂訂閱。這意味著您購買的任何訂閱都將在其結算期結束時續簽，除非您之前取消了該訂閱。`,
	renewal_content2:`通過向暢意註冊信用卡，您在此同意定期付款。您已授權我們（或指定的第三方提供商）在每個計費期的同一天（或接近該日期）對信用卡進行收費，並自動從您的信用卡扣費，扣費在購買訂閱時開始，或者在您更改計費周期時開始。您要支付的費用取決於您對訂閱的選擇，可以在結帳時，在我們的價格方案頁面上看到，或在提案中指定。`,
	renewal_content3:`請注意，訂閱的價格可能取決於某些使用參數，例如在空間上收集的創新想法數量。您在此同意，在結算期內影響訂閱的任何更改（例如昇級，降級，額外或取消的訂閱，額外的使用情況，費用）將自動反映在下一個發送給您的賬單中。`,
	subscriptions_payment:`付款條款，稅收和功能`,
	payment_content1:`訂閱費用必須始終提前支付，並且在訂閱時應立即付款，如果在發票日期後（七）天仍未付款，則視為未付款。在特殊情況下，暢意可能會要求其他付款方式（例如銀行轉帳）。訂閱費用可能包括最高不超過訂閱價格4％的支付卡處理費用。客戶要支付的費用取決於他或她選擇的訂購方式，可以在結帳日期看到。`,
	payment_content2:`暢意訂閱為預付費且不可退款。除非法律要求或提案中明確規定，否則暢意通常不會就其部分使用服務，任何剩餘的款項，數據或內容提供退款或交換。如果因任何原因在暢意上註冊的信用卡因付款無效或發票未付清，則客戶將對任何未收取的款項負責。如果未付款，我們保留每月收取未償金額最高2％的滯納金的權利。`,
	payment_content3:`所有價格均可能不包含稅項（增值稅或其他稅項），取決於適用法律和您的合法居住地，稅種可能會或可能不會增加。您有責任支付任何適用的稅款或費用。`,
	payment_content4:`我們可能會不時提供有關我們服務何時以有望提供新功能的預期。購買決定不應依賴於此信息，因為我們無法保證它們。我們保留不時調整價格，費用，功能和使用限製或服務和訂閱的其他限制的權利。有效訂閱的調整價格應自價格更改之日起您隨後的服務計費期開始生效。`,
	subscriptions_trials:`試用`,
	trials_content1:`暢意可能會提供限時訪問付費訂閱的服務（稱為“試用”）。使用試用版時，您將獲得在有限的時間內（“試用期”）訪問某個空間上給定訂閱的功能的權利，該時限在試用期開始時會披露。`,
	trials_content2:`如果您已經在相關的帳戶或空間上註冊了付款信息，則如果沒有取消訂閱，該試用版將在試用期結束時自動變成按月計費的付費訂閱。`,
	subscriptions_termination:`終止訂閱`,
	termination_content1:`作為客戶，如果您希望取消訂閱，可以直接與我們聯繫。您可以在結算期結束前的30天內隨時取消按月付費，並在任何時候取消按年付費。在計費期結束之前，可以繼續訪問訂閱的其他功能。在任何時間點，只有空間所有者帳戶才能開啟，結束或更改其空間訂閱。`,
	termination_content2:`如果您在結算期內成功取消或以其他方式終止了訂閱，則您不必為下一個即將到來的結算期付款。例如，如果您是從每月的14日到14日使用月度結算付款，並在一個月的13日終止訂閱，則在下一個結算期，該月的14日將不會向您收費。`,
	termination_content3:`如果出於任何原因未付款或違反本客戶協議，暢意有權，不承擔責任，立即禁止訪問服務和/或服務的一部分和/或禁止訪問付費訂閱，和/或終止您控制的空間。實際上，暢意將採取合理的措施在採取此類步驟之前通知您付款失敗，無效或延遲。`,
	suspension:`變更或暫停服務`,
	suspension_content1:`暢意保留根據其合理酌情決定權，在以下情況下暫停或終止對帳戶，客戶，應用和/或空間的訪問（i）在計劃內停機期間以升級和維護服務（稱為“計劃內停機” ）（ii）在由於暢意在合理控制範圍內的情況，例如但不限於不可抗力事件，政府行為，恐怖襲擊或內亂，暢意控制範圍內的技術故障（包括但不限於無法訪問互聯網），或第三方採取的行動，包括但不限於分佈式拒絕服務攻擊（iii）如果帳戶不符合這些條款中規定的規定（iv ），如果客戶出於任何原因欠暢意款項，或者（v）如果我們懷疑或檢測到與您的帳戶或您所屬的空間相關的任何惡意軟件。`,
	suspension_content2:`暢意有權單方面決定隨時終止服務並刪除所有內容。在這種情況下，暢意將在我們認為在技術和法律上可行的範圍內，提前30天通知您，並在通知期間提供您的內容副本。`,
	warranty:`免責聲明`,
	warranty_content1:`我們竭盡全力以高標準構建和運營服務，但我們不能保證一切。`,
	warranty_content2:`因此，在法律允許的最大範圍內，我們的服務“按原樣”提供，沒有任何明示或暗示的陳述或保證。換句話說，暢意始終處於beta狀態。暢意不對我們的服務，其對特定目的的適用性或通過我們的服務提供的信息和材料做出任何陳述或保證。`,
	warranty_content3:`您同意本服務可以進行更新，修改，更改，中斷或終止，恕不另行通知或承擔責任。我們保留隨時限製或終止您對服務或部分服務的訪問的權利。對於刪除，更正，銷毀，損壞，丟失或無法存儲提交給我們服務的任何內容或個性化設置，我們概不負責。`,
	liability:`責任限制`,
	liability_content1:`您理解並同意暢意或任何參與創建，生產或分發服務的任何實體在任何情況下均不對任何不使用或無法使用或訪問服務或服務上的內容帶來的損害負責，包括但不限於利潤損失，直接，間接，偶然，特殊，結果性或懲罰性損害。`,
	liability_content2:`暢意對他人或第三方的誹謗，冒犯或非法行為不承擔任何責任，並且由這些原因引起的傷害風險完全由您承擔。`,
	liability_content3:`暢意對於因您一方無法保護您的帳戶訪問權而導致的未經授權訪問您的帳戶而造成的任何損失，損壞，費用或不當行為概不負責。`,
	liability_content4:`暢意或其任何關聯公司，信息提供商或其他第三方提供商或合作夥伴均不對服務或以下服務中包含的信息的任何錯誤，不准確性，遺漏或其他缺陷或不及時性負責，無論原因或持續時間。服務上的內容。我們服務的任何部分均不構成或旨在構成任何形式的建議。如果您需要有關任何法律或財務事項的建議，則應諮詢適當的專業人員。`,
	liability_content5:`暢意可以與任何要求或指示暢意的執法部門充分合作，以披露任何帳戶或客戶的機密或私人信息或內容。您理解並同意暢意或任何參與創建，生產或分發服務的任何實體在任何情況下均不對出於與執法部門的合規或合作帶來的損害負責，包括但不限於利潤損失，直接，間接，偶然，特殊，結果性或懲罰性損害。`,
	liability_content6:`對暢意造成重大後果的任何違反社區準則的行為，將使您對暢意和可能對他人造成傷害的其他人造成的損失負責。`,
	liability_content7:`如果發現暢意對與您進行的這些條款和條件引起的任何事件或情況承擔責任，我們的責任不得超過我們在過去十二（12）個月內向您收取的金額。`,
	indemnification:`保障`,
	indemnification_content1:`您同意就因使用暢意而引起的所有索賠和費用（包括律師費）抗辯，賠償並使暢意，其分支機構及其各自的董事，管理人員，僱員和代理商免受傷害。如果您提交的內容侵犯了第三方權利，您將承擔全部責任，因此，您同意就與這種侵權和/或違法行為有關的所有索賠和損失賠償暢意。您承認本節的規定應適用於服務上的所有內容。`,
	general:`通用規則`,
	general_copyright:`版權和所有權`,
	copyright_content1:`暢意及其外觀和商標歸暢意所有，我們保留所有權利。我們服務上出現的所有其他商標均為其各自所有者的財產。`,
	copyright_content2:`您承认畅意及其许可人保留我们所有机密信息和/或其他專有信息中的所有知識產權和所有權。這包括但不限於：產品，服務，概念，技術，發明，過程，軟件或暢意提供的部分服務編寫。`,
	copyright_content3:`您了解，儘管我們可能使用諸如“出售”，“擁有”和“無限”之類的術語，但我們對我們的服務和技術的所有權仍歸我們所有。不轉讓暢意技術和知識產權的權利。您了解該服務是在線託管的，您無權訪問，複製和/或託管我們技術的源代碼。您有責任通過金錢或其他方式獲取和維護訪問和使用暢意所需的所有電話，計算機硬件，軟件，互聯網服務計劃和其他設備以及與之相關的所有費用。`,
	general_relationship:`雙方關係`,
	relationship_content1:`這些條款與條件不會在您和暢意之間建立合夥關係，專營權，合資企業，代理以及受託或僱傭關係。`,
	general_survival:`條款的保留`,
	survival_content1:`您同意，這些條款與條件（根據其性質）應在終止後仍然有效。任何一方的任何終止或本條款與條件的期限屆滿僅對未來有效，而無追溯效力。`,
	general_severability:`可分割性`,
	severability_content1:`如果這些條款和條件中的任何規定被具有管轄權的法院裁定為不可執行，則該規定應由法院進行修改和解釋，以在適用法律允許的最大範圍內最佳地完成原始規定。這些條款和條件的其餘規定應保持全部效力。`,
	severability_content2:`在不違反上述一般說明的前提下，我們想明確指出，您的管轄範圍內的某些消費者保護法或其他法律可能不允許在這些條款和條件中對責任，保修限制和/或計費政策進行某些限制。如果適用法律明確禁止任何責任限制，保修限制和/或計費政策，則此類責任限制，保修限制和/或計費政策可能不適用於您。在這種情況下，如果您想利用某些與這些條款和條件相抵觸的政府法規或規定，我們懇請您提前通知我們。`,
	general_waiver:`無豁免`,
	waiver_content1:`即使暢意沒有明確行使這些條款和條件下的特定權利，並不代表我們會放棄它們。`,
	general_entire:`整個協議`,
	entire_content1:`這些條款和條件（包括其中包含的所有參考文獻）是您與我們之間關於我們服務的全部協議。`,
	entire_content2:`我們可能選擇通過我們的服務進行競賽，促銷或市場營銷活動，這些活動可能受另一套條款和條件的約束。如果您選擇參加任何此類競賽，促銷或市場營銷活動，請查看適用於它們的條件，因為它們可能會發生衝突，並覆蓋這些條款和條件。同樣，通過接受指定或擴展此協議的銷售報價，可能會適用其他條件和政策。`,
	general_law:`適用法律`,
	law_content1:`這些條款與條件應受英格蘭和威爾士的實體法管轄，而不考慮法律衝突原則。您在此明確同意接受英國的專屬管轄權，以解決與這些條款和條件或訪問或使用服務有關的任何爭議。`,
	general_dispute:`糾紛解決`,
	dispute_content1:`如果您正在考慮提出爭議或索賠，我們建議您在通過正式渠道索賠前21提案先通過非正式渠道與我們溝通。為此，請發送電子郵件至info@idexltd.com與我們聯繫，主題行為“爭議通知”。`,
	dispute_content2:`我們將共同努力，在收到您的電子郵件後21天內確定解決方案 - 如果我們未能達成協議，您或暢意可自由啟動正式程序。`,
	dispute_content3:`因條款和條件引起或與之相關的任何爭議，包括與存在，有效性或終止有關的任何爭議，均應根據所採用的仲裁程序規則，由特許仲裁員協會（CIArb）進行仲裁，以仲裁方式解決。由特許仲裁員協會（CIArb）提出，並在此類程序開始時生效。`,
	dispute_content4:`您只能通過個人方式解決爭議和仲裁，而不能通過集體，聯合或集體訴訟來解決。仲裁庭應由一名仲裁員組成。仲裁地點應為英國倫敦。仲裁程序中使用的語言應為英語。`,
	general_notice:`通知`,
	notice_content1:`條款和條件下的所有通知將通過郵件發送到您的帳戶信息中記錄的電子郵件地址。對暢意的通知應通過電子郵件發送至info@idexltd.com。任何此類通知均應視為在郵寄或發布後72小時（如果以郵件或預付費郵寄方式發送）或發送後24小時（如果以電子郵件發送）時已發出。`,
	general_majeure:`不可抗力`,
	majeure_content1:`由於不可抗力因素導致的任何失敗或延誤，任何一方均不承擔任何責任，包括但不限於不可抗力事件，戰爭，騷亂，禁運，文職或軍事當局的行為，火災，洪水，事故，服務設備和/或軟件故障和/或電信故障，電源故障，網絡故障，第三方服務提供商（包括互聯網服務提供商）的故障所導致的中斷。只要任何此類事件將阻止受影響的方履行這些條款和條件下的要求義務，則這些條款和條件的執行應被暫停。`,
	general_question:`問題`,
	question_content1:`如有任何問題請通過info@idexltd.com與我們聯繫。`,
	}
}