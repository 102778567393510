import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import gb from './lang/gb.svg';
import cn from './lang/cn.svg';
import hk from './lang/hk.svg';
import es from './lang/es.svg';

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => (
    <div
      ref={ref}
      style={style}
      className={className}
      aria-labelledby={labeledBy}
    >
      <ul className="list-unstyled m-0">{children}</ul>
    </div>
  )
);

CustomMenu.propTypes = {
  'aria-labelledby': PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  style: PropTypes.object,
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    role="button"
    tabIndex="0"
    ref={ref}
    className="cursor-pointer outline-none country-label"
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}&nbsp;
    <i className="fas fa-sort-down" />
  </div>
));

CustomToggle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onClick: PropTypes.func,
};

function FlagDropDown({ lang, changeLang }) {
  const languages = {
    GB: { label: "English", flag: gb },
    ES: { label: "Español", flag: es },
    CN: { label: "简体中文", flag: cn },
    HK: { label: "繁體中文", flag: hk }
  };

  return (
    <Dropdown className="flag-select">
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        {languages[lang] && <img key={lang} src={languages[lang].flag} alt="flag" />} &nbsp;
        {languages[lang] && <span className="country-label">{languages[lang].label} </span>}
      </Dropdown.Toggle>
      <Dropdown.Menu as={CustomMenu}>
        {Object.keys(languages).map(d => (
          <Dropdown.Item
            key={d}
            eventKey={d}
            active={d === lang}
            onClick={() => changeLang(d)}
          >
            <img src={languages[d].flag} alt="flag" key={d} /> &nbsp;
              <span className="country-label">{languages[d].label}</span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

FlagDropDown.propTypes = {
  changeLang: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};

export default FlagDropDown;
