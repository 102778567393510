import React from "react";

import Header from "./components/Header/Header";
import history from "./history";

function RouteComponent(props) {
  const isFreeTrial =
      history.location && history.location.pathname
        ? history.location.pathname.includes("free-trial")
        : false;

  return (
    <div>
      {!isFreeTrial && <Header />}
      {props.children}
    </div>
  )
}

export default RouteComponent;