import axios from "axios";

import * as actions from "./index";

export function sendTryItDetail(formData, locale) {
  let url = "";
  if (locale === "CN") {
    url =
      "https://h3ne7ol3ii.execute-api.cn-north-1.amazonaws.com.cn/prod/launch-trial-v2";
  } else {
    url =
      "https://0pkdsk7a88.execute-api.ap-southeast-1.amazonaws.com/prod/websiteLaunchTrialMT";
  };

  return (dispatch, getState) => {
    dispatch(actions.sendTryItDetailRequest());
    return new Promise((resolve, reject) => {
      axios
        .post(url, {
          ...formData
        })
        .then(body => {
          if (body.data.success && body.data.data.hash && body.data.data.subdomain) {
            dispatch(
              actions.sendTryItDetailSuccess({
                ...body.data.data,
                email: formData.email
              })
            );
            dispatch(actions.getPollProgressRequest());
            dispatch(
              pollProgress(
                { subdomain: body.data.data.subdomain },
                locale,
                body.data.data.hash,
                formData.email
              )
            );
          } else {
            dispatch(actions.sendTryItDetailError(body.data.errors.email));
          }
        })
        .catch(() => {
          dispatch(actions.sendTryItDetailError("Error Occurs"));
        });
    });
  };
};


export function pollProgress(formData, locale, hash, email) {
  const lang = getLangFromLocale(locale);
  const domainStart = getDomainStart(locale);
  let url = "";
  let loginUrl = `https://${formData.subdomain}.${domainStart}/`;
  let redirectUrl = `https://${formData.subdomain}.${domainStart}/free-trial`;
  let ottUrl = `https://${formData.subdomain}.${domainStart}/ims-api/auth/ott-login`;
  if (locale === "CN") {
    url =
      "https://h3ne7ol3ii.execute-api.cn-north-1.amazonaws.com.cn/prod/poll-progress";
  } else {
    url =
      "https://0pkdsk7a88.execute-api.ap-southeast-1.amazonaws.com/prod/poll-progress";
  };

  return (dispatch, getState) => {
    dispatch(actions.setPollProgressCounter());
    return new Promise(async (resolve, reject) => {
      try {
        const body = await axios.post(url, { ...formData });
        if (body.data && body.data.success && body.data.data.ready === "true") {
          const payload = {
            email,
            ott: hash
          };
          const headers = {
            "Content-Type": "application/json",
            "X-Ims-Lang": lang
          };
          const body = await axios.post(ottUrl, JSON.stringify(payload), {
            headers: headers
          });
          if (body.status !== 200 && !body.data.token) {
            redirectUrl = `${loginUrl}?lang=${lang}`;
          } else {
            redirectUrl += `/${body.data.token}?lang=${lang}&email=${email}`
          }
          dispatch(actions.getPollProgressSuccess(redirectUrl));
          resolve(body.data.data.isReady);
        } else {
          setTimeout(() => {
            resolve(dispatch(pollProgress(formData, locale, hash, email)));
          }, 5000);
        }
      } catch (err) {
        dispatch(actions.getPollProgressError("Error Occurs"));
      }
    });
  };
};



function getLangFromLocale(locale) {
  let lang = "en";
  if (locale === "CN") {
    lang = "zh_CN";
  } else if (locale === "HK") {
    lang = "zh_TW";
  }
  return lang;
};

function getDomainStart(locale) {
  if (locale === "CN") {
    return "idexinnovation.cn";
  } else {
    return "getidex.com";
  };
};
