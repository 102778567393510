export default {
  GB:{
    price1:{
      plans_pricing:`Plans & Pricing`,
      choose_plan:`Choose the plan that best suits your needs. Don’t know which one to choose? Contact us.`,
      starter:`STARTER`,
      usd:` `,
      currency:"$",
      99:`0`,
      499:`499`,
      999:`999`,
      month:`Free Forever`,
      professional:`Professional`,
      business:`Business`,
      enterprise:`Enterprise`,
      for_custom:`For pricing and custom features `,
      unlimited_members:`Up to 5 users`,
      xx_teams:`1 team`,
      xx_projects:`Up to 5 projects`,
      xx_ideas:`Unlimited ideas`,
      xx_tasks:`Unlimited tasks`,
      your_own_workspace:`Your own workspace for innovation & project management`,
      collect_analyse:`Collect, analyze, and manage ideas`,
      gantt_chart:`Gantt chart for project management and roadmapping`,
      view:`Board view to visualize your projects and workflow`,
      assign_prioritize:`Assign and prioritize tasks`,
      custom_branding:`Customized branding`,
      performance_analytics: `Performance analytics on innovation and people`,
      single_sign_on:`Single-Sign-On`,
      dedicated_manager:`A dedicated innovation success manager`,
      sla_tech:`99.99% uptime SLA and technical support`,
      try_for_free:`Try Business plan for free`,
      contact_us:`contact us`,
      faq:`Frequently Asked Questions`,
      can_i_cancel:`Can I cancel my subscription?`,
      our_goal:`For monthly plans, you can cancel at any time and won't be billed for subsequent months. For annual plans, you can cancel before the end of the plan and won't be billed for subsequent year.`,
      payment_options:`What are my payment options?`,
      payments_made_via:`All payments are made via bank transfer. We will issue you invoices for payment. `,
      data_protection:`How is my data protected? `,
      serious_secutiry:`We take security very seriously. Your contact info, ideas and data are yours and yours alone. All data is encrypted using transport layer security. All ideas and content that you create and upload to the platform is only available to authenticated users. `,
      more_ides:`How do I upgrade my plan?`,
      dont_worry:`You can email us at info@idexltd.com anytime you want to change your subscriptios.`,
      stay_in_touch:`Stay in touch if you have more questions`,
    },
    price2:{
      plans_pricing:`Plans & Pricing`,
      choose_plan:`Choose the plan that best suits your needs. Don’t know which one to choose? Contact us.`,
      starter:`starter`,
      most_popular:"Most Popular",
      usd:`User`,
      currency:"$",
      monthly_price: `9.99`,
      annual_price: `6.99`,
      99:`99`,
      499:`6.99`,
      999:`999`,
      annual_breakdown: "Total: %{annually} / year (%{monthly} / month)",
      monthly_breakdown: "Total: %{monthly} / month",
      annually:`/ User / Month`,
      monthly: `/ User / Month`,
      professional:`PROFESSIONAL`,
      business:`BUSINESS`,
      enterprise:`Enterprise`,
      for_custom:`For pricing and custom features `,
      unlimited_members:`No limit on the number of users`,
      xx_teams:`Up to 25 teams`,
      xx_ideas:`Unlimited ideas`,
      xx_tasks:`Unlimited tasks`,
      xx_projects:`Unlimited projects`,
      your_own_workspace:`Your own workspace for innovation & project management`,
      collect_analyse:`Collect, analyze, and manage ideas`,
      gantt_chart:`Gantt chart for project management and roadmapping`,
      view:`Board view, list view, and calendar view to visualize your projects the way you want them`,
      assign_prioritize:`Assign and prioritize tasks`,
      custom_branding:`Customized branding`,
      performance_analytics: `Performance analytics on innovation and people`,
      single_sign_on:`Single-Sign-On`,
      dedicated_manager:`A dedicated innovation success manager`,
      sla_tech:`99.99% uptime SLA and technical support`,
      try_for_free:`Try for free`,
      contact_us:`contact us`,
      faq:`Frequently Asked Questions`,
      can_i_cancel:`Can I cancel my subscription?`,
      our_goal:`For monthly plans, you can cancel at any time and won't be billed for subsequent months. For annual plans, you can cancel before the end of the plan and won't be billed for subsequent year.`,
      payment_options:`What are my payment options?`,
      payments_made_via:`All payments are made via bank transfer. We will issue you invoices for payment. `,
      data_protection:`How is my data protected? `,
      serious_secutiry:`We take security very seriously. Your contact info, ideas and data are yours and yours alone. All data is encrypted using transport layer security. All ideas and content that you create and upload to the platform is only available to authenticated users. `,
      more_ides:`How do I upgrade my plan?`,
      dont_worry:`You can email us at info@idexltd.com anytime you want to change your subscriptios.`,
      stay_in_touch:`Stay in touch if you have more questions`,
    },
    price4:{
      plans_pricing:`Plans & Pricing`,
      choose_plan:`Choose the plan that best suits your needs. Don’t know which one to choose? Contact us.`,
      starter:`STARTER`,
      usd:`USD`,
      currency:"$",
      99:`99`,
      499:`499`,
      999:`999`,
      month:`Month`,
      professional:`Professional`,
      business:`Business`,
      enterprise:`ENTERPRISE`,
      for_custom:`For pricing and custom features, please `,
      unlimited_members:`No limit on the number of users`,
      xx_teams:`Unlimited teams`,
      xx_ideas:`Unlimited ideas`,
      xx_tasks:`Unlimited tasks`,
      xx_projects:`Unlimited projects`,
      your_own_workspace:`Your own workspace for innovation & project management`,
      collect_analyse:`Collect, analyze, and manage ideas`,
      gantt_chart:`Gantt chart for project management and roadmapping`,
      view:`Board view, list view, and calendar view to visualize your projects the way you want them`,
      assign_prioritize:`Assign and prioritize tasks`,
      custom_branding:`Customized branding`,
      single_sign_on:`Single-Sign-On`,
      dedicated_manager:`A dedicated innovation success manager`,
      sla_tech:`99.99% uptime SLA and technical support`,
      try_for_free:`Try it for free`,
      contact_us:`contact us`,
      faq:`Frequently Asked Questions`,
      can_i_cancel:`Can I cancel my subscription?`,
      our_goal:`For monthly plans, you can cancel at any time and won't be billed for subsequent months. For annual plans, you can cancel before the end of the plan and won't be billed for subsequent year.`,
      payment_options:`What are my payment options?`,
      payments_made_via:`All payments are made via bank transfer. We will issue you invoices for payment. `,
      data_protection:`How is my data protected? `,
      serious_secutiry:`We take security very seriously. Your contact info, ideas and data are yours and yours alone. All data is encrypted using transport layer security. All ideas and content that you create and upload to the platform is only available to authenticated users. `,
      more_ides:`How do I upgrade my plan?`,
      dont_worry:`You can email us at info@idexltd.com anytime you want to change your subscriptios.`,
      stay_in_touch:`Stay in touch if you have more questions`,
    }
  },
  ES:{
    price1:{
      plans_pricing:`Planes & Precios`,
      choose_plan:`Elija el plan que mejor se adapte a sus necesidades. ¿No sabes cuál elegir? Contáctenos.`,
      starter:`ENTRANTE`,
      usd:` `,
      currency:"$",
      99:`0`,
      499:`499`,
      999:`999`,
      month:`Siempre Gratis`,
      professional:`PROFESIONAL`,
      business:`NEGOCIO`,
      enterprise:`EMPRESA`,
      for_custom:`Para conocer los precios y las funciones personalizadas, `,
      unlimited_members:`Hasta 5 usuarios`,
      xx_teams:`1 equipo`,
      xx_ideas:`Ilimitadas ideas`,
      xx_tasks:`Tareas ilimitadas`,
      xx_projects:`Hasta 5 proyectos`,
      your_own_workspace:`Su propio espacio de trabajo para la innovación y la gestión de proyectos`,
      collect_analyse:`Recopilar, analizar y gestionar ideas`,
      gantt_chart:`Diagrama de Gantt para la gestión de proyectos y la hoja de ruta`,
      view:`Vista de tablero para visualizar sus proyectos`,
      assign_prioritize:`Asignar y priorizar tareas`,
      custom_branding:`Marca personalizada`,
      performance_analytics: `Análisis de desempeño sobre innovación y personas`,
      single_sign_on:`Inicio de sesión único`,
      dedicated_manager:`Un gerente de éxito de innovación dedicado`,
      sla_tech:`99,99% de tiempo de actividad SLA y soporte técnico`,
      try_for_free:`Prueba el plan NEGOCIO gratis`,
      contact_us:`contacta con nosotros`,
      faq:`Preguntas frecuentes`,
      can_i_cancel:`¿Puedo cancelar mi suscripción?`,
      our_goal:`Para los planes mensuales, puede cancelar en cualquier momento y no se le facturarán los meses siguientes. Para los planes anuales, puede cancelar antes de que finalice el plan y no se le facturará el año siguiente.`,
      payment_options:`¿Cuáles son mis opciones de pago?`,
      payments_made_via:`Todos los pagos se realizan mediante transferencia bancaria. Le emitiremos facturas para el pago. `,
      data_protection:`¿Cómo se protegen mis datos? `,
      serious_secutiry:`Nosotros nos tomamos la seguridad muy en serio. Su información de contacto, ideas y datos son suyos y solo suyos. Todos los datos se cifran mediante la seguridad de la capa de transporte. Todas las ideas y el contenido que cree y cargue en la plataforma solo están disponibles para usuarios autenticados. `,
      more_ides:`¿Cómo actualizo mi plan?`,
      dont_worry:`Puede enviarnos un correo electrónico a info@idexltd.com en cualquier momento que desee cambiar su suscripción.`,
      stay_in_touch:`Mantente en contacto si tienes más preguntas`,
    },
    price2:{
      plans_pricing:`Planes & Precios`,
      choose_plan:`Elija el plan que mejor se adapte a sus necesidades. ¿No sabes cuál elegir? Contáctenos.`,
      starter:`ENTRANTE`,
      most_popular:"Más popular",
      usd:`User`,
      currency:"$",
      monthly_price: `9.99`,
      annual_price: `6.99`,
      99:`99`,
      499:`$6.99`,
      999:`999`,
      annual_breakdown: "Total: %{annually} / año (%{monthly} / mes)",
      monthly_breakdown: "Total: %{monthly} / mes",
      annually:`/ Usuario / mes`,
      monthly: `/ Usuario / mes`,
      professional:`PROFESIONAL`,
      business:`NEGOCIO`,
      enterprise:`EMPRESA`,
      for_custom:`Para conocer los precios y las funciones personalizadas, `,
      unlimited_members:`Sin límite en el número de usuarios`,
      xx_teams:`Hasta 25 equipos`,
      xx_ideas:`Ideas ilimitadas`,
      xx_tasks:`Tareas ilimitadas`,
      xx_projects:`Proyectos ilimitados`,
      your_own_workspace:`Su propio espacio de trabajo para la innovación y la gestión de proyectos`,
      collect_analyse:`Recopilar, analizar y gestionar ideas`,
      gantt_chart:`Diagrama de Gantt para la gestión de proyectos y la hoja de ruta`,
      view:`Vista de tablero, vista de lista y vista de calendario para visualizar sus proyectos de la forma que desee`,
      assign_prioritize:`Asignar y priorizar tareas`,
      custom_branding:`Marca personalizada`,
      performance_analytics: `Análisis de desempeño sobre innovación y personase`,
      single_sign_on:`Inicio de sesión único`,
      dedicated_manager:`Un gerente de éxito de innovación dedicado`,
      sla_tech:`99,99% de tiempo de actividad SLA y soporte técnico`,
      try_for_free:`Prueba gratis`,
      contact_us:`contacta con nosotros`,
      faq:`Preguntas frecuentes`,
      can_i_cancel:`¿Puedo cancelar mi suscripción?`,
      our_goal:`Para los planes mensuales, puede cancelar en cualquier momento y no se le facturarán los meses siguientes. Para los planes anuales, puede cancelar antes de que finalice el plan y no se le facturará el año siguiente.`,
      payment_options:`¿Cuáles son mis opciones de pago?`,
      payments_made_via:`Todos los pagos se realizan mediante transferencia bancaria. Le emitiremos facturas para el pago. `,
      data_protection:`¿Cómo se protegen mis datos? `,
      serious_secutiry:`Nosotros nos tomamos la seguridad muy en serio. Su información de contacto, ideas y datos son suyos y solo suyos. Todos los datos se cifran mediante la seguridad de la capa de transporte. Todas las ideas y el contenido que cree y cargue en la plataforma solo están disponibles para usuarios autenticados. `,
      more_ides:`¿Cómo actualizo mi plan?`,
      dont_worry:`Puede enviarnos un correo electrónico a info@idexltd.com en cualquier momento que desee cambiar su suscripción.`,
      stay_in_touch:`Mantente en contacto si tienes más preguntas`,
    },
    price4:{
      plans_pricing:`Planes & Precios`,
      choose_plan:`Elija el plan que mejor se adapte a sus necesidades. ¿No sabes cuál elegir? Contáctenos.`,
      starter:`ENTRANTE`,
      usd:`USD`,
      currency:"$",
      99:`99`,
      499:`499`,
      999:`999`,
      month:`Month`,
      professional:`PROFESIONAL`,
      business:`NEGOCIO`,
      enterprise:`EMPRESA`,
      for_custom:`Para conocer los precios y las funciones personalizadas, `,
      unlimited_members:`Sin límite en el número de usuarios`,
      xx_teams:`Equipos ilimitados`,
      xx_ideas:`Ideas ilimitadas`,
      xx_tasks:`Tareas ilimitadas`,
      xx_projects:`Proyectos ilimitados`,
      your_own_workspace:`Su propio espacio de trabajo para la innovación y la gestión de proyectos`,
      collect_analyse:`Recopilar, analizar y gestionar ideas`,
      gantt_chart:`Diagrama de Gantt para la gestión de proyectos y la hoja de ruta`,
      view:`Vista de tablero, vista de lista y vista de calendario para visualizar sus proyectos de la forma que desee`,
      assign_prioritize:`Asignar y priorizar tareas`,
      custom_branding:`Marca personalizada`,
      performance_analytics: `Análisis de desempeño sobre innovación y personase`,
      single_sign_on:`Inicio de sesión único`,
      dedicated_manager:`Un gerente de éxito de innovación dedicado`,
      sla_tech:`99,99% de tiempo de actividad SLA y soporte técnico`,
      try_for_free:`Prueba gratis`,
      contact_us:`contacta con nosotros`,
      faq:`Preguntas frecuentes`,
      can_i_cancel:`¿Puedo cancelar mi suscripción?`,
      our_goal:`Para los planes mensuales, puede cancelar en cualquier momento y no se le facturarán los meses siguientes. Para los planes anuales, puede cancelar antes de que finalice el plan y no se le facturará el año siguiente.`,
      payment_options:`¿Cuáles son mis opciones de pago?`,
      payments_made_via:`Todos los pagos se realizan mediante transferencia bancaria. Le emitiremos facturas para el pago. `,
      data_protection:`¿Cómo se protegen mis datos? `,
      serious_secutiry:`Nosotros nos tomamos la seguridad muy en serio. Su información de contacto, ideas y datos son suyos y solo suyos. Todos los datos se cifran mediante la seguridad de la capa de transporte. Todas las ideas y el contenido que cree y cargue en la plataforma solo están disponibles para usuarios autenticados. `,
      more_ides:`¿Cómo actualizo mi plan?`,
      dont_worry:`Puede enviarnos un correo electrónico a info@idexltd.com en cualquier momento que desee cambiar su suscripción.`,
      stay_in_touch:`Mantente en contacto si tienes más preguntas`,
    }
  },
    CN:{
      price1:{
        plans_pricing:`价格方案`,
        choose_plan:`选择最适合企业需求的方案。如果您不知道该选择哪一个方案，请随时联系我们。`,
        starter:`基础版`,
        usd:``,
        currency:"¥",
        99:`0`,
        499:`3499`,
        999:`6999`,
        month:`永久免费`,
        professional:`专业版`,
        business:`商业版`,
        enterprise:`企业版`,
        for_custom:`联系我们获取报价和定制化功能`,
        unlimited_members:`最多5名用户`,
        xx_teams:`1个团队`,
        xx_ideas:`不限创新想法数量`,
        xx_tasks:`不限任务数量`,
        xx_projects:`最多5个项目`,
        your_own_workspace:`企业自有的创新和项目管理空间`,
        collect_analyse:`收集，分析和整理创新想法`,
        gantt_chart:`甘特图用于项目管理`,
        view:`通过看板视图让项目管理更直观`,
        assign_prioritize:`分配任务并确定优先级`,
        custom_branding:`根据企业的品牌形象定制`,
        performance_analytics:`企业创新和员工创新数据分析`,
        single_sign_on:`单点登录（SSO）`,
        dedicated_manager:`专有创新成功专家支持`,
        sla_tech:`99.99%的可用时间保证和技术支持`,
        try_for_free:`免费试用商业版`,
        contact_us:`联系我们`,
        faq:`常见问题`,
        can_i_cancel:`能否随时取消订阅？`,
        our_goal:`对于包月方案，您可以随时取消，我们将从下月起取消对您的收费。对于包年方案，您可以在方案结束前取消，并且不会为下一年付费。`,
        payment_options:`付款方式有哪些？`,
        payments_made_via:`付款是通过银行转账进行，我们将每月为您开具发票。`,
        data_protection:`你们怎样保护数据安全？`,
        serious_secutiry:`我们非常重视数据安全。您的联系方式，创新想法和数据都是您自己的。您在我们平台上创建的所有创新想法和提交的所有内容都只对经过验证的用户开放。所有数据都通过传输层安全性协定进行加密。`,
        more_ides:`如何升级我的方案？`,
        dont_worry:`您可以随时发送电子邮件至info@idexltd.com，联系我们以更改您的订阅。`,
        stay_in_touch:`如果您还有其他问题，请随时与我们联系`,
      },
      price2:{
        plans_pricing:`价格方案`,
        choose_plan:`选择最适合企业需求的方案。如果您不知道该选择哪一个方案，请随时联系我们。`,
        starter:`基础版`,
        most_popular:"最受欢迎",
        usd:`每用户`,
        currency:"¥",
        monthly_price: `69.9`,
        annual_price: `48.9`,
        99:`699`,
        499:`69.9`,
        999:`6999`,
        annual_breakdown: "总计: %{annually} / 每年（ (%{monthly} / 每月)",
        monthly_breakdown: "总计: %{monthly} / 每月",
        annually:`/ 每用户 / 每月`,
        monthly: `/ 每用户 / 每月`,
        professional:`专业版`,
        business:`商业版`,
        enterprise:`企业版`,
        for_custom:`联系我们获取报价和定制化功能`,
        unlimited_members:`不限用户数量`,
        xx_teams:`5个团队`,
        xx_ideas:`不限创新想法数量`,
        xx_tasks:`不限任务数量`,
        xx_projects:`不限项目数量`,
        your_own_workspace:`企业自有的创新和项目管理空间`,
        collect_analyse:`收集，分析和整理创新想法`,
        gantt_chart:`甘特图用于项目管理`,
        view:`通过看板，列表和日历视图，让项目管理更直观`,
        assign_prioritize:`分配任务并确定优先级`,
        custom_branding:`根据企业的品牌形象定制`,
        performance_analytics:`企业创新和员工创新数据分析`,
        single_sign_on:`单点登录（SSO）`,
        dedicated_manager:`专有创新成功专家支持`,
        sla_tech:`99.99%的可用时间保证和技术支持`,
        try_for_free:`免费试用`,
        contact_us:`联系我们`,
        faq:`常见问题`,
        can_i_cancel:`能否随时取消订阅？`,
        our_goal:`对于包月方案，您可以随时取消，我们将从下月起取消对您的收费。对于包年方案，您可以在方案结束前取消，并且不会为下一年付费。`,
        payment_options:`付款方式有哪些？`,
        payments_made_via:`付款是通过银行转账进行，我们将每月为您开具发票。`,
        data_protection:`你们怎样保护数据安全？`,
        serious_secutiry:`我们非常重视数据安全。您的联系方式，创新想法和数据都是您自己的。您在我们平台上创建的所有创新想法和提交的所有内容都只对经过验证的用户开放。所有数据都通过传输层安全性协定进行加密。`,
        more_ides:`如何升级我的方案？`,
        dont_worry:`您可以随时发送电子邮件至info@idexltd.com，联系我们以更改您的订阅。`,
        stay_in_touch:`如果您还有其他问题，请随时与我们联系`,
      },
      price4:{
        plans_pricing:`价格方案`,
        choose_plan:`选择最适合企业需求的方案。如果您不知道该选择哪一个方案，请随时联系我们。`,
        starter:`基础版`,
        usd:`元`,
        currency:"¥",
        99:`699`,
        499:`3499`,
        999:`6999`,
        month:`每月`,
        professional:`专业版`,
        business:`商业版`,
        enterprise:`企业版`,
        for_custom:`了解更多产品功能，预约产品演示，获取报价和定制化功能，请`,
        unlimited_members:`不限用户数量`,
        xx_teams:`无限的团队`,
        xx_ideas:`不限创新想法数量`,
        xx_tasks:`不限任务数量`,
        xx_projects:`不限项目数量`,
        your_own_workspace:`企业独有的创新和项目管理空间`,
        collect_analyse:`收集，分析和整理创新想法`,
        gantt_chart:`甘特图用于项目管理`,
        view:`通过看板，列表和日历视图，让项目管理更直观`,
        assign_prioritize:`分配任务并确定优先级`,
        custom_branding:`根据企业的品牌形象定制`,
        performance_analytics:`企业创新和员工创新数据分析`,
        single_sign_on:`单点登录（SSO）`,
        dedicated_manager:`专有创新成功专家支持`,
        sla_tech:`99.99%的可用时间保证和技术支持`,
        try_for_free:`免费试用`,
        contact_us:`联系我们`,
        faq:`常见问题`,
        can_i_cancel:`能否随时取消订阅？`,
        our_goal:`對於月度支付方案，您可以隨時取消，我們重置下月起取消對您的收費。對於年度支付方案，您可以在方案結束前取消，並且不會為下一年一次。`,
        payment_options:`付款方式有哪些？`,
        payments_made_via:`付款是通过银行转账进行，我们将每月为您开具发票。`,
        data_protection:`你们怎样保护数据安全？`,
        serious_secutiry:`我们非常重视数据安全。您的联系方式，创新想法和数据都是您自己的。您在我们平台上创建的所有创新想法和提交的所有内容都只对经过验证的用户开放。所有数据都通过传输层安全性协定进行加密。`,
        more_ides:`如何升级我的方案？`,
        dont_worry:`您可以随时发送电子邮件至info@idexltd.com，联系我们以更改您的订阅。`,
        stay_in_touch:`如果您还有其他问题，请随时与我们联系`,
      }
    },
    HK:{
      price1:{
        plans_pricing:`價格方案`,
        choose_plan:`選擇最適合企業需求的方案。如果您不知道該選擇哪一個方案，請隨時聯繫我們。`,
        starter:`基礎版`,
        usd:`美元`,
        currency:"$",
        99:`0`,
        499:`499`,
        999:`999`,
        month:`永久免費`,
        professional:`專業版`,
        business:`商業版`,
        enterprise:`企業版`,
        for_custom:`聯絡我們獲取報價和定制化功能`,
        unlimited_members:`最多5名用戶`,
        xx_teams:`1個團隊`,
        xx_ideas:`不限創新想法數量`,
        xx_tasks:`不限任務數量`,
        xx_projects:`最多5個項目`,
        your_own_workspace:`企業獨有的創新和專案管理空間`,
        collect_analyse:`收集，分析和整理創新想法`,
        gantt_chart:`甘特圖用於專案管理`,
        view:`通過看板視圖讓專案管理更直觀`,
        assign_prioritize:`分配任務並確定優先級`,
        custom_branding:`根據企業的品牌形象客制`,
        performance_analytics:`企業創新和員工創新數據分析`,
        single_sign_on:`單一登入（SSO)`,
        dedicated_manager:`專有創新成功專家支持`,
        sla_tech:`99.99%的可用時間保證和技術支持`,
        try_for_free:`免費試用商業版`,
        contact_us:`聯絡我們`,
        faq:`常見問題`,
        can_i_cancel:`能否隨時取消訂閱？`,
        our_goal:`對於月度支付方案，您可以隨時取消，我們重置下月起取消對您的收費。對於年度支付方案，您可以在方案結束前取消，並且不會為下一年一次。`,
        payment_options:`付款方式有哪些？`,
        payments_made_via:`付款是通過銀行轉帳進行，我們將每月為您開具付款通知。`,
        data_protection:`你們怎樣保護數據安全？`,
        serious_secutiry:`我們非常重視數據安全。您的聯繫方式，創新想法和數據都是您自己的。您在我們平台上創建的所有創新想法和提交的所有內容都只對經過驗證的用戶開放。所有數據都通過傳輸層安全性協定進行加密。`,
        more_ides:`如何升級我的方案？`,
        dont_worry:`您可以隨時發送電子郵件至info@idexltd.com，聯繫我們以更改您的訂閱。`,
        stay_in_touch:`如果您還有其他問題，請隨時與我們聯絡`,
      },
      price2:{
        plans_pricing:`價格方案`,
        choose_plan:`選擇最適合企業需求的方案。如果您不知道該選擇哪一個方案，請隨時聯繫我們。`,
        starter:`基礎版`,
        most_popular:"最受歡迎",
        usd:`每用戶`,
        99:`99`,
        499:`6.99`,
        999:`999`,
        monthly_price: `9.99`,
        annual_price: `6.99`,
        annual_breakdown: "總計: %{annually} / 每年（ (%{monthly} / 每月)",
        monthly_breakdown: "總計: %{monthly} / 每月",
        annually:`/ 每用户 / 每月`,
        monthly: `/ 每用户 / 每月`,
        professional:`專業版`,
        business:`商業版`,
        enterprise:`企業版`,
        for_custom:`聯絡我們獲取報價和定制化功能`,
        unlimited_members:`不限用戶數量`,
        xx_teams:`5個團隊`,
        xx_ideas:`不限創新想法數量`,
        xx_tasks:`不限任務數量`,
        xx_projects:`不限項目數量`,
        your_own_workspace:`企業獨有的創新和專案管理空間`,
        collect_analyse:`收集，分析和整理創新想法`,
        gantt_chart:`甘特圖用於專案管理`,
        view:`通過看板，列表和日曆視圖，讓專案管理更直觀`,
        assign_prioritize:`分配任務並確定優先級`,
        custom_branding:`根據企業的品牌形象客制`,
        performance_analytics:`企業創新和員工創新數據分析`,
        single_sign_on:`單一登入（SSO)`,
        dedicated_manager:`專有創新成功專家支持`,
        sla_tech:`99.99%的可用時間保證和技術支持`,
        try_for_free:`免費試用`,
        contact_us:`聯絡我們`,
        faq:`常見問題`,
        can_i_cancel:`能否隨時取消訂閱？`,
        our_goal:`對於月度支付方案，您可以隨時取消，我們重置下月起取消對您的收費。對於年度支付方案，您可以在方案結束前取消，並且不會為下一年一次。`,
        payment_options:`付款方式有哪些？`,
        payments_made_via:`付款是通過銀行轉帳進行，我們將每月為您開具付款通知。`,
        data_protection:`你們怎樣保護數據安全？`,
        serious_secutiry:`我們非常重視數據安全。您的聯繫方式，創新想法和數據都是您自己的。您在我們平台上創建的所有創新想法和提交的所有內容都只對經過驗證的用戶開放。所有數據都通過傳輸層安全性協定進行加密。`,
        more_ides:`如何升級我的方案？`,
        dont_worry:`您可以隨時發送電子郵件至info@idexltd.com，聯繫我們以更改您的訂閱。`,
        stay_in_touch:`如果您還有其他問題，請隨時與我們聯絡`,
      },
      price4:{
        plans_pricing:`價格方案`,
        choose_plan:`選擇最適合企業需求的方案。如果您不知道該選擇哪一個方案，請隨時聯繫我們。`,
        starter:`基礎版`,
        usd:`美元`,
        99:`99`,
        499:`499`,
        999:`999`,
        month:`每月`,
        professional:`專業版`,
        business:`商業版`,
        enterprise:`企業版`,
        for_custom:`了解更多產品功能，預約產品演示，獲取報價和定制化功能，請`,
        unlimited_members:`不限用戶數量`,
        xx_teams:`无限的团队`,
        xx_ideas:`不限創新想法數量`,
        xx_tasks:`不限任務數量`,
        xx_projects:`不限項目數量`,
        your_own_workspace:`企業獨有的創新和專案管理空間`,
        collect_analyse:`收集，分析和整理創新想法`,
        gantt_chart:`甘特圖用於專案管理`,
        view:`通過看板，列表和日曆視圖，讓專案管理更直觀`,
        assign_prioritize:`分配任務並確定優先級`,
        custom_branding:`根據企業的品牌形象客制`,
        performance_analytics:`企業創新和員工創新數據分析`,
        single_sign_on:`單一登入（SSO)`,
        dedicated_manager:`專有創新成功專家支持`,
        sla_tech:`99.99%的可用時間保證和技術支持`,
        try_for_free:`免費試用`,
        contact_us:`聯絡我們`,
        faq:`常見問題`,
        can_i_cancel:`能否隨時取消訂閱？`,
        our_goal:`我們的目標是讓您滿意。您可以隨時取消訂閱，我們將從下月起取消對您的收費。`,
        payment_options:`付款方式有哪些？`,
        payments_made_via:`付款是通過銀行轉帳進行，我們將每月為您開具付款通知。`,
        data_protection:`你們怎樣保護數據安全？`,
        serious_secutiry:`我們非常重視數據安全。您的聯繫方式，創新想法和數據都是您自己的。您在我們平台上創建的所有創新想法和提交的所有內容都只對經過驗證的用戶開放。所有數據都通過傳輸層安全性協定進行加密。`,
        more_ides:`如何升級我的方案？`,
        dont_worry:`您可以隨時發送電子郵件至info@idexltd.com，聯繫我們以更改您的訂閱。`,
        stay_in_touch:`如果您還有其他問題，請隨時與我們聯絡`,
      }
    }
}
