import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';

import './Howworks.scss'
import checkIcon from '../../assets/images/icons/check.svg'
import video1 from '../../assets/videos/1st.mp4'
import video2 from '../../assets/videos/2nd.mp4'
import video3 from '../../assets/videos/3rd.mp4'

class Howworks extends Component {
  componentDidMount() {
    let lang = 'GB';
    if (localStorage.getItem("lang")) {
      lang = localStorage.getItem("lang");
    }
    this.setState({ lang });
    this.videoObserver();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.i18n && this.props.i18n && prevProps.i18n.locale !== this.props.i18n.locale) {
      this.setState({ lang: this.props.i18n.locale });
    }
  };

  videoObserver = () => {
    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.8
    };
  
    let callback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.play();
        }
        else {
          entry.target.pause();
        }
      });
    };
    
    let observer = new IntersectionObserver(callback, options);
    const videos = document.querySelectorAll("video");
    
    videos.forEach((vid) => {
      setTimeout(() => {
        observer.observe(vid);
        vid.muted = true;
      }, 1000);
    });
  };

  isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent);

  render() {
    let config = {
      file: {
        attributes: {
          controlsList: "nodownload"
        }
      }
    };

    if (this.isIOS()) {
      config.youtube = {
        controls: 0,
        disablekb: 1,
        fs: 0,
        iv_load_policy: 3,
        cc_load_policy: 0,
        modestbranding: 1,
        playsinline: 1,
        rel: 0,
      };
    };

    return (
      <div className="howworks-container">
        <h2>{I18n.t('howworks.title')}</h2>
        <div className="howworks-item align-left item-ideate">
          <div className="howworks-text-wrapper">
            <div className="howwork-item-textgroup">
              <div className="description">
                <img src={checkIcon} alt="Right Mark" />
                <p>{I18n.t('howworks.ideate')}</p>
              </div>
              <div className="content">{I18n.t('howworks.harness')}</div>
            </div>
          </div>
          <div className="idex-player-container">
            <ReactPlayer
              className="react-player"
              url={this.isIOS() ? 'https://youtu.be/TMYrxmBi83w' : video1}
              width="100%"
              height={this.isIOS() ? '45vh' : '100%'}
              loop
              muted
              config={config}
            />
          </div>
        </div>

        <div className="howworks-item align-right item-engage">
          <div className="howworks-text-wrapper">
            <div className="howwork-item-textgroup">
              <div className="description">
                <img src={checkIcon} alt="Right Mark" />
                <p>{I18n.t('howworks.engage')}</p>
              </div>
              <div className="content">{I18n.t('howworks.easysearch')}</div>
            </div>
          </div>
          <div className="idex-player-container">
            <ReactPlayer
              className="react-player"
              url={this.isIOS() ? 'https://youtu.be/ot2lLNj-Uo0' : video2}
              width="100%"
              height={this.isIOS() ? '45vh' : '100%'}
              loop
              muted
              config={config}
            />
          </div>
        </div>

        <div className="howworks-item align-left item-evaluate">
          <div className="howworks-text-wrapper">
            <div className="howwork-item-textgroup">
              <div className="description">
                <img src={checkIcon} alt="Right Mark" />
                <p>{I18n.t('howworks.evaluate')}</p>
              </div>
              <div className="content">{I18n.t('howworks.prioritize')}</div>
            </div>
          </div>
          <div className="idex-player-container">
            <ReactPlayer
              className="react-player"
              url={this.isIOS() ? 'https://youtu.be/ZUeUaEYg9bw' : video3}
              width="100%"
              height={this.isIOS() ? '45vh' : '100%'}
              loop
              muted
              config={config}
            />
          </div>
        </div>
      </div>
    );
  };
};

function mapStateToProps(state) {
  return {
    i18n: state.i18n
  };
};

export default connect(mapStateToProps)(Howworks);
