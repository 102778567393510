import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {I18n} from "react-redux-i18n";
import { Experiment, Variant, emitter, experimentDebugger } from "@marvelapp/react-ab-test";
import ReactGA from "react-ga";
import { variantArr } from "../../utils";

import bgImage from "../../assets/images/background/Circles.svg";
import notebookImage from "../../assets/images/background/Notebook.svg";
import "./HeadSection.scss";

let trackingId = "UA-137478659-1";
ReactGA.initialize(trackingId);
ReactGA.set({ dimension14: "Sports" });

experimentDebugger.enable();
emitter.defineVariants(
  "Middle-test",
  ["Try-It-For-Free", "Experience-IDEX", "Get-Started"],
  [33, 33, 33]
);

function HeadSection() {
  return (
    <div className="head-section">
      <img className="head-circle-img" src={bgImage} alt="head-circle-img" />
      <img
        className="head-notebook-img"
        src={notebookImage}
        alt="head-notebook-img"
      />
      <div className="linear-bg"></div>
      <h2>{I18n.t("headSection.moveForward")}</h2>
      <p>{I18n.t("headSection.transform")}</p>
      <Experiment name="Middle-test">
        {variantArr.map(val => {
          return (
            <Variant name={val.name} key={val.name}>
              <Link
                to="/free-trial"
                target="_blank"
                className="exp-idex-btn"
              >
                {I18n.t(val.content)}
              </Link>
            </Variant>
          );
        })}
      </Experiment>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    i18n: state.i18n
  };
};

export default connect(mapStateToProps)(HeadSection);
emitter.addPlayListener("Test-Addl-Info", function (
  experimentName,
  variantName
) {
});

emitter.addWinListener("Middle-test", function (experimentName, variantName) {
  ReactGA.event({
    category: variantName,
    action: experimentName
  });
});
