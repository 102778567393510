export default [
  {
    iso2: "CN",
    name: "China",
    iso3: "CHN",
    unicode: "🇨🇳",
    dial: "+86",
    currency: "CNY",
    capital: "Beijing",
    continent: "AS",
    traditional_cn: "中國",
    simplified_cn: "中国"
  },
  {
    iso2: "HK",
    name: "Hong Kong",
    iso3: "HKG",
    unicode: "🇭🇰",
    dial: "+852",
    currency: "HKD",
    capital: "Hong Kong",
    continent: "AS",
    traditional_cn: "香港",
    simplified_cn: "香港"
  },
  {
    iso2: "TW",
    name: "Taiwan",
    iso3: "TWN",
    unicode: "🇹🇼",
    dial: "+886",
    currency: "TWD",
    capital: "Taipei",
    continent: "AS",
    traditional_cn: "台灣",
    simplified_cn: "台湾"
  },
  {
    iso2: "AF",
    name: "Afghanistan",
    iso3: "AFG",
    unicode: "🇦🇫",
    dial: "+93",
    currency: "AFN",
    capital: "Kabul",
    continent: "AS",
    traditional_cn: "阿富汗",
    simplified_cn: "阿富汗"
  },
  {
    iso2: "AX",
    name: "Aland Islands",
    iso3: "ALA",
    unicode: "🇦🇽",
    dial: "+358-18",
    currency: "EUR",
    capital: "Mariehamn",
    continent: "EU",
    traditional_cn: "奧蘭",
    simplified_cn: "奥兰"
  },
  {
    iso2: "AL",
    name: "Albania",
    iso3: "ALB",
    unicode: "🇦🇱",
    dial: "+355",
    currency: "ALL",
    capital: "Tirana",
    continent: "EU",
    traditional_cn: "阿爾巴尼亞",
    simplified_cn: "阿尔巴尼亚"
  },
  {
    iso2: "DZ",
    name: "Algeria",
    iso3: "DZA",
    unicode: "🇩🇿",
    dial: "+213",
    currency: "DZD",
    capital: "Algiers",
    continent: "AF",
    traditional_cn: "阿爾及利亞",
    simplified_cn: "阿尔及利亚"
  },
  {
    iso2: "AS",
    name: "American Samoa",
    iso3: "ASM",
    unicode: "🇦🇸",
    dial: "+1-684",
    currency: "USD",
    capital: "Pago Pago",
    continent: "OC",
    traditional_cn: "美屬薩摩亞",
    simplified_cn: "美属萨摩亚"
  },
  {
    iso2: "AD",
    name: "Andorra",
    iso3: "AND",
    unicode: "🇦🇩",
    dial: "+376",
    currency: "EUR",
    capital: "Andorra la Vella",
    continent: "EU",
    traditional_cn: "安道爾",
    simplified_cn: "安道尔"
  },
  {
    iso2: "AO",
    name: "Angola",
    iso3: "AGO",
    unicode: "🇦🇴",
    dial: "+244",
    currency: "AOA",
    capital: "Luanda",
    continent: "AF",
    traditional_cn: "安哥拉",
    simplified_cn: "安哥拉"
  },
  {
    iso2: "AI",
    name: "Anguilla",
    iso3: "AIA",
    unicode: "🇦🇮",
    dial: "+1-264",
    currency: "XCD",
    capital: "The Valley",
    continent: "NA",
    traditional_cn: "安圭拉",
    simplified_cn: "安圭拉"
  },
  {
    iso2: "AQ",
    name: "Antarctica",
    iso3: "ATA",
    unicode: "🇦🇶",
    dial: "+672",
    currency: "",
    capital: "",
    continent: "AN",
    traditional_cn: "南極洲",
    simplified_cn: "南极洲"
  },
  {
    iso2: "AG",
    name: "Antigua and Barbuda",
    iso3: "ATG",
    unicode: "🇦🇬",
    dial: "+1-268",
    currency: "XCD",
    capital: "St. John's",
    continent: "NA",
    traditional_cn: "安提瓜和巴布達",
    simplified_cn: "安地卡及巴布达"
  },
  {
    iso2: "AR",
    name: "Argentina",
    iso3: "ARG",
    unicode: "🇦🇷",
    dial: "+54",
    currency: "ARS",
    capital: "Buenos Aires",
    continent: "SA",
    traditional_cn: "阿根廷",
    simplified_cn: "阿根廷"
  },
  {
    iso2: "AM",
    name: "Armenia",
    iso3: "ARM",
    unicode: "🇦🇲",
    dial: "+374",
    currency: "AMD",
    capital: "Yerevan",
    continent: "AS",
    traditional_cn: "亞美尼亞",
    simplified_cn: "亚美尼亚"
  },
  {
    iso2: "AW",
    name: "Aruba",
    iso3: "ABW",
    unicode: "🇦🇼",
    dial: "+297",
    currency: "AWG",
    capital: "Oranjestad",
    continent: "NA",
    traditional_cn: "阿魯巴",
    simplified_cn: "阿鲁巴"
  },
  {
    iso2: "AU",
    name: "Australia",
    iso3: "AUS",
    unicode: "🇦🇺",
    dial: "+61",
    currency: "AUD",
    capital: "Canberra",
    continent: "OC",
    traditional_cn: "澳洲",
    simplified_cn: "澳大利亚"
  },
  {
    iso2: "AT",
    name: "Austria",
    iso3: "AUT",
    unicode: "🇦🇹",
    dial: "+43",
    currency: "EUR",
    capital: "Vienna",
    continent: "EU",
    traditional_cn: "奧地利",
    simplified_cn: "奥地利"
  },
  {
    iso2: "AZ",
    name: "Azerbaijan",
    iso3: "AZE",
    unicode: "🇦🇿",
    dial: "+994",
    currency: "AZN",
    capital: "Baku",
    continent: "AS",
    traditional_cn: "亞塞拜然",
    simplified_cn: "阿塞拜疆"
  },
  {
    iso2: "BS",
    name: "Bahamas",
    iso3: "BHS",
    unicode: "🇧🇸",
    dial: "+1-242",
    currency: "BSD",
    capital: "Nassau",
    continent: "NA",
    traditional_cn: "巴哈馬",
    simplified_cn: "巴哈马"
  },
  {
    iso2: "BH",
    name: "Bahrain",
    iso3: "BHR",
    unicode: "🇧🇭",
    dial: "+973",
    currency: "BHD",
    capital: "Manama",
    continent: "AS",
    traditional_cn: "巴林",
    simplified_cn: "巴林"
  },
  {
    iso2: "BD",
    name: "Bangladesh",
    iso3: "BGD",
    unicode: "🇧🇩",
    dial: "+880",
    currency: "BDT",
    capital: "Dhaka",
    continent: "AS",
    traditional_cn: "孟加拉",
    simplified_cn: "孟加拉国"
  },
  {
    iso2: "BB",
    name: "Barbados",
    iso3: "BRB",
    unicode: "🇧🇧",
    dial: "+1-246",
    currency: "BBD",
    capital: "Bridgetown",
    continent: "NA",
    traditional_cn: "巴巴多斯",
    simplified_cn: "巴巴多斯"
  },
  {
    iso2: "BY",
    name: "Belarus",
    iso3: "BLR",
    unicode: "🇧🇾",
    dial: "+375",
    currency: "BYR",
    capital: "Minsk",
    continent: "EU",
    traditional_cn: "白俄羅斯",
    simplified_cn: "白俄罗斯"
  },
  {
    iso2: "BE",
    name: "Belgium",
    iso3: "BEL",
    unicode: "🇧🇪",
    dial: "+32",
    currency: "EUR",
    capital: "Brussels",
    continent: "EU",
    traditional_cn: "比利時",
    simplified_cn: "比利时"
  },
  {
    iso2: "BZ",
    name: "Belize",
    iso3: "BLZ",
    unicode: "🇧🇿",
    dial: "+501",
    currency: "BZD",
    capital: "Belmopan",
    continent: "NA",
    traditional_cn: "貝里斯",
    simplified_cn: "伯利兹"
  },
  {
    iso2: "BJ",
    name: "Benin",
    iso3: "BEN",
    unicode: "🇧🇯",
    dial: "+229",
    currency: "XOF",
    capital: "Porto-Novo",
    continent: "AF",
    traditional_cn: "貝南",
    simplified_cn: "贝宁"
  },
  {
    iso2: "BM",
    name: "Bermuda",
    iso3: "BMU",
    unicode: "🇧🇲",
    dial: "+1-441",
    currency: "BMD",
    capital: "Hamilton",
    continent: "NA",
    traditional_cn: "百慕大",
    simplified_cn: "百慕大"
  },
  {
    iso2: "BT",
    name: "Bhutan",
    iso3: "BTN",
    unicode: "🇧🇹",
    dial: "+975",
    currency: "BTN",
    capital: "Thimphu",
    continent: "AS",
    traditional_cn: "不丹",
    simplified_cn: "不丹"
  },
  {
    iso2: "BO",
    name: "Bolivia",
    iso3: "BOL",
    unicode: "🇧🇴",
    dial: "+591",
    currency: "BOB",
    capital: "Sucre",
    continent: "SA",
    traditional_cn: "玻利維亞",
    simplified_cn: "玻利维亚"
  },
  {
    iso2: "BQ",
    name: "Bonaire, Saint Eustatius and Saba ",
    iso3: "BES",
    unicode: "🇧🇶",
    dial: "+599",
    currency: "USD",
    capital: "",
    continent: "NA",
    traditional_cn: "荷蘭加勒比區",
    simplified_cn: "荷兰加勒比区"
  },
  {
    iso2: "BA",
    name: "Bosnia and Herzegovina",
    iso3: "BIH",
    unicode: "🇧🇦",
    dial: "+387",
    currency: "BAM",
    capital: "Sarajevo",
    continent: "EU",
    traditional_cn: "波斯尼亞和黑塞哥維那",
    simplified_cn: "波黑"
  },
  {
    iso2: "BW",
    name: "Botswana",
    iso3: "BWA",
    unicode: "🇧🇼",
    dial: "+267",
    currency: "BWP",
    capital: "Gaborone",
    continent: "AF",
    traditional_cn: "博茨瓦納",
    simplified_cn: "博茨瓦纳"
  },
  {
    iso2: "BV",
    name: "Bouvet Island",
    iso3: "BVT",
    unicode: "🇧🇻",
    dial: "+55",
    currency: "NOK",
    capital: "",
    continent: "AN",
    traditional_cn: "布韋島",
    simplified_cn: "布韦岛"
  },
  {
    iso2: "BR",
    name: "Brazil",
    iso3: "BRA",
    unicode: "🇧🇷",
    dial: "+55",
    currency: "BRL",
    capital: "Brasilia",
    continent: "SA",
    traditional_cn: "巴西",
    simplified_cn: "巴西"
  },
  {
    iso2: "IO",
    name: "British Indian Ocean Territory",
    iso3: "IOT",
    unicode: "🇮🇴",
    dial: "+246",
    currency: "USD",
    capital: "Diego Garcia",
    continent: "AS",
    traditional_cn: "英屬印度洋領地",
    simplified_cn: "英属印度洋领地"
  },
  {
    iso2: "VG",
    name: "British Virgin Islands",
    iso3: "VGB",
    unicode: "🇻🇬",
    dial: "+1-284",
    currency: "USD",
    capital: "Road Town",
    continent: "NA",
    traditional_cn: "英屬維爾京群島",
    simplified_cn: "英属维尔京群岛"
  },
  {
    iso2: "BN",
    name: "Brunei",
    iso3: "BRN",
    unicode: "🇧🇳",
    dial: "+673",
    currency: "BND",
    capital: "Bandar Seri Begawan",
    continent: "AS",
    traditional_cn: "汶萊",
    simplified_cn: "文莱"
  },
  {
    iso2: "BG",
    name: "Bulgaria",
    iso3: "BGR",
    unicode: "🇧🇬",
    dial: "+359",
    currency: "BGN",
    capital: "Sofia",
    continent: "EU",
    traditional_cn: "保加利亞",
    simplified_cn: "保加利亚"
  },
  {
    iso2: "BF",
    name: "Burkina Faso",
    iso3: "BFA",
    unicode: "🇧🇫",
    dial: "+226",
    currency: "XOF",
    capital: "Ouagadougou",
    continent: "AF",
    traditional_cn: "布吉納法索",
    simplified_cn: "布吉纳法索"
  },
  {
    iso2: "BI",
    name: "Burundi",
    iso3: "BDI",
    unicode: "🇧🇮",
    dial: "+257",
    currency: "BIF",
    capital: "Bujumbura",
    continent: "AF",
    traditional_cn: "蒲隆地",
    simplified_cn: "布隆迪"
  },
  {
    iso2: "KH",
    name: "Cambodia",
    iso3: "KHM",
    unicode: "🇰🇭",
    dial: "+855",
    currency: "KHR",
    capital: "Phnom Penh",
    continent: "AS",
    traditional_cn: "柬埔寨",
    simplified_cn: "柬埔寨"
  },
  {
    iso2: "CM",
    name: "Cameroon",
    iso3: "CMR",
    unicode: "🇨🇲",
    dial: "+237",
    currency: "XAF",
    capital: "Yaounde",
    continent: "AF",
    traditional_cn: "喀麥隆",
    simplified_cn: "喀麦隆"
  },
  {
    iso2: "CA",
    name: "Canada",
    iso3: "CAN",
    unicode: "🇨🇦",
    dial: "+1",
    currency: "CAD",
    capital: "Ottawa",
    continent: "NA",
    traditional_cn: "加拿大",
    simplified_cn: "加拿大"
  },
  {
    iso2: "CV",
    name: "Cape Verde",
    iso3: "CPV",
    unicode: "🇨🇻",
    dial: "+238",
    currency: "CVE",
    capital: "Praia",
    continent: "AF",
    traditional_cn: "維德角",
    simplified_cn: "佛得角"
  },
  {
    iso2: "KY",
    name: "Cayman Islands",
    iso3: "CYM",
    unicode: "🇰🇾",
    dial: "+1-345",
    currency: "KYD",
    capital: "George Town",
    continent: "NA",
    traditional_cn: "開曼群島",
    simplified_cn: "开曼群岛"
  },
  {
    iso2: "CF",
    name: "Central African Republic",
    iso3: "CAF",
    unicode: "🇨🇫",
    dial: "+236",
    currency: "XAF",
    capital: "Bangui",
    continent: "AF",
    traditional_cn: "中非",
    simplified_cn: "中非"
  },
  {
    iso2: "TD",
    name: "Chad",
    iso3: "TCD",
    unicode: "🇹🇩",
    dial: "+235",
    currency: "XAF",
    capital: "N'Djamena",
    continent: "AF",
    traditional_cn: "查德",
    simplified_cn: "乍得"
  },
  {
    iso2: "CL",
    name: "Chile",
    iso3: "CHL",
    unicode: "🇨🇱",
    dial: "+56",
    currency: "CLP",
    capital: "Santiago",
    continent: "SA",
    traditional_cn: "智利",
    simplified_cn: "智利"
  },

  {
    iso2: "CX",
    name: "Christmas Island",
    iso3: "CXR",
    unicode: "🇨🇽",
    dial: "+61",
    currency: "AUD",
    capital: "Flying Fish Cove",
    continent: "AS",
    traditional_cn: "聖誕島",
    simplified_cn: "圣诞岛"
  },
  {
    iso2: "CC",
    name: "Cocos Islands",
    iso3: "CCK",
    unicode: "🇨🇨",
    dial: "+61",
    currency: "AUD",
    capital: "West Island",
    continent: "AS",
    traditional_cn: "科科斯（基林）群島",
    simplified_cn: "科科斯（基林）群岛"
  },
  {
    iso2: "CO",
    name: "Colombia",
    iso3: "COL",
    unicode: "🇨🇴",
    dial: "+57",
    currency: "COP",
    capital: "Bogota",
    continent: "SA",
    traditional_cn: "哥倫比亞",
    simplified_cn: "哥伦比亚"
  },
  {
    iso2: "KM",
    name: "Comoros",
    iso3: "COM",
    unicode: "🇰🇲",
    dial: "+269",
    currency: "KMF",
    capital: "Moroni",
    continent: "AF",
    traditional_cn: "葛摩",
    simplified_cn: "科摩罗"
  },
  {
    iso2: "CK",
    name: "Cook Islands",
    iso3: "COK",
    unicode: "🇨🇰",
    dial: "+682",
    currency: "NZD",
    capital: "Avarua",
    continent: "OC",
    traditional_cn: "庫克群島",
    simplified_cn: "库克群岛"
  },
  {
    iso2: "CR",
    name: "Costa Rica",
    iso3: "CRI",
    unicode: "🇨🇷",
    dial: "+506",
    currency: "CRC",
    capital: "San Jose",
    continent: "NA",
    traditional_cn: "哥斯大黎加",
    simplified_cn: "哥斯达黎加"
  },
  {
    iso2: "HR",
    name: "Croatia",
    iso3: "HRV",
    unicode: "🇭🇷",
    dial: "+385",
    currency: "HRK",
    capital: "Zagreb",
    continent: "EU",
    traditional_cn: "克羅埃西亞",
    simplified_cn: "克罗地亚"
  },
  {
    iso2: "CU",
    name: "Cuba",
    iso3: "CUB",
    unicode: "🇨🇺",
    dial: "+53",
    currency: "CUP",
    capital: "Havana",
    continent: "NA",
    traditional_cn: "古巴",
    simplified_cn: "古巴"
  },
  {
    iso2: "CW",
    name: "Curacao",
    iso3: "CUW",
    unicode: "🇨🇼",
    dial: "+599",
    currency: "ANG",
    capital: "Willemstad",
    continent: "NA",
    traditional_cn: "庫拉索",
    simplified_cn: "库拉索"
  },
  {
    iso2: "CY",
    name: "Cyprus",
    iso3: "CYP",
    unicode: "🇨🇾",
    dial: "+357",
    currency: "EUR",
    capital: "Nicosia",
    continent: "EU",
    traditional_cn: "賽普勒斯",
    simplified_cn: "塞浦路斯"
  },
  {
    iso2: "CZ",
    name: "Czech Republic",
    iso3: "CZE",
    unicode: "🇨🇿",
    dial: "+420",
    currency: "CZK",
    capital: "Prague",
    continent: "EU",
    traditional_cn: "捷克",
    simplified_cn: "捷克"
  },
  {
    iso2: "CD",
    name: "Democratic Republic of the Congo",
    iso3: "COD",
    unicode: "🇨🇩",
    dial: "+243",
    currency: "CDF",
    capital: "Kinshasa",
    continent: "AF",
    traditional_cn: "剛果民主共和國",
    simplified_cn: "刚果（金）"
  },
  {
    iso2: "DK",
    name: "Denmark",
    iso3: "DNK",
    unicode: "🇩🇰",
    dial: "+45",
    currency: "DKK",
    capital: "Copenhagen",
    continent: "EU",
    traditional_cn: "丹麥",
    simplified_cn: "丹麦"
  },
  {
    iso2: "DJ",
    name: "Djibouti",
    iso3: "DJI",
    unicode: "🇩🇯",
    dial: "+253",
    currency: "DJF",
    capital: "Djibouti",
    continent: "AF",
    traditional_cn: "吉布地",
    simplified_cn: "吉布提"
  },
  {
    iso2: "DM",
    name: "Dominica",
    iso3: "DMA",
    unicode: "🇩🇲",
    dial: "+1-767",
    currency: "XCD",
    capital: "Roseau",
    continent: "NA",
    traditional_cn: "多米尼克",
    simplified_cn: "多米尼克"
  },
  {
    iso2: "DO",
    name: "Dominican Republic",
    iso3: "DOM",
    unicode: "🇩🇴",
    dial: "+1-809 and 1-829",
    currency: "DOP",
    capital: "Santo Domingo",
    continent: "NA",
    traditional_cn: "多明尼加",
    simplified_cn: "多米尼加"
  },
  {
    iso2: "TL",
    name: "East Timor",
    iso3: "TLS",
    unicode: "🇹🇱",
    dial: "+670",
    currency: "USD",
    capital: "Dili",
    continent: "OC",
    traditional_cn: "東帝汶",
    simplified_cn: "东帝汶"
  },
  {
    iso2: "EC",
    name: "Ecuador",
    iso3: "ECU",
    unicode: "🇪🇨",
    dial: "+593",
    currency: "USD",
    capital: "Quito",
    continent: "SA",
    traditional_cn: "厄瓜多",
    simplified_cn: "厄瓜多尔"
  },
  {
    iso2: "EG",
    name: "Egypt",
    iso3: "EGY",
    unicode: "🇪🇬",
    dial: "+20",
    currency: "EGP",
    capital: "Cairo",
    continent: "AF",
    traditional_cn: "埃及",
    simplified_cn: "埃及"
  },
  {
    iso2: "SV",
    name: "El Salvador",
    iso3: "SLV",
    unicode: "🇸🇻",
    dial: "+503",
    currency: "USD",
    capital: "San Salvador",
    continent: "NA",
    traditional_cn: "薩爾瓦多",
    simplified_cn: "萨尔瓦多"
  },
  {
    iso2: "GQ",
    name: "Equatorial Guinea",
    iso3: "GNQ",
    unicode: "🇬🇶",
    dial: "+240",
    currency: "XAF",
    capital: "Malabo",
    continent: "AF",
    traditional_cn: "赤道幾內亞",
    simplified_cn: "赤道几内亚"
  },
  {
    iso2: "ER",
    name: "Eritrea",
    iso3: "ERI",
    unicode: "🇪🇷",
    dial: "+291",
    currency: "ERN",
    capital: "Asmara",
    continent: "AF",
    traditional_cn: "厄利垂亞",
    simplified_cn: "厄立特里亚"
  },
  {
    iso2: "EE",
    name: "Estonia",
    iso3: "EST",
    unicode: "🇪🇪",
    dial: "+372",
    currency: "EUR",
    capital: "Tallinn",
    continent: "EU",
    traditional_cn: "愛沙尼亞",
    simplified_cn: "爱沙尼亚"
  },
  {
    iso2: "ET",
    name: "Ethiopia",
    iso3: "ETH",
    unicode: "🇪🇹",
    dial: "+251",
    currency: "ETB",
    capital: "Addis Ababa",
    continent: "AF",
    traditional_cn: "衣索比亞",
    simplified_cn: "埃塞俄比亚"
  },
  {
    iso2: "FK",
    name: "Falkland Islands",
    iso3: "FLK",
    unicode: "🇫🇰",
    dial: "+500",
    currency: "FKP",
    capital: "Stanley",
    continent: "SA",
    traditional_cn: "福克蘭群島",
    simplified_cn: "福克兰群岛"
  },
  {
    iso2: "FO",
    name: "Faroe Islands",
    iso3: "FRO",
    unicode: "🇫🇴",
    dial: "+298",
    currency: "DKK",
    capital: "Torshavn",
    continent: "EU",
    traditional_cn: "法羅群島",
    simplified_cn: "法罗群岛"
  },
  {
    iso2: "FJ",
    name: "Fiji",
    iso3: "FJI",
    unicode: "🇫🇯",
    dial: "+679",
    currency: "FJD",
    capital: "Suva",
    continent: "OC",
    traditional_cn: "斐濟",
    simplified_cn: "斐济"
  },
  {
    iso2: "FI",
    name: "Finland",
    iso3: "FIN",
    unicode: "🇫🇮",
    dial: "+358",
    currency: "EUR",
    capital: "Helsinki",
    continent: "EU",
    traditional_cn: "芬蘭",
    simplified_cn: "芬兰"
  },
  {
    iso2: "FR",
    name: "France",
    iso3: "FRA",
    unicode: "🇫🇷",
    dial: "+33",
    currency: "EUR",
    capital: "Paris",
    continent: "EU",
    traditional_cn: "法國",
    simplified_cn: "法国"
  },
  {
    iso2: "GF",
    name: "French Guiana",
    iso3: "GUF",
    unicode: "🇬🇫",
    dial: "+594",
    currency: "EUR",
    capital: "Cayenne",
    continent: "SA",
    traditional_cn: "法屬圭亞那",
    simplified_cn: "法属圭亚那"
  },
  {
    iso2: "PF",
    name: "French Polynesia",
    iso3: "PYF",
    unicode: "🇵🇫",
    dial: "+689",
    currency: "XPF",
    capital: "Papeete",
    continent: "OC",
    traditional_cn: "法屬玻里尼西亞",
    simplified_cn: "法属玻里尼西亚"
  },
  {
    iso2: "TF",
    name: "French Southern Territories",
    iso3: "ATF",
    unicode: "🇹🇫",
    dial: "+262",
    currency: "EUR",
    capital: "Port-aux-Francais",
    continent: "AN",
    traditional_cn: "法屬南方和南極洲領地",
    simplified_cn: "法属南方和南极洲领地"
  },
  {
    iso2: "GA",
    name: "Gabon",
    iso3: "GAB",
    unicode: "🇬🇦",
    dial: "+241",
    currency: "XAF",
    capital: "Libreville",
    continent: "AF",
    traditional_cn: "加彭",
    simplified_cn: "加蓬"
  },
  {
    iso2: "GM",
    name: "Gambia",
    iso3: "GMB",
    unicode: "🇬🇲",
    dial: "+220",
    currency: "GMD",
    capital: "Banjul",
    continent: "AF",
    traditional_cn: "甘比亞",
    simplified_cn: "冈比亚"
  },
  {
    iso2: "GE",
    name: "Georgia",
    iso3: "GEO",
    unicode: "🇬🇪",
    dial: "+995",
    currency: "GEL",
    capital: "Tbilisi",
    continent: "AS",
    traditional_cn: "喬治亞",
    simplified_cn: "格鲁吉亚"
  },
  {
    iso2: "DE",
    name: "Germany",
    iso3: "DEU",
    unicode: "🇩🇪",
    dial: "+49",
    currency: "EUR",
    capital: "Berlin",
    continent: "EU",
    traditional_cn: "德國",
    simplified_cn: "德国"
  },
  {
    iso2: "GH",
    name: "Ghana",
    iso3: "GHA",
    unicode: "🇬🇭",
    dial: "+233",
    currency: "GHS",
    capital: "Accra",
    continent: "AF",
    traditional_cn: "迦納",
    simplified_cn: "加纳"
  },
  {
    iso2: "GI",
    name: "Gibraltar",
    iso3: "GIB",
    unicode: "🇬🇮",
    dial: "+350",
    currency: "GIP",
    capital: "Gibraltar",
    continent: "EU",
    traditional_cn: "直布羅陀",
    simplified_cn: "直布罗陀"
  },
  {
    iso2: "GR",
    name: "Greece",
    iso3: "GRC",
    unicode: "🇬🇷",
    dial: "+30",
    currency: "EUR",
    capital: "Athens",
    continent: "EU",
    traditional_cn: "希臘",
    simplified_cn: "希腊"
  },
  {
    iso2: "GL",
    name: "Greenland",
    iso3: "GRL",
    unicode: "🇬🇱",
    dial: "+299",
    currency: "DKK",
    capital: "Nuuk",
    continent: "NA",
    traditional_cn: "格陵蘭",
    simplified_cn: "格陵兰"
  },
  {
    iso2: "GD",
    name: "Grenada",
    iso3: "GRD",
    unicode: "🇬🇩",
    dial: "+1-473",
    currency: "XCD",
    capital: "St. George's",
    continent: "NA",
    traditional_cn: "格瑞那達",
    simplified_cn: "格林纳达"
  },
  {
    iso2: "GP",
    name: "Guadeloupe",
    iso3: "GLP",
    unicode: "🇬🇵",
    dial: "+590",
    currency: "EUR",
    capital: "Basse-Terre",
    continent: "NA",
    traditional_cn: "瓜德羅普",
    simplified_cn: "瓜德罗普"
  },
  {
    iso2: "GU",
    name: "Guam",
    iso3: "GUM",
    unicode: "🇬🇺",
    dial: "+1-671",
    currency: "USD",
    capital: "Hagatna",
    continent: "OC",
    traditional_cn: "關島",
    simplified_cn: "关岛"
  },
  {
    iso2: "GT",
    name: "Guatemala",
    iso3: "GTM",
    unicode: "🇬🇹",
    dial: "+502",
    currency: "GTQ",
    capital: "Guatemala City",
    continent: "NA",
    traditional_cn: "瓜地馬拉",
    simplified_cn: "危地马拉"
  },
  {
    iso2: "GG",
    name: "Guernsey",
    iso3: "GGY",
    unicode: "🇬🇬",
    dial: "+44-1481",
    currency: "GBP",
    capital: "St Peter Port",
    continent: "EU",
    traditional_cn: "根西",
    simplified_cn: "根西"
  },
  {
    iso2: "GN",
    name: "Guinea",
    iso3: "GIN",
    unicode: "🇬🇳",
    dial: "+224",
    currency: "GNF",
    capital: "Conakry",
    continent: "AF",
    traditional_cn: "幾內亞",
    simplified_cn: "几内亚"
  },
  {
    iso2: "GW",
    name: "Guinea-Bissau",
    iso3: "GNB",
    unicode: "🇬🇼",
    dial: "+245",
    currency: "XOF",
    capital: "Bissau",
    continent: "AF",
    traditional_cn: "幾內亞比索",
    simplified_cn: "几内亚比绍"
  },
  {
    iso2: "GY",
    name: "Guyana",
    iso3: "GUY",
    unicode: "🇬🇾",
    dial: "+592",
    currency: "GYD",
    capital: "Georgetown",
    continent: "SA",
    traditional_cn: "蓋亞那",
    simplified_cn: "圭亚那"
  },
  {
    iso2: "HT",
    name: "Haiti",
    iso3: "HTI",
    unicode: "🇭🇹",
    dial: "+509",
    currency: "HTG",
    capital: "Port-au-Prince",
    continent: "NA",
    traditional_cn: "海地",
    simplified_cn: "海地"
  },
  {
    iso2: "HM",
    name: "Heard Island and McDonald Islands",
    iso3: "HMD",
    unicode: "🇭🇲",
    dial: "+672",
    currency: "AUD",
    capital: "",
    continent: "AN",
    traditional_cn: "赫德島和麥克唐納群島",
    simplified_cn: "赫德岛和麦克唐纳群岛"
  },
  {
    iso2: "HN",
    name: "Honduras",
    iso3: "HND",
    unicode: "🇭🇳",
    dial: "+504",
    currency: "HNL",
    capital: "Tegucigalpa",
    continent: "NA",
    traditional_cn: "宏都拉斯",
    simplified_cn: "洪都拉斯"
  },

  {
    iso2: "HU",
    name: "Hungary",
    iso3: "HUN",
    unicode: "🇭🇺",
    dial: "+36",
    currency: "HUF",
    capital: "Budapest",
    continent: "EU",
    traditional_cn: "匈牙利",
    simplified_cn: "匈牙利"
  },
  {
    iso2: "IS",
    name: "Iceland",
    iso3: "ISL",
    unicode: "🇮🇸",
    dial: "+354",
    currency: "ISK",
    capital: "Reykjavik",
    continent: "EU",
    traditional_cn: "冰島",
    simplified_cn: "冰岛"
  },
  {
    iso2: "IN",
    name: "India",
    iso3: "IND",
    unicode: "🇮🇳",
    dial: "+91",
    currency: "INR",
    capital: "New Delhi",
    continent: "AS",
    traditional_cn: "印度",
    simplified_cn: "印度"
  },
  {
    iso2: "ID",
    name: "Indonesia",
    iso3: "IDN",
    unicode: "🇮🇩",
    dial: "+62",
    currency: "IDR",
    capital: "Jakarta",
    continent: "AS",
    traditional_cn: "印尼",
    simplified_cn: "印尼"
  },
  {
    iso2: "IR",
    name: "Iran",
    iso3: "IRN",
    unicode: "🇮🇷",
    dial: "+98",
    currency: "IRR",
    capital: "Tehran",
    continent: "AS",
    traditional_cn: "伊朗",
    simplified_cn: "伊朗"
  },
  {
    iso2: "IQ",
    name: "Iraq",
    iso3: "IRQ",
    unicode: "🇮🇶",
    dial: "+964",
    currency: "IQD",
    capital: "Baghdad",
    continent: "AS",
    traditional_cn: "伊拉克",
    simplified_cn: "伊拉克"
  },
  {
    iso2: "IE",
    name: "Ireland",
    iso3: "IRL",
    unicode: "🇮🇪",
    dial: "+353",
    currency: "EUR",
    capital: "Dublin",
    continent: "EU",
    traditional_cn: "愛爾蘭",
    simplified_cn: "爱尔兰"
  },
  {
    iso2: "IM",
    name: "Isle of Man",
    iso3: "IMN",
    unicode: "🇮🇲",
    dial: "+44-1624",
    currency: "GBP",
    capital: "Douglas, Isle of Man",
    continent: "EU",
    traditional_cn: "馬恩島",
    simplified_cn: "马恩岛"
  },
  {
    iso2: "IL",
    name: "Israel",
    iso3: "ISR",
    unicode: "🇮🇱",
    dial: "+972",
    currency: "ILS",
    capital: "Jerusalem",
    continent: "AS",
    traditional_cn: "以色列",
    simplified_cn: "以色列"
  },
  {
    iso2: "IT",
    name: "Italy",
    iso3: "ITA",
    unicode: "🇮🇹",
    dial: "+39",
    currency: "EUR",
    capital: "Rome",
    continent: "EU",
    traditional_cn: "義大利",
    simplified_cn: "意大利"
  },
  {
    iso2: "CI",
    name: "Ivory Coast",
    iso3: "CIV",
    unicode: "🇨🇮",
    dial: "+225",
    currency: "XOF",
    capital: "Yamoussoukro",
    continent: "AF",
    traditional_cn: "象牙海岸\t",
    simplified_cn: "科特迪瓦"
  },
  {
    iso2: "JM",
    name: "Jamaica",
    iso3: "JAM",
    unicode: "🇯🇲",
    dial: "+1-876",
    currency: "JMD",
    capital: "Kingston",
    continent: "NA",
    traditional_cn: "牙買加",
    simplified_cn: "牙买加"
  },
  {
    iso2: "JP",
    name: "Japan",
    iso3: "JPN",
    unicode: "🇯🇵",
    dial: "+81",
    currency: "JPY",
    capital: "Tokyo",
    continent: "AS",
    traditional_cn: "日本",
    simplified_cn: "日本"
  },
  {
    iso2: "JE",
    name: "Jersey",
    iso3: "JEY",
    unicode: "🇯🇪",
    dial: "+44-1534",
    currency: "GBP",
    capital: "Saint Helier",
    continent: "EU",
    traditional_cn: "澤西",
    simplified_cn: "泽西"
  },
  {
    iso2: "JO",
    name: "Jordan",
    iso3: "JOR",
    unicode: "🇯🇴",
    dial: "+962",
    currency: "JOD",
    capital: "Amman",
    continent: "AS",
    traditional_cn: "約旦",
    simplified_cn: "约旦"
  },
  {
    iso2: "KZ",
    name: "Kazakhstan",
    iso3: "KAZ",
    unicode: "🇰🇿",
    dial: "+7",
    currency: "KZT",
    capital: "Astana",
    continent: "AS",
    traditional_cn: "哈薩克",
    simplified_cn: "哈萨克斯坦"
  },
  {
    iso2: "KE",
    name: "Kenya",
    iso3: "KEN",
    unicode: "🇰🇪",
    dial: "+254",
    currency: "KES",
    capital: "Nairobi",
    continent: "AF",
    traditional_cn: "肯亞",
    simplified_cn: "肯尼亚"
  },
  {
    iso2: "KI",
    name: "Kiribati",
    iso3: "KIR",
    unicode: "🇰🇮",
    dial: "+686",
    currency: "AUD",
    capital: "Tarawa",
    continent: "OC",
    traditional_cn: "吉里巴斯",
    simplified_cn: "基里巴斯"
  },
  {
    iso2: "XK",
    name: "Kosovo",
    iso3: "XKX",
    unicode: "🇽🇰",
    dial: "+383",
    currency: "EUR",
    capital: "Pristina",
    continent: "EU",
    traditional_cn: "科索沃",
    simplified_cn: "科索沃"
  },
  {
    iso2: "KW",
    name: "Kuwait",
    iso3: "KWT",
    unicode: "🇰🇼",
    dial: "+965",
    currency: "KWD",
    capital: "Kuwait City",
    continent: "AS",
    traditional_cn: "科威特",
    simplified_cn: "科威特"
  },
  {
    iso2: "KG",
    name: "Kyrgyzstan",
    iso3: "KGZ",
    unicode: "🇰🇬",
    dial: "+996",
    currency: "KGS",
    capital: "Bishkek",
    continent: "AS",
    traditional_cn: "吉爾吉斯",
    simplified_cn: "吉尔吉斯斯坦"
  },
  {
    iso2: "LA",
    name: "Laos",
    iso3: "LAO",
    unicode: "🇱🇦",
    dial: "+856",
    currency: "LAK",
    capital: "Vientiane",
    continent: "AS",
    traditional_cn: "寮国",
    simplified_cn: "老挝"
  },
  {
    iso2: "LV",
    name: "Latvia",
    iso3: "LVA",
    unicode: "🇱🇻",
    dial: "+371",
    currency: "EUR",
    capital: "Riga",
    continent: "EU",
    traditional_cn: "拉脫維亞",
    simplified_cn: "拉脱维亚"
  },
  {
    iso2: "LB",
    name: "Lebanon",
    iso3: "LBN",
    unicode: "🇱🇧",
    dial: "+961",
    currency: "LBP",
    capital: "Beirut",
    continent: "AS",
    traditional_cn: "黎巴嫩",
    simplified_cn: "黎巴嫩"
  },
  {
    iso2: "LS",
    name: "Lesotho",
    iso3: "LSO",
    unicode: "🇱🇸",
    dial: "+266",
    currency: "LSL",
    capital: "Maseru",
    continent: "AF",
    traditional_cn: "賴索托",
    simplified_cn: "莱索托"
  },
  {
    iso2: "LR",
    name: "Liberia",
    iso3: "LBR",
    unicode: "🇱🇷",
    dial: "+231",
    currency: "LRD",
    capital: "Monrovia",
    continent: "AF",
    traditional_cn: "賴比瑞亞",
    simplified_cn: "利比里亚"
  },
  {
    iso2: "LY",
    name: "Libya",
    iso3: "LBY",
    unicode: "🇱🇾",
    dial: "+218",
    currency: "LYD",
    capital: "Tripolis",
    continent: "AF",
    traditional_cn: "利比亞",
    simplified_cn: "利比亚"
  },
  {
    iso2: "LI",
    name: "Liechtenstein",
    iso3: "LIE",
    unicode: "🇱🇮",
    dial: "+423",
    currency: "CHF",
    capital: "Vaduz",
    continent: "EU",
    traditional_cn: "列支敦斯登",
    simplified_cn: "列支敦士登"
  },
  {
    iso2: "LT",
    name: "Lithuania",
    iso3: "LTU",
    unicode: "🇱🇹",
    dial: "+370",
    currency: "LTL",
    capital: "Vilnius",
    continent: "EU",
    traditional_cn: "立陶宛",
    simplified_cn: "立陶宛"
  },
  {
    iso2: "LU",
    name: "Luxembourg",
    iso3: "LUX",
    unicode: "🇱🇺",
    dial: "+352",
    currency: "EUR",
    capital: "Luxembourg",
    continent: "EU",
    traditional_cn: "盧森堡",
    simplified_cn: "卢森堡"
  },
  {
    iso2: "MO",
    name: "Macao",
    iso3: "MAC",
    unicode: "🇲🇴",
    dial: "+853",
    currency: "MOP",
    capital: "Macao",
    continent: "AS",
    traditional_cn: "澳門",
    simplified_cn: "澳门"
  },
  {
    iso2: "MK",
    name: "Macedonia",
    iso3: "MKD",
    unicode: "🇲🇰",
    dial: "+389",
    currency: "MKD",
    capital: "Skopje",
    continent: "EU",
    traditional_cn: "北馬其頓",
    simplified_cn: "北马其顿"
  },
  {
    iso2: "MG",
    name: "Madagascar",
    iso3: "MDG",
    unicode: "🇲🇬",
    dial: "+261",
    currency: "MGA",
    capital: "Antananarivo",
    continent: "AF",
    traditional_cn: "馬達加斯加",
    simplified_cn: "马达加斯加"
  },
  {
    iso2: "MW",
    name: "Malawi",
    iso3: "MWI",
    unicode: "🇲🇼",
    dial: "+265",
    currency: "MWK",
    capital: "Lilongwe",
    continent: "AF",
    traditional_cn: "馬拉威",
    simplified_cn: "马拉维"
  },
  {
    iso2: "MY",
    name: "Malaysia",
    iso3: "MYS",
    unicode: "🇲🇾",
    dial: "+60",
    currency: "MYR",
    capital: "Kuala Lumpur",
    continent: "AS",
    traditional_cn: "馬來西亞",
    simplified_cn: "马来西亚"
  },
  {
    iso2: "MV",
    name: "Maldives",
    iso3: "MDV",
    unicode: "🇲🇻",
    dial: "+960",
    currency: "MVR",
    capital: "Male",
    continent: "AS",
    traditional_cn: "馬爾地夫",
    simplified_cn: "马尔代夫"
  },
  {
    iso2: "ML",
    name: "Mali",
    iso3: "MLI",
    unicode: "🇲🇱",
    dial: "+223",
    currency: "XOF",
    capital: "Bamako",
    continent: "AF",
    traditional_cn: "馬利",
    simplified_cn: "马里"
  },
  {
    iso2: "MT",
    name: "Malta",
    iso3: "MLT",
    unicode: "🇲🇹",
    dial: "+356",
    currency: "EUR",
    capital: "Valletta",
    continent: "EU",
    traditional_cn: "馬爾他",
    simplified_cn: "马耳他"
  },
  {
    iso2: "MH",
    name: "Marshall Islands",
    iso3: "MHL",
    unicode: "🇲🇭",
    dial: "+692",
    currency: "USD",
    capital: "Majuro",
    continent: "OC",
    traditional_cn: "馬紹爾群島",
    simplified_cn: "马绍尔群岛"
  },
  {
    iso2: "MQ",
    name: "Martinique",
    iso3: "MTQ",
    unicode: "🇲🇶",
    dial: "+596",
    currency: "EUR",
    capital: "Fort-de-France",
    continent: "NA",
    traditional_cn: "馬提尼克",
    simplified_cn: "马提尼克"
  },
  {
    iso2: "MR",
    name: "Mauritania",
    iso3: "MRT",
    unicode: "🇲🇷",
    dial: "+222",
    currency: "MRO",
    capital: "Nouakchott",
    continent: "AF",
    traditional_cn: "茅利塔尼亞",
    simplified_cn: "毛里塔尼亚"
  },
  {
    iso2: "MU",
    name: "Mauritius",
    iso3: "MUS",
    unicode: "🇲🇺",
    dial: "+230",
    currency: "MUR",
    capital: "Port Louis",
    continent: "AF",
    traditional_cn: "模里西斯",
    simplified_cn: "毛里求斯"
  },
  {
    iso2: "YT",
    name: "Mayotte",
    iso3: "MYT",
    unicode: "🇾🇹",
    dial: "+262",
    currency: "EUR",
    capital: "Mamoudzou",
    continent: "AF",
    traditional_cn: "馬約特",
    simplified_cn: "马约特"
  },
  {
    iso2: "MX",
    name: "Mexico",
    iso3: "MEX",
    unicode: "🇲🇽",
    dial: "+52",
    currency: "MXN",
    capital: "Mexico City",
    continent: "NA",
    traditional_cn: "墨西哥",
    simplified_cn: "墨西哥"
  },
  {
    iso2: "FM",
    name: "Micronesia",
    iso3: "FSM",
    unicode: "🇫🇲",
    dial: "+691",
    currency: "USD",
    capital: "Palikir",
    continent: "OC",
    traditional_cn: "密克羅尼西亞聯邦",
    simplified_cn: "密克罗尼西亚联邦"
  },
  {
    iso2: "MD",
    name: "Moldova",
    iso3: "MDA",
    unicode: "🇲🇩",
    dial: "+373",
    currency: "MDL",
    capital: "Chisinau",
    continent: "EU",
    traditional_cn: "摩爾多瓦",
    simplified_cn: "摩尔多瓦"
  },
  {
    iso2: "MC",
    name: "Monaco",
    iso3: "MCO",
    unicode: "🇲🇨",
    dial: "+377",
    currency: "EUR",
    capital: "Monaco",
    continent: "EU",
    traditional_cn: "摩納哥",
    simplified_cn: "摩纳哥"
  },
  {
    iso2: "MN",
    name: "Mongolia",
    iso3: "MNG",
    unicode: "🇲🇳",
    dial: "+976",
    currency: "MNT",
    capital: "Ulan Bator",
    continent: "AS",
    traditional_cn: "蒙古國",
    simplified_cn: "蒙古国"
  },
  {
    iso2: "ME",
    name: "Montenegro",
    iso3: "MNE",
    unicode: "🇲🇪",
    dial: "+382",
    currency: "EUR",
    capital: "Podgorica",
    continent: "EU",
    traditional_cn: "蒙特內哥羅",
    simplified_cn: "黑山"
  },
  {
    iso2: "MS",
    name: "Montserrat",
    iso3: "MSR",
    unicode: "🇲🇸",
    dial: "+1-664",
    currency: "XCD",
    capital: "Plymouth",
    continent: "NA",
    traditional_cn: "蒙特塞拉特",
    simplified_cn: "蒙特塞拉特"
  },
  {
    iso2: "MA",
    name: "Morocco",
    iso3: "MAR",
    unicode: "🇲🇦",
    dial: "+212",
    currency: "MAD",
    capital: "Rabat",
    continent: "AF",
    traditional_cn: "摩洛哥",
    simplified_cn: "摩洛哥"
  },
  {
    iso2: "MZ",
    name: "Mozambique",
    iso3: "MOZ",
    unicode: "🇲🇿",
    dial: "+258",
    currency: "MZN",
    capital: "Maputo",
    continent: "AF",
    traditional_cn: "莫三比克",
    simplified_cn: "莫桑比克"
  },
  {
    iso2: "MM",
    name: "Myanmar",
    iso3: "MMR",
    unicode: "🇲🇲",
    dial: "+95",
    currency: "MMK",
    capital: "Nay Pyi Taw",
    continent: "AS",
    traditional_cn: "緬甸",
    simplified_cn: "缅甸"
  },
  {
    iso2: "NA",
    name: "Namibia",
    iso3: "NAM",
    unicode: "🇳🇦",
    dial: "+264",
    currency: "NAD",
    capital: "Windhoek",
    continent: "AF",
    traditional_cn: "納米比亞",
    simplified_cn: "纳米比亚"
  },
  {
    iso2: "NR",
    name: "Nauru",
    iso3: "NRU",
    unicode: "🇳🇷",
    dial: "+674",
    currency: "AUD",
    capital: "Yaren",
    continent: "OC",
    traditional_cn: "諾魯",
    simplified_cn: "瑙鲁"
  },
  {
    iso2: "NP",
    name: "Nepal",
    iso3: "NPL",
    unicode: "🇳🇵",
    dial: "+977",
    currency: "NPR",
    capital: "Kathmandu",
    continent: "AS",
    traditional_cn: "尼泊爾",
    simplified_cn: "尼泊尔"
  },
  {
    iso2: "NL",
    name: "Netherlands",
    iso3: "NLD",
    unicode: "🇳🇱",
    dial: "+31",
    currency: "EUR",
    capital: "Amsterdam",
    continent: "EU",
    traditional_cn: "荷蘭",
    simplified_cn: "荷兰"
  },
  {
    iso2: "NC",
    name: "New Caledonia",
    iso3: "NCL",
    unicode: "🇳🇨",
    dial: "+687",
    currency: "XPF",
    capital: "Noumea",
    continent: "OC",
    traditional_cn: "新喀裡多尼亞",
    simplified_cn: "新喀里多尼亚"
  },
  {
    iso2: "NZ",
    name: "New Zealand",
    iso3: "NZL",
    unicode: "🇳🇿",
    dial: "+64",
    currency: "NZD",
    capital: "Wellington",
    continent: "OC",
    traditional_cn: "紐西蘭",
    simplified_cn: "新西兰"
  },
  {
    iso2: "NI",
    name: "Nicaragua",
    iso3: "NIC",
    unicode: "🇳🇮",
    dial: "+505",
    currency: "NIO",
    capital: "Managua",
    continent: "NA",
    traditional_cn: "尼加拉瓜",
    simplified_cn: "尼加拉瓜"
  },
  {
    iso2: "NE",
    name: "Niger",
    iso3: "NER",
    unicode: "🇳🇪",
    dial: "+227",
    currency: "XOF",
    capital: "Niamey",
    continent: "AF",
    traditional_cn: "尼日",
    simplified_cn: "尼日尔"
  },
  {
    iso2: "NG",
    name: "Nigeria",
    iso3: "NGA",
    unicode: "🇳🇬",
    dial: "+234",
    currency: "NGN",
    capital: "Abuja",
    continent: "AF",
    traditional_cn: "奈及利亞",
    simplified_cn: "尼日利亚"
  },
  {
    iso2: "NU",
    name: "Niue",
    iso3: "NIU",
    unicode: "🇳🇺",
    dial: "+683",
    currency: "NZD",
    capital: "Alofi",
    continent: "OC",
    traditional_cn: "紐埃",
    simplified_cn: "纽埃"
  },
  {
    iso2: "NF",
    name: "Norfolk Island",
    iso3: "NFK",
    unicode: "🇳🇫",
    dial: "+672",
    currency: "AUD",
    capital: "Kingston",
    continent: "OC",
    traditional_cn: "諾福克島",
    simplified_cn: "诺福克岛"
  },
  {
    iso2: "KP",
    name: "North Korea",
    iso3: "PRK",
    unicode: "🇰🇵",
    dial: "+850",
    currency: "KPW",
    capital: "Pyongyang",
    continent: "AS",
    traditional_cn: "北韓",
    simplified_cn: "朝鲜"
  },
  {
    iso2: "MP",
    name: "Northern Mariana Islands",
    iso3: "MNP",
    unicode: "🇲🇵",
    dial: "+1-670",
    currency: "USD",
    capital: "Saipan",
    continent: "OC",
    traditional_cn: "北馬裡亞納群島",
    simplified_cn: "北马里亚纳群岛"
  },
  {
    iso2: "NO",
    name: "Norway",
    iso3: "NOR",
    unicode: "🇳🇴",
    dial: "+47",
    currency: "NOK",
    capital: "Oslo",
    continent: "EU",
    traditional_cn: "挪威",
    simplified_cn: "挪威"
  },
  {
    iso2: "OM",
    name: "Oman",
    iso3: "OMN",
    unicode: "🇴🇲",
    dial: "+968",
    currency: "OMR",
    capital: "Muscat",
    continent: "AS",
    traditional_cn: "阿曼",
    simplified_cn: "阿曼"
  },
  {
    iso2: "PK",
    name: "Pakistan",
    iso3: "PAK",
    unicode: "🇵🇰",
    dial: "+92",
    currency: "PKR",
    capital: "Islamabad",
    continent: "AS",
    traditional_cn: "巴基斯坦",
    simplified_cn: "巴基斯坦"
  },
  {
    iso2: "PW",
    name: "Palau",
    iso3: "PLW",
    unicode: "🇵🇼",
    dial: "+680",
    currency: "USD",
    capital: "Melekeok",
    continent: "OC",
    traditional_cn: "帛琉",
    simplified_cn: "帕劳"
  },
  {
    iso2: "PS",
    name: "Palestinian Territory",
    iso3: "PSE",
    unicode: "🇵🇸",
    dial: "+970",
    currency: "ILS",
    capital: "East Jerusalem",
    continent: "AS",
    traditional_cn: "巴勒斯坦",
    simplified_cn: "巴勒斯坦"
  },
  {
    iso2: "PA",
    name: "Panama",
    iso3: "PAN",
    unicode: "🇵🇦",
    dial: "+507",
    currency: "PAB",
    capital: "Panama City",
    continent: "NA",
    traditional_cn: "巴拿馬",
    simplified_cn: "巴拿马"
  },
  {
    iso2: "PG",
    name: "Papua New Guinea",
    iso3: "PNG",
    unicode: "🇵🇬",
    dial: "+675",
    currency: "PGK",
    capital: "Port Moresby",
    continent: "OC",
    traditional_cn: "巴布亞新幾內亞",
    simplified_cn: "巴布亚新几内亚"
  },
  {
    iso2: "PY",
    name: "Paraguay",
    iso3: "PRY",
    unicode: "🇵🇾",
    dial: "+595",
    currency: "PYG",
    capital: "Asuncion",
    continent: "SA",
    traditional_cn: "巴拉圭",
    simplified_cn: "巴拉圭"
  },
  {
    iso2: "PE",
    name: "Peru",
    iso3: "PER",
    unicode: "🇵🇪",
    dial: "+51",
    currency: "PEN",
    capital: "Lima",
    continent: "SA",
    traditional_cn: "秘魯",
    simplified_cn: "秘鲁"
  },
  {
    iso2: "PH",
    name: "Philippines",
    iso3: "PHL",
    unicode: "🇵🇭",
    dial: "+63",
    currency: "PHP",
    capital: "Manila",
    continent: "AS",
    traditional_cn: "菲律賓",
    simplified_cn: "菲律宾"
  },
  {
    iso2: "PN",
    name: "Pitcairn",
    iso3: "PCN",
    unicode: "🇵🇳",
    dial: "+870",
    currency: "NZD",
    capital: "Adamstown",
    continent: "OC",
    traditional_cn: "皮特凱恩群島",
    simplified_cn: "皮特凯恩群岛"
  },
  {
    iso2: "PL",
    name: "Poland",
    iso3: "POL",
    unicode: "🇵🇱",
    dial: "+48",
    currency: "PLN",
    capital: "Warsaw",
    continent: "EU",
    traditional_cn: "波蘭",
    simplified_cn: "波兰"
  },
  {
    iso2: "PT",
    name: "Portugal",
    iso3: "PRT",
    unicode: "🇵🇹",
    dial: "+351",
    currency: "EUR",
    capital: "Lisbon",
    continent: "EU",
    traditional_cn: "葡萄牙",
    simplified_cn: "葡萄牙"
  },
  {
    iso2: "PR",
    name: "Puerto Rico",
    iso3: "PRI",
    unicode: "🇵🇷",
    dial: "+1-787 and 1-939",
    currency: "USD",
    capital: "San Juan",
    continent: "NA",
    traditional_cn: "波多黎各",
    simplified_cn: "波多黎各"
  },
  {
    iso2: "QA",
    name: "Qatar",
    iso3: "QAT",
    unicode: "🇶🇦",
    dial: "+974",
    currency: "QAR",
    capital: "Doha",
    continent: "AS",
    traditional_cn: "卡達",
    simplified_cn: "卡塔尔"
  },
  {
    iso2: "CG",
    name: "Republic of the Congo",
    iso3: "COG",
    unicode: "🇨🇬",
    dial: "+242",
    currency: "XAF",
    capital: "Brazzaville",
    continent: "AF",
    traditional_cn: "剛果共和國\t",
    simplified_cn: "刚果（布）"
  },
  {
    iso2: "RE",
    name: "Reunion",
    iso3: "REU",
    unicode: "🇷🇪",
    dial: "+262",
    currency: "EUR",
    capital: "Saint-Denis",
    continent: "AF",
    traditional_cn: "留尼汪",
    simplified_cn: "留尼汪"
  },
  {
    iso2: "RO",
    name: "Romania",
    iso3: "ROU",
    unicode: "🇷🇴",
    dial: "+40",
    currency: "RON",
    capital: "Bucharest",
    continent: "EU",
    traditional_cn: "羅馬尼亞",
    simplified_cn: "罗马尼亚"
  },
  {
    iso2: "RU",
    name: "Russia",
    iso3: "RUS",
    unicode: "🇷🇺",
    dial: "+7",
    currency: "RUB",
    capital: "Moscow",
    continent: "EU",
    traditional_cn: "俄羅斯",
    simplified_cn: "俄罗斯"
  },
  {
    iso2: "RW",
    name: "Rwanda",
    iso3: "RWA",
    unicode: "🇷🇼",
    dial: "+250",
    currency: "RWF",
    capital: "Kigali",
    continent: "AF",
    traditional_cn: "盧安達",
    simplified_cn: "卢旺达"
  },
  {
    iso2: "BL",
    name: "Saint Barthelemy",
    iso3: "BLM",
    unicode: "🇧🇱",
    dial: "+590",
    currency: "EUR",
    capital: "Gustavia",
    continent: "NA",
    traditional_cn: "聖巴泰勒米",
    simplified_cn: "圣巴泰勒米"
  },
  {
    iso2: "SH",
    name: "Saint Helena",
    iso3: "SHN",
    unicode: "🇸🇭",
    dial: "+290",
    currency: "SHP",
    capital: "Jamestown",
    continent: "AF",
    traditional_cn: "聖赫勒拿、阿森松和特里斯坦-達庫尼亞",
    simplified_cn: "圣赫勒拿、阿森松和特里斯坦-达库尼亚"
  },
  {
    iso2: "KN",
    name: "Saint Kitts and Nevis",
    iso3: "KNA",
    unicode: "🇰🇳",
    dial: "+1-869",
    currency: "XCD",
    capital: "Basseterre",
    continent: "NA",
    traditional_cn: "聖克里斯多福及尼維斯",
    simplified_cn: "圣基茨和尼维斯"
  },
  {
    iso2: "LC",
    name: "Saint Lucia",
    iso3: "LCA",
    unicode: "🇱🇨",
    dial: "+1-758",
    currency: "XCD",
    capital: "Castries",
    continent: "NA",
    traditional_cn: "聖露西亞",
    simplified_cn: "圣卢西亚"
  },
  {
    iso2: "MF",
    name: "Saint Martin",
    iso3: "MAF",
    unicode: "🇲🇫",
    dial: "+590",
    currency: "EUR",
    capital: "Marigot",
    continent: "NA",
    traditional_cn: "法屬聖馬丁",
    simplified_cn: "法属圣马丁"
  },
  {
    iso2: "PM",
    name: "Saint Pierre and Miquelon",
    iso3: "SPM",
    unicode: "🇵🇲",
    dial: "+508",
    currency: "EUR",
    capital: "Saint-Pierre",
    continent: "NA",
    traditional_cn: "聖皮埃爾和密克隆",
    simplified_cn: "圣皮埃尔和密克隆"
  },
  {
    iso2: "VC",
    name: "Saint Vincent and the Grenadines",
    iso3: "VCT",
    unicode: "🇻🇨",
    dial: "+1-784",
    currency: "XCD",
    capital: "Kingstown",
    continent: "NA",
    traditional_cn: "聖文森及格瑞那丁",
    simplified_cn: "圣文森特和格林纳丁斯"
  },
  {
    iso2: "WS",
    name: "Samoa",
    iso3: "WSM",
    unicode: "🇼🇸",
    dial: "+685",
    currency: "WST",
    capital: "Apia",
    continent: "OC",
    traditional_cn: "薩摩亞",
    simplified_cn: "萨摩亚"
  },
  {
    iso2: "SM",
    name: "San Marino",
    iso3: "SMR",
    unicode: "🇸🇲",
    dial: "+378",
    currency: "EUR",
    capital: "San Marino",
    continent: "EU",
    traditional_cn: "聖馬利諾",
    simplified_cn: "圣马力诺"
  },
  {
    iso2: "ST",
    name: "Sao Tome and Principe",
    iso3: "STP",
    unicode: "🇸🇹",
    dial: "+239",
    currency: "STD",
    capital: "Sao Tome",
    continent: "AF",
    traditional_cn: "聖多美普林西比",
    simplified_cn: "圣多美和普林西比"
  },
  {
    iso2: "SA",
    name: "Saudi Arabia",
    iso3: "SAU",
    unicode: "🇸🇦",
    dial: "+966",
    currency: "SAR",
    capital: "Riyadh",
    continent: "AS",
    traditional_cn: "沙烏地阿拉伯",
    simplified_cn: "沙特阿拉伯"
  },
  {
    iso2: "SN",
    name: "Senegal",
    iso3: "SEN",
    unicode: "🇸🇳",
    dial: "+221",
    currency: "XOF",
    capital: "Dakar",
    continent: "AF",
    traditional_cn: "塞內加爾",
    simplified_cn: "塞内加尔"
  },
  {
    iso2: "RS",
    name: "Serbia",
    iso3: "SRB",
    unicode: "🇷🇸",
    dial: "+381",
    currency: "RSD",
    capital: "Belgrade",
    continent: "EU",
    traditional_cn: "塞爾維亞",
    simplified_cn: "塞尔维亚"
  },
  {
    iso2: "SC",
    name: "Seychelles",
    iso3: "SYC",
    unicode: "🇸🇨",
    dial: "+248",
    currency: "SCR",
    capital: "Victoria",
    continent: "AF",
    traditional_cn: "塞席爾",
    simplified_cn: "塞舌尔"
  },
  {
    iso2: "SL",
    name: "Sierra Leone",
    iso3: "SLE",
    unicode: "🇸🇱",
    dial: "+232",
    currency: "SLL",
    capital: "Freetown",
    continent: "AF",
    traditional_cn: "獅子山",
    simplified_cn: "塞拉利昂"
  },
  {
    iso2: "SG",
    name: "Singapore",
    iso3: "SGP",
    unicode: "🇸🇬",
    dial: "+65",
    currency: "SGD",
    capital: "Singapur",
    continent: "AS",
    traditional_cn: "新加坡",
    simplified_cn: "新加坡"
  },
  {
    iso2: "SX",
    name: "Sint Maarten",
    iso3: "SXM",
    unicode: "🇸🇽",
    dial: "+599",
    currency: "ANG",
    capital: "Philipsburg",
    continent: "NA",
    traditional_cn: "聖馬丁",
    simplified_cn: "圣马丁"
  },
  {
    iso2: "SK",
    name: "Slovakia",
    iso3: "SVK",
    unicode: "🇸🇰",
    dial: "+421",
    currency: "EUR",
    capital: "Bratislava",
    continent: "EU",
    traditional_cn: "斯洛伐克",
    simplified_cn: "斯洛伐克"
  },
  {
    iso2: "SI",
    name: "Slovenia",
    iso3: "SVN",
    unicode: "🇸🇮",
    dial: "+386",
    currency: "EUR",
    capital: "Ljubljana",
    continent: "EU",
    traditional_cn: "斯洛維尼亞",
    simplified_cn: "斯洛文尼亚"
  },
  {
    iso2: "SB",
    name: "Solomon Islands",
    iso3: "SLB",
    unicode: "🇸🇧",
    dial: "+677",
    currency: "SBD",
    capital: "Honiara",
    continent: "OC",
    traditional_cn: "索羅門群島",
    simplified_cn: "所罗门群岛"
  },
  {
    iso2: "SO",
    name: "Somalia",
    iso3: "SOM",
    unicode: "🇸🇴",
    dial: "+252",
    currency: "SOS",
    capital: "Mogadishu",
    continent: "AF",
    traditional_cn: "索馬利亞",
    simplified_cn: "索马里"
  },
  {
    iso2: "ZA",
    name: "South Africa",
    iso3: "ZAF",
    unicode: "🇿🇦",
    dial: "+27",
    currency: "ZAR",
    capital: "Pretoria",
    continent: "AF",
    traditional_cn: "南非",
    simplified_cn: "南非"
  },
  {
    iso2: "GS",
    name: "South Georgia and the South Sandwich Islands",
    iso3: "SGS",
    unicode: "🇬🇸",
    dial: "+500",
    currency: "GBP",
    capital: "Grytviken",
    continent: "AN",
    traditional_cn: "南喬治亞和南桑威奇群島",
    simplified_cn: "南乔治亚和南桑威奇群岛"
  },
  {
    iso2: "KR",
    name: "South Korea",
    iso3: "KOR",
    unicode: "🇰🇷",
    dial: "+82",
    currency: "KRW",
    capital: "Seoul",
    continent: "AS",
    traditional_cn: "南韓",
    simplified_cn: "韩国"
  },
  {
    iso2: "SS",
    name: "South Sudan",
    iso3: "SSD",
    unicode: "🇸🇸",
    dial: "+211",
    currency: "SSP",
    capital: "Juba",
    continent: "AF",
    traditional_cn: "南蘇丹",
    simplified_cn: "南苏丹"
  },
  {
    iso2: "ES",
    name: "Spain",
    iso3: "ESP",
    unicode: "🇪🇸",
    dial: "+34",
    currency: "EUR",
    capital: "Madrid",
    continent: "EU",
    traditional_cn: "西班牙",
    simplified_cn: "西班牙"
  },
  {
    iso2: "LK",
    name: "Sri Lanka",
    iso3: "LKA",
    unicode: "🇱🇰",
    dial: "+94",
    currency: "LKR",
    capital: "Colombo",
    continent: "AS",
    traditional_cn: "斯里蘭卡",
    simplified_cn: "斯里兰卡"
  },
  {
    iso2: "SD",
    name: "Sudan",
    iso3: "SDN",
    unicode: "🇸🇩",
    dial: "+249",
    currency: "SDG",
    capital: "Khartoum",
    continent: "AF",
    traditional_cn: "蘇丹",
    simplified_cn: "苏丹"
  },
  {
    iso2: "SR",
    name: "Suriname",
    iso3: "SUR",
    unicode: "🇸🇷",
    dial: "+597",
    currency: "SRD",
    capital: "Paramaribo",
    continent: "SA",
    traditional_cn: "蘇利南",
    simplified_cn: "苏里南"
  },
  {
    iso2: "SJ",
    name: "Svalbard and Jan Mayen",
    iso3: "SJM",
    unicode: "🇸🇯",
    dial: "+47",
    currency: "NOK",
    capital: "Longyearbyen",
    continent: "EU",
    traditional_cn: "斯瓦爾巴和揚馬延",
    simplified_cn: "斯瓦尔巴和扬马延"
  },
  {
    iso2: "SZ",
    name: "Swaziland",
    iso3: "SWZ",
    unicode: "🇸🇿",
    dial: "+268",
    currency: "SZL",
    capital: "Mbabane",
    continent: "AF",
    traditional_cn: "史瓦帝尼",
    simplified_cn: "斯威士兰"
  },
  {
    iso2: "SE",
    name: "Sweden",
    iso3: "SWE",
    unicode: "🇸🇪",
    dial: "+46",
    currency: "SEK",
    capital: "Stockholm",
    continent: "EU",
    traditional_cn: "瑞典",
    simplified_cn: "瑞典"
  },
  {
    iso2: "CH",
    name: "Switzerland",
    iso3: "CHE",
    unicode: "🇨🇭",
    dial: "+41",
    currency: "CHF",
    capital: "Berne",
    continent: "EU",
    traditional_cn: "瑞士",
    simplified_cn: "瑞士"
  },
  {
    iso2: "SY",
    name: "Syria",
    iso3: "SYR",
    unicode: "🇸🇾",
    dial: "+963",
    currency: "SYP",
    capital: "Damascus",
    continent: "AS",
    traditional_cn: "敘利亞",
    simplified_cn: "叙利亚"
  },
  {
    iso2: "TJ",
    name: "Tajikistan",
    iso3: "TJK",
    unicode: "🇹🇯",
    dial: "+992",
    currency: "TJS",
    capital: "Dushanbe",
    continent: "AS",
    traditional_cn: "塔吉克",
    simplified_cn: "塔吉克斯坦"
  },
  {
    iso2: "TZ",
    name: "Tanzania",
    iso3: "TZA",
    unicode: "🇹🇿",
    dial: "+255",
    currency: "TZS",
    capital: "Dodoma",
    continent: "AF",
    traditional_cn: "坦尚尼亞",
    simplified_cn: "坦桑尼亚"
  },
  {
    iso2: "TH",
    name: "Thailand",
    iso3: "THA",
    unicode: "🇹🇭",
    dial: "+66",
    currency: "THB",
    capital: "Bangkok",
    continent: "AS",
    traditional_cn: "泰國",
    simplified_cn: "泰国"
  },
  {
    iso2: "TG",
    name: "Togo",
    iso3: "TGO",
    unicode: "🇹🇬",
    dial: "+228",
    currency: "XOF",
    capital: "Lome",
    continent: "AF",
    traditional_cn: "多哥",
    simplified_cn: "多哥"
  },
  {
    iso2: "TK",
    name: "Tokelau",
    iso3: "TKL",
    unicode: "🇹🇰",
    dial: "+690",
    currency: "NZD",
    capital: "",
    continent: "OC",
    traditional_cn: "托克勞",
    simplified_cn: "托克劳"
  },
  {
    iso2: "TO",
    name: "Tonga",
    iso3: "TON",
    unicode: "🇹🇴",
    dial: "+676",
    currency: "TOP",
    capital: "Nuku'alofa",
    continent: "OC",
    traditional_cn: "東加",
    simplified_cn: "汤加"
  },
  {
    iso2: "TT",
    name: "Trinidad and Tobago",
    iso3: "TTO",
    unicode: "🇹🇹",
    dial: "+1-868",
    currency: "TTD",
    capital: "Port of Spain",
    continent: "NA",
    traditional_cn: "千里達及托巴哥",
    simplified_cn: "特立尼达和多巴哥\t"
  },
  {
    iso2: "TN",
    name: "Tunisia",
    iso3: "TUN",
    unicode: "🇹🇳",
    dial: "+216",
    currency: "TND",
    capital: "Tunis",
    continent: "AF",
    traditional_cn: "突尼西亞",
    simplified_cn: "突尼斯"
  },
  {
    iso2: "TR",
    name: "Turkey",
    iso3: "TUR",
    unicode: "🇹🇷",
    dial: "+90",
    currency: "TRY",
    capital: "Ankara",
    continent: "AS",
    traditional_cn: "土耳其",
    simplified_cn: "土耳其"
  },
  {
    iso2: "TM",
    name: "Turkmenistan",
    iso3: "TKM",
    unicode: "🇹🇲",
    dial: "+993",
    currency: "TMT",
    capital: "Ashgabat",
    continent: "AS",
    traditional_cn: "土庫曼",
    simplified_cn: "土库曼斯坦"
  },
  {
    iso2: "TC",
    name: "Turks and Caicos Islands",
    iso3: "TCA",
    unicode: "🇹🇨",
    dial: "+1-649",
    currency: "USD",
    capital: "Cockburn Town",
    continent: "NA",
    traditional_cn: "特克斯和凱科斯群島",
    simplified_cn: "特克斯和凯科斯群岛"
  },
  {
    iso2: "TV",
    name: "Tuvalu",
    iso3: "TUV",
    unicode: "🇹🇻",
    dial: "+688",
    currency: "AUD",
    capital: "Funafuti",
    continent: "OC",
    traditional_cn: "吐瓦魯",
    simplified_cn: "图瓦卢"
  },
  {
    iso2: "VI",
    name: "U.S. Virgin Islands",
    iso3: "VIR",
    unicode: "🇻🇮",
    dial: "+1-340",
    currency: "USD",
    capital: "Charlotte Amalie",
    continent: "NA",
    traditional_cn: "美屬維爾京群島",
    simplified_cn: "美属维尔京群岛"
  },
  {
    iso2: "UG",
    name: "Uganda",
    iso3: "UGA",
    unicode: "🇺🇬",
    dial: "+256",
    currency: "UGX",
    capital: "Kampala",
    continent: "AF",
    traditional_cn: "烏干達",
    simplified_cn: "乌干达"
  },
  {
    iso2: "UA",
    name: "Ukraine",
    iso3: "UKR",
    unicode: "🇺🇦",
    dial: "+380",
    currency: "UAH",
    capital: "Kiev",
    continent: "EU",
    traditional_cn: "烏克蘭",
    simplified_cn: "乌克兰"
  },
  {
    iso2: "AE",
    name: "United Arab Emirates",
    iso3: "ARE",
    unicode: "🇦🇪",
    dial: "+971",
    currency: "AED",
    capital: "Abu Dhabi",
    continent: "AS",
    traditional_cn: "阿聯酋",
    simplified_cn: "阿联酋"
  },
  {
    iso2: "GB",
    name: "United Kingdom",
    iso3: "GBR",
    unicode: "🇬🇧",
    dial: "+44",
    currency: "GBP",
    capital: "London",
    continent: "EU",
    traditional_cn: "英國",
    simplified_cn: "英国"
  },
  {
    iso2: "US",
    name: "United States",
    iso3: "USA",
    unicode: "🇺🇸",
    dial: "+1",
    currency: "USD",
    capital: "Washington",
    continent: "NA",
    traditional_cn: "美國",
    simplified_cn: "美国"
  },
  {
    iso2: "UM",
    name: "United States Minor Outlying Islands",
    iso3: "UMI",
    unicode: "🇺🇲",
    dial: "+1",
    currency: "USD",
    capital: "",
    continent: "OC",
    traditional_cn: "美國本土外小島嶼",
    simplified_cn: "美国本土外小岛屿"
  },
  {
    iso2: "UY",
    name: "Uruguay",
    iso3: "URY",
    unicode: "🇺🇾",
    dial: "+598",
    currency: "UYU",
    capital: "Montevideo",
    continent: "SA",
    traditional_cn: "烏拉圭",
    simplified_cn: "乌拉圭"
  },
  {
    iso2: "UZ",
    name: "Uzbekistan",
    iso3: "UZB",
    unicode: "🇺🇿",
    dial: "+998",
    currency: "UZS",
    capital: "Tashkent",
    continent: "AS",
    traditional_cn: "烏茲別克",
    simplified_cn: "乌兹别克斯坦"
  },
  {
    iso2: "VU",
    name: "Vanuatu",
    iso3: "VUT",
    unicode: "🇻🇺",
    dial: "+678",
    currency: "VUV",
    capital: "Port Vila",
    continent: "OC",
    traditional_cn: "萬那杜",
    simplified_cn: "瓦努阿图"
  },
  {
    iso2: "VA",
    name: "Vatican",
    iso3: "VAT",
    unicode: "🇻🇦",
    dial: "+379",
    currency: "EUR",
    capital: "Vatican City",
    continent: "EU",
    traditional_cn: "梵蒂岡",
    simplified_cn: "梵蒂冈"
  },
  {
    iso2: "VE",
    name: "Venezuela",
    iso3: "VEN",
    unicode: "🇻🇪",
    dial: "+58",
    currency: "VEF",
    capital: "Caracas",
    continent: "SA",
    traditional_cn: "委內瑞拉",
    simplified_cn: "委内瑞拉"
  },
  {
    iso2: "VN",
    name: "Vietnam",
    iso3: "VNM",
    unicode: "🇻🇳",
    dial: "+84",
    currency: "VND",
    capital: "Hanoi",
    continent: "AS",
    traditional_cn: "越南",
    simplified_cn: "越南"
  },
  {
    iso2: "WF",
    name: "Wallis and Futuna",
    iso3: "WLF",
    unicode: "🇼🇫",
    dial: "+681",
    currency: "XPF",
    capital: "Mata Utu",
    continent: "OC",
    traditional_cn: "瓦利斯和富圖納",
    simplified_cn: "瓦利斯和富图纳"
  },
  {
    iso2: "EH",
    name: "Western Sahara",
    iso3: "ESH",
    unicode: "🇪🇭",
    dial: "+212",
    currency: "MAD",
    capital: "El-Aaiun",
    continent: "AF",
    traditional_cn: "西撒哈拉",
    simplified_cn: "西撒哈拉"
  },
  {
    iso2: "YE",
    name: "Yemen",
    iso3: "YEM",
    unicode: "🇾🇪",
    dial: "+967",
    currency: "YER",
    capital: "Sanaa",
    continent: "AS",
    traditional_cn: "葉門",
    simplified_cn: "也门"
  },
  {
    iso2: "ZM",
    name: "Zambia",
    iso3: "ZMB",
    unicode: "🇿🇲",
    dial: "+260",
    currency: "ZMK",
    capital: "Lusaka",
    continent: "AF",
    traditional_cn: "尚比亞",
    simplified_cn: "赞比亚"
  },
  {
    iso2: "ZW",
    name: "Zimbabwe",
    iso3: "ZWE",
    unicode: "🇿🇼",
    dial: "+263",
    currency: "ZWL",
    capital: "Harare",
    continent: "AF",
    traditional_cn: "辛巴威",
    simplified_cn: "津巴布韦"
  }
];
