import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import ReactHtmlParser from "react-html-parser";
import toArray from "lodash/toArray";
import isEqual from "lodash/isEqual";

import "./Blog.scss";
import RecentArticles from "./RecentArticle";

function Blog(props) {
  const [blog, setBlog] = useState(toArray(I18n.t("blog").articles).reverse());

  useEffect(() => {
    if (!isEqual(blog, toArray(I18n.t("blog").articles).reverse())) {
      setBlog({
        blog: toArray(I18n.t("blog").articles).reverse()
      });
    }
  }, [blog]);

  return (
    <div className="blog-wrapper blog-view">
      <div className="container blog">
        <div className="row">
          <div className="col-md-7 col-sm-12">
            <div className="blog-post">
              {blog.map((val, index) => {
                return (
                  <div className="blog-content" key={index}>
                    <h2
                      className="blog-title"
                      onClick={() => {
                        let blogRoute = val.route.split(" ").join("_");
                        props.history.push(`/blog/${blogRoute}`);
                      }}
                    >
                      {val.title}
                    </h2>
                    <img
                      src={val.image}
                      alt="banner"
                      onClick={() => {
                        let blogRoute = val.route.split(" ").join("_");
                        props.history.push(`/blog/${blogRoute}`);
                      }}
                    />
                    <div className="tag-user">
                      <div className="tag-container">
                        {toArray(val.tags).map(tag => (
                          <span className="tag">{tag}</span>
                        ))}
                      </div>
                      <div className="by">
                        {I18n.t("blog.by")} {val.by}
                      </div>
                    </div>
                    <div className="content">
                      {ReactHtmlParser(val.excerpt)}{" "}
                      <Link to={`/blog/${val.route.split(" ").join("_")}`}>
                        {I18n.t("blog.read_more")}
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-md-1 col-sm-12"></div>

          <div className="col-md-4 col-sm-12 recent-article">
            <h3 className="mb-3"> {I18n.t("blog.recent_articles")}</h3>

            {blog.slice(0, 3).map((val, rIndex) => (
              <RecentArticles blog={val} key={rIndex} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    i18n: state.i18n
  };
};

export default withRouter(connect(mapStateToProps)(Blog));
