import blog_GB from '../blogsContent/blogs_GB';
import pricing_content from "../blogsContent/pricing";
import terms_content from "../blogsContent/terms";

export default {
  title: "IDEX - Innovation & Project Management Software",
  header: {
    home: "Home",
    features: "Features",
    howitworks: "How It Works",
    testimonials: "Testimonials",
    blog: "Blog",
    contactus: "Contact Us",
    pricing: "Pricing",
    tryFree: "Try it for free"
  },
  modal: {
    try: `Try IDEX for free`,
    totry: `To try IDEX Innovation & Project Management Software for free, enter your details below.${" "} We will be in touch shortly with your login details ${" "}. llllll Rest assured, your data is secure with us. `,
    fullname: `Full Name`,
    workemail: `Work Email`,
    company: `Company`,
    phone: `Phone number`,
    optional: `(optional)`,
    submit: `Submit`,
    close: `Close`
  },
  footer: {
    idex: "IDEX INNOVATION",
    turnIdea: "Turn ideas into innovations",
    startCollecting:
      "Start collecting ideas for the next big thing and implement them in your organization now!",
    getStarted: "Get started!",
    productTitle: "Product",
    productPricing: "Pricing",
    productProjectMgnt: "Project Management",
    productIdeaMgnt: "Idea & Innovation Management",
    companyTitle: "Company",
    companyBlog: "Blog",
    companyAboutUs: "About Us",
    companyTerms: "Terms and Conditions",
  },
  headSection: {
    moveForward: "Moving innovation forward",
    transform: "Innovation & project management software for collecting, curating, and implementing ideas.",
    expIDEX: "Experience IDEX",
    video: "https://youtu.be/M3WoC6vuspw"
  },
  seo: {
    homePage: {
      title: "IDEX | Innovation & Project Management Software",
      description: "IDEX provides innovation & project management software for collecting, curating, and implementing ideas from employees and customers.",
      keyword: "innovation management, idea management, innovation management tool, idea management tool, corporate innovation, innovation management software, project management, project management software, project management tool",
    },
    blogPage: {
      title: "Blog | IDEX Innovation & Project Management Software",
      description: "IDEX provides innovation & project management software for collecting, curating, and implementing ideas from employees and customers.",
      keyword: "innovation management, idea management, innovation management tool, idea management tool, corporate innovation, innovation management software, innovation management resources, project management, project management software, project management tool",
    },
    pricingPage: {
      title: "Pricing | IDEX Innovation & Project Management Software",
      description: "IDEX provides innovation & project management software for collecting, curating, and implementing ideas from employees and customers.",
      keyword: "innovation management, idea management, innovation management tool, idea management tool, corporate innovation, innovation management software, project management, project management software, project management tool",
    },
    termsPage: {
      title: "Terms & Conditions | IDEX Innovation & Project Management Software",
      description: "IDEX provides innovation & project management software for collecting, curating, and implementing ideas from employees and customers.",
      keyword: "innovation management, idea management, innovation management tool, idea management tool, corporate innovation, innovation management software, project management, project management software, project management tool",
    },
    aboutUsPage: {
      title: "About Us | IDEX Innovation & Project Management Software",
      description: "IDEX provides innovation & project management software for collecting, curating, and implementing ideas from employees and customers.",
      keyword: "innovation management, idea management, innovation management tool, idea management tool, corporate innovation, innovation management software, project management, project management software, project management tool",
    },
    projectMgntPage: {
      title: "Project Management | IDEX Innovation & Project Management Software",
      description: "IDEX provides innovation & project management software for collecting, curating, and implementing ideas from employees and customers.",
      keyword: "innovation management, idea management, innovation management tool, idea management tool, corporate innovation, innovation management software, project management, project management software, project management tool",
    },
    ideaMgntPage: {
      title: "Idea & Innovation Management | IDEX Innovation & Project Management Software",
      description: "IDEX provides innovation & project management software for collecting, curating, and implementing ideas from employees and customers.",
      keyword: "innovation management, idea management, innovation management tool, idea management tool, corporate innovation, innovation management software, project management, project management software, project management tool",
    },
  },
  benefits: {
    trial_info: "You can try Business plan for 14-days for free, and downgrade to Starter plan at any time.",
    employee: "More Ideas and Better Product",
    engage:
      "Involve stakeholders from all sides of your business to collaborate, refine, and prioritize the best ideas. Empower company-wide innovation, foster a culture of ownership, and improve products, processes, and customer satisfaction.",
    continuous: "Stay Organized and Connected",
    stakeholder:
      "Work with your team together in one space to break down department, language, and geographic barriers. Stay on top of things across different teams and projects with easy tracking and comprehensive roadmap.",
    high: "High Return on Investment",
    crowdsource:
      "Manage day to day tasks with a vision. Get the job done, while create the next big thing with people who know your business the best. Incremental changes plus breakthrough innovations, generate billions of dollars in return."
  },
  pricing_info: {
    save30: "Save 30%",
    monthly: "Monthly",
    annual: "Annual",
    select_team_size: "Select your team size"
  },
  howworks: {
    title: "How it works",
    ideate: "Ideate & Collect",
    harness:
      "Harness the collective innovative power of your organization by allowing anyone to submit an idea, openly or anonymously. Find and prioritize the best ideas to implement, and turn them into tasks to realise them.",
    engage: "Plan & Organize",
    easysearch:
      "Create projects within your teams to manage your workflow. You can use projects for just about anything, from managing sprints, deadline-driven scheduling, ongoing processes, or storing backlog.",
    evaluate: "Visualize & Implement",
    prioritize:
      "Switch between list, board, or calendar view to visualize your work. Use list view to prioritize tasks and assign them. Use board view to identify the stages of the tasks and focus on things at hand. Use calendar view to map out tasks over time and give the entire team a common vision to work towards.",
  },
  trusty: {
    title: "Trusted by",
    identify:
      "We use IDEX to capture innovative ideas from our employees, turn those ideas into actionable items, and use the project management side of it across different teams to manage and track the progress of tasks. It saves us tremendous time have all of our information in one central place.  ",
    beforeIDEX:
      "Before IDEX, we used email threads to exchange and manage ideas. Now our employees are able to easily submit their ideas in one platform. IDEX really helps us to increase efficiency in collecting ideas and selecting actionable ideas to implement.",
    card1Name: "Jason Peterson",
    card1Position: "Product Director",
    card2Name: "Matt Stewart",
    card2Position: "Managing Director"
  },
  about_us: {
    title: "About Us",
    description1: "We are an innovation driven company that live for new ideas, technologies, and enable teams small and big to innovate and see their ideas come to life.", 
    description2: "We are a team of innovation experts and technology enthusiasts. We value individual contribution as well as team work. Individually, we are the masters at our craft; together, we form an agile team that can adapt to any changes.", 
    description3: "We work remotely. Some of us has never met but we work together towards a common vision, that is, making innovation work, and making work efficient.",
    valueTitle: "Our Core Values",
    valueSubtitle1: "International approach",
    valueSubtitle2: "Inclusive and transparent culture",
    valueSubtitle3: "Delivering instant solutions",
    valueDescription1: "We speak different languages but we find mutual understanding. When developing IDEX, we always considering multicultural aspects of users from all over the world.",
    valueDescription2: "As we working towards goals, our approach is to be mindful of the people around us and of how we can help each other.",
    valueDescription3: "We know the value of well-earned respect and constructive feedback. We never settle for “good enough” knowing we can do better. ",
  },
  projectMgnt: {
    title: "Project Management",
    description: "From small tasks to the big picture, IDEX project management allows you to plan, track, and deliver your team’s best work all in one place. With the help of great visualization, managing tasks and workflow has never been easier.",
    listviewTitle: "List view",
    listviewDescription: "With lists, teams see immediately what they need to do, when they need to start and finish it, which tasks are a priority, their status, and the estimated time to complete them.",
    boardviewTitle: "Board view",
    boardviewDescription: "Identify the stages of the tasks and focus on things at hand easily. See what’s important and where things are getting stuck. Focusing on your tasks while have the complete picture of the project.",
    calendarviewTitle: "Calendar view",
    calendarviewDescription: "Map out tasks over time, see what has been done, what’s ongoing, and what’s upcoming. Give the entire team a common vision to work towards.",
  },
  ideaMgnt: {
    title: "Idea & Innovation Management",
    description: "IDEX allows you to collect and curate ideas from anywhere - from a feature request from a customer, a new integration that would help streamline an internal process, to a breakthrough innovation in your industry - then transform these into actionable items. ",
    teamIdeaTitle: "Collect ideas from everywhere",
    teamIdeaDescription: "Crowdsource ideas from everyone involved in your business. Easy to use interface that allows your team to add ideas in minutes. ",
    ideaTaskTitle: "Refine ideas with ease",
    ideaTaskDescription: "Utilize the collective intelligence of your employees to comment on the ideas, refine them, and transform them into tasks to work on.",
    ideasOverviewTitle: "Evaluate and select the best ideas",
    ideasOverviewDescription1: "Identify the best ideas to implement in a straightforward manner. And implement those ideas with",
    ideasOverviewDescription2: "IDEX project management",
    ideasOverviewDescription3: ".",

  },
  trial: {
    title: "Ready to dive in?",
    button: "Try for free",
    phrae: "14-day free trial ｜ No credit card needed ｜ Start in minutes",
  },
  resource: {
    title: "Resources",
    learn:
      "Learn more on how to successfully manage innovation and projects at your organization.",
    by: "By",
    articles: "See all articles"
  },
  contact: {
    keepTouch: "Keep in touch",
    connect: "Connect with an innovation & project management specialist. ",
    name: "Name",
    company: "Company",
    workEmail: "Work email",
    message: "Message",
    submit: "Submit",
    empty_all: "Please provide your details to proceed",
    empty_email: "Please enter a valid email address",
    contact_success_message:
      "Your message has been sent. We will be in touch with you shortly.",
    message_sent_successfull:
      "Your request has been submitted successfully. We will send a link to the trial system to your email shortly. Please check your email in 1 minute."
  },
  pricing: pricing_content.GB,
  terms: terms_content.GB,

  blog: {
    blog: `Blog`,
    recent_articles: `Recent Articles`,
    read: `Read`,
    read_more: `Read More`,
    previous_articles: `Previous Article`,
    articles: blog_GB,
    next_article: "Next Article",
    previous: "Previous",
    by: "By",
    share: "Share"
  },
  
  modalForm: {
    header: "IDEX | Innovation & Project Management Software",
    try_free: "IDEX Innovation & Project Management Software",
    para1:
      "Enter your details below to start your free trial of IDEX.",
    para2:
      "Your own space will be ready in less than 60 seconds once we receive your information.",
    fullName: "Full Name",
    emailAddress: "Work Email",
    company: "Company",
    phoneNumber: "Phone Number",
    optional: "optional",
    submit: "Submit",
    close: "Close",
    passwordNotMatch: "Passwords don't match",
    required: "* field is required",
    password: "Password",
    confirmPassword: "Confirm Password",
    email_exists_error:
      "Email already used. Please try to login or create a new account. ",
    field_length_long: "%{field} length exceeded",
    field_length_short: "%{field} must be %{number} characters or longer.",
    loadingTexts: {
      message0: "Checking your information ...",
      message1: "Registering your details...",
      message2: "Creating your own space...",
      message3: "Applying colors...",
      message4: "Fine-tuning the pixels...",
      message5: "Spinning up the server...",
      message6: "Setting you up as admin...",
      message7: "Customizing the application...",
      message8: "Finishing the setup..."
    },
    password_placeholder: "8 characters or longer",
    subdomain: "Subdomain",
    subdomain_in_use: "Subdomain already exists, please login or use a different subdomain"
  }
};
