import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/all.css";
import "./index.scss";
import ModalForm from "../components/ModalForm/ModalForm";

function TrialPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="trial-container">
      <ModalForm />
      <div className="footer-wrapper trial-footer container-fluid">
        <Row className="footer-line mt-4" />
        <Row className="footer-content footer-copyright">
          <Col
            md={{ order: 1 }}
            xs={{ order: 2 }}
            className="footer-icons"
          >
            <div className="pt-2 pb-3 footer-address copyright-align mt-2 d-flex justify-content-center">
              &copy; {(new Date().getFullYear())} - IDEX | Jouster FZE
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TrialPage;