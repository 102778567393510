import React, { Component } from "react";
import { I18n, setLocale } from "react-redux-i18n";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Experiment, Variant, emitter, experimentDebugger } from "@marvelapp/react-ab-test";
import ReactGA from "react-ga";

import "react-flags-select/css/react-flags-select.css";
import "react-flags-select/scss/react-flags-select.scss";
import "./Header.scss";
import FlagDropdown from './FlagDropdown';
import logoImg from "../../assets/images/logo.png";

let trackingId = "UA-137478659-1";
ReactGA.initialize(trackingId);
ReactGA.set({ dimension14: "Sports" });

experimentDebugger.enable();
emitter.defineVariants(
  "Header-test",
  ["Try-It-For-Free", "Experience-IDEX", "Get-Started"],
  [33, 33, 33]
);

class Header extends Component {
  state = {
    mobileHeader: false,
    mobileDropdown: false,
    dropdown: false,
    show: false,
    lang: ''
  };
  myRef = React.createRef();
  dropdownRef = React.createRef();

  handleClick = () => {
    if (!this.state.mobileHeader) {
      document.addEventListener('mousedown', this.handleClickOutside, false);
    } else {
      document.removeEventListener('mousedown', this.handleClickOutside, false);
    };

    this.setState(prevState => ({
      mobileHeader: !prevState.mobileHeader,
    }));
  };

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  onclick(e) {
    emitter.emitWin("Header-test");
  };

  componentDidMount() {
    let lang = 'GB';
    if (localStorage.getItem("lang")) {
      this.props.dispatch(setLocale(localStorage.getItem("lang")));
      lang = localStorage.getItem("lang");
    };
    this.setState({ lang });
    document.addEventListener('mousedown', this.handleClickOut);
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOut);
  };

  handelShowModal = () => {
    this.onclick();
    this.setState({
      show: true
    });
    this.props.tryIt.isSuccess = false;
  };
  handleLogoClick = () => {
    if (this.props.location.pathname === "/") {
      window.scrollTo(0, 0);
    } else {
      this.props.history.push("/");
    };
  };

  handleDropdownClick = () => {
    const { dropdown, mobileDropdown } = this.state;
    this.setState({
      dropdown: !dropdown,
      mobileDropdown: !mobileDropdown
    });
  };

  handleClickOut = (event) => {
    if (this.dropdownRef && !this.dropdownRef.current.contains(event.target)) {
      this.setState(prevState => ({
        dropdown: false
      }));
    };
  };

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      document.removeEventListener('mousedown', this.handleClickOutside, false);
      this.setState(prevState => ({
        mobileHeader: !prevState.mobileHeader,
      }));
    };
  };

  handleSelect = val => {
    localStorage.setItem("lang", val);
    document.title = I18n.t("title");
  };

  handleClickBtn = () => {
    this.setState({
      mobileHeader: false,
      mobileDropdown: false,
      dropdown: false
    });
  };

  handleFreeTrial = () => {
    window.open("/free-trial", "_self");
  }

  render() {
    const { mobileHeader, mobileDropdown, dropdown } = this.state;
    return (
      <div ref={this.setWrapperRef}>
        <div
          className={(!mobileHeader && "hide-header ") + " header-wrapper"}
          ref={this.setWrapperRef}
        >
          <div className="header-logo-wrapper">
            <img
              src={logoImg}
              onClick={this.handleLogoClick}
              alt="IDEX Logo"
            />
            <i
              className="fa fa-times-thin "
              aria-hidden="true"
              onClick={this.handleClick}
            ></i>
          </div>
          <div className="menu">
            <div className="menu-group">
              <Link
                to="/"
                className={`${this.props.location.pathname === "/" ? 'active' : ''}  menu-item`}
                onClick={this.handleClick}
              >
                {I18n.t("header.home")}
              </Link>

              <Link
                to="#"
                className="menu-item features-link relative"
                ref={this.dropdownRef}
                onClick={this.handleDropdownClick}
              >
                {dropdown && (
                  <div className="features-dropdown-wrapper">
                    <ul>
                      <li>
                        <Link to="/idea-management">
                          {I18n.t('ideaMgnt.title')}
                        </Link>
                      </li>
                      <li>
                        <Link to="/project-management">
                          {I18n.t('projectMgnt.title')}
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
                <div className="d-flex align-items-center">
                  <span>
                    {I18n.t("header.features")}
                  </span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className={`bi bi-chevron-down ${dropdown && 'rotate-180'}`} viewBox="0 0 16 16">
                    <path stroke="rgba(51, 51, 51, 0.87)" strokeWidth="1.5" fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </div>
                <div className={`features-list-mobile ${mobileDropdown ? 'panel-collapse' : 'panel-close'}`}>
                  <ul>
                    <li>
                      <Link to="/idea-management" onClick={this.handleClickBtn}>
                        {I18n.t('ideaMgnt.title')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/project-management" onClick={this.handleClickBtn}>
                        {I18n.t('projectMgnt.title')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </Link>
              <Link
                to="/blog"
                className={`${this.props.location.pathname.includes("/blog") ? 'active' : ''}  menu-item`}
                onClick={this.handleClick}
              >
                {I18n.t("header.blog")}
              </Link>
              <Link
                to="/pricing"
                className={`${this.props.location.pathname === "/pricing" ? 'active' : ''} menu-item`}
                onClick={this.handleClick}
              >
                {I18n.t("header.pricing")}
              </Link>
            </div>
            <div className="lang-section">
              {this.state.lang && <FlagDropdown
                lang={localStorage.getItem("lang") || "GB"}
                changeLang={val => {
                  this.props.dispatch(setLocale(val));
                  this.handleSelect(val);
                  this.handleClick();
                }}
              />}
            </div>
            <Experiment ref="ab-more-info" name="Header-test">
              <Variant name="Try-It-For-Free">
                <div
                  className="trial-btn"
                  onClick={() => this.handleFreeTrial()}
                >
                  {I18n.t("header.tryFree")}
                </div>
              </Variant>
              <Variant name="Experience-IDEX">
                <div
                  className="trial-btn"
                  onClick={() => this.handleFreeTrial()}
                >
                  {I18n.t("headSection.expIDEX")}
                </div>
              </Variant>
              <Variant name="Get-Started">
                <div
                  className="trial-btn"
                  onClick={() => this.handleFreeTrial()}
                >
                  {I18n.t("footer.getStarted")}
                </div>
              </Variant>
            </Experiment>
          </div>
        </div>
        <div
          className={
            (mobileHeader && "hide-header ") + " mobile-small-header"
          }
        >
          <div className="header-logo-wrapper">
            <i
              className="fa fa-bars"
              aria-hidden="true"
              onClick={this.handleClick}
            ></i>
            <img
              src={logoImg}
              alt="IDEX Logo"
              onClick={this.handleLogoClick}
            />
          </div>
          <div
            className="trial-btn-mobile"
            onClick={() => this.handleFreeTrial()}
          >
            {I18n.t("header.tryFree")}
          </div>
        </div>
      </div>
    );
  };
};

function mapStateToProps(state) {
  return {
    i18n: state.i18n,
    tryIt: state.tryItFree.tryItData
  };
};

export default withRouter(connect(mapStateToProps)(Header));

emitter.addPlayListener("Header-test", function (experimentName, variantName) {
});

emitter.addWinListener("Header-test", function (experimentName, variantName) {
  ReactGA.event({
    category: variantName,
    action: experimentName
  });
});
