import React from "react";
import Loader from "react-loader";
import "./CustomLoader.scss";

const CustomLoader = React.memo(props => {
  return (
    <Loader
      text="Loading your content..."
      loaded={false}
      lines={13}
      length={20}
      width={10}
      radius={30}
      corners={1}
      rotate={0}
      direction={1}
      color="#000"
      speed={1}
      trail={60}
      shadow={false}
      hwaccel={false}
      className="spinner"
      zIndex={2e9}
      top="50%"
      left="50%"
      scale={1.0}
      loadedClassName="loadedContent"
    />
  );
});

export default function ContactForm({ text, loaded }) {
  return (
    <div className="loader-wrapper">
      <div className="loader-content">
        {!loaded && <CustomLoader />}
        <div className="loader-message">{text}</div>
      </div>
    </div>
  );
};
