import blog_GB from '../blogsContent/blogs_GB';
import pricing_content from "../blogsContent/pricing";
import terms_content from "../blogsContent/terms";

export default {
  title: "IDEX - Software de Innovación y Gestión de Proyectos",
  header: {
    home: "Hogar",
    features: "Características",
    howitworks: "Cómo funciona",
    testimonials: "Testimonios",
    blog: "Blog",
    contactus: "Contáctenos",
    pricing: "Precios",
    tryFree: "Pruébalo gratis"
  },
  modal: {
    try: `Prueba IDEX gratis`,
    totry: `Para probar el software de gestión de proyectos e innovación de IDEX de forma gratuita, ingrese sus datos a continuación. $ {""} En breve nos comunicaremos con sus datos de inicio de sesión $ {""}. llllll Tenga la seguridad de que sus datos están seguros con nosotros. `,
    fullname: `Nombre`,
    workemail: `Email del trabajo`,
    company: `Empresa`,
    phone: `Número de teléfono`,
    optional: `(opcional)`,
    submit: `Enviar`,
    close: `Cerrar`
  },
  footer: {
    idex: "IDEX INNOVATION",
    turnIdea: "Convierta las ideas en innovaciones",
    startCollecting:
      "¡Empiece a recopilar ideas para el próximo gran éxito e impleméntelas en su organización ahora!",
    getStarted: "¡Empezar!",
    productTitle: "Producto",
    productPricing: "Precios",
    productProjectMgnt: "Gestión de Proyectos",
    productIdeaMgnt: "Gestión de Ideas e Innovación",
    companyTitle: "Empresa",
    companyBlog: "Blog",
    companyAboutUs: "Sobre Nosotros",
    companyTerms: "Términos y condiciones",
  },
  headSection: {
    moveForward: "Innovación mas allá",
    transform: "Software de gestión de proyectos e innovación empresarial para recopilar, seleccionar e implementar ideas.",
    expIDEX: "Experimente IDEX",
    video: "https://youtu.be/M3WoC6vuspw"
  },
  seo: {
    homePage: {
      title: "IDEX | Software de Innovación y Gestión de Proyectos",
      description: "IDEX proporciona software de gestión de proyectos e innovación empresarial para recopilar, seleccionar e implementar ideas de empleados y clientes.",
      keyword: "gestión de la innovación, gestión de ideas, herramienta de gestión de la innovación, herramienta de gestión de ideas, innovación corporativa, software de gestión de la innovación, gestión de proyectos, software de gestión de proyectos, herramienta de gestión de proyectos",
    },
    blogPage: {
      title: "Blog | IDEX Software de Innovación y Gestión de Proyectos",
      description: "IDEX proporciona software de gestión de proyectos e innovación empresarial para recopilar, seleccionar e implementar ideas de empleados y clientes.",
      keyword: "gestión de la innovación, gestión de ideas, herramienta de gestión de la innovación, herramienta de gestión de ideas, innovación corporativa, software de gestión de la innovación, gestión de proyectos, software de gestión de proyectos, herramienta de gestión de proyectos",
    },
    pricingPage: {
      title: "Planes & Precios | IDEX Software de Innovación y Gestión de Proyectos",
      description: "IDEX proporciona software de gestión de proyectos e innovación empresarial para recopilar, seleccionar e implementar ideas de empleados y clientes.",
      keyword: "gestión de la innovación, gestión de ideas, herramienta de gestión de la innovación, herramienta de gestión de ideas, innovación corporativa, software de gestión de la innovación, gestión de proyectos, software de gestión de proyectos, herramienta de gestión de proyectos",
    },
    termsPage: {
      title: "Términos & Condiciones | IDEX Software de Innovación y Gestión de Proyectos",
      description: "IDEX proporciona software de gestión de proyectos e innovación empresarial para recopilar, seleccionar e implementar ideas de empleados y clientes.",
      keyword: "gestión de la innovación, gestión de ideas, herramienta de gestión de la innovación, herramienta de gestión de ideas, innovación corporativa, software de gestión de la innovación, gestión de proyectos, software de gestión de proyectos, herramienta de gestión de proyectos",
    },
    aboutUsPage: {
      title: "Sobre Nosotros | IDEX Software de Innovación y Gestión de Proyectos",
      description: "IDEX proporciona software de gestión de proyectos e innovación empresarial para recopilar, seleccionar e implementar ideas de empleados y clientes.",
      keyword: "gestión de la innovación, gestión de ideas, herramienta de gestión de la innovación, herramienta de gestión de ideas, innovación corporativa, software de gestión de la innovación, gestión de proyectos, software de gestión de proyectos, herramienta de gestión de proyectos",
    },
    projectMgntPage: {
      title: "Gestión de Proyectos | IDEX Software de Innovación y Gestión de Proyectos",
      description: "IDEX proporciona software de gestión de proyectos e innovación empresarial para recopilar, seleccionar e implementar ideas de empleados y clientes.",
      keyword: "gestión de la innovación, gestión de ideas, herramienta de gestión de la innovación, herramienta de gestión de ideas, innovación corporativa, software de gestión de la innovación, gestión de proyectos, software de gestión de proyectos, herramienta de gestión de proyectos",
    },
    ideaMgntPage: {
      title: "Gestión de Ideas e Innovación | IDEX Software de Innovación y Gestión de Proyectos",
      description: "IDEX proporciona software de gestión de proyectos e innovación empresarial para recopilar, seleccionar e implementar ideas de empleados y clientes.",
      keyword: "gestión de la innovación, gestión de ideas, herramienta de gestión de la innovación, herramienta de gestión de ideas, innovación corporativa, software de gestión de la innovación, gestión de proyectos, software de gestión de proyectos, herramienta de gestión de proyectos",
    },
  },
  benefits: {
    trial_info: "Puede probar el plan Business durante 14 días de forma gratuita y cambiar al plan Starter en cualquier momento.",
    employee: "Más Ideas y Mejor Producto",
    engage:
      "Involucre a las partes interesadas de todos los lados de su negocio para colaborar, perfeccionar y priorizar las mejores ideas. Potenciar la innovación en toda la empresa, fomentar una cultura de propiedad y mejorar los productos, los procesos y la satisfacción del cliente.",
    continuous: "Mantente Organizado y Conectado",
    stakeholder:
      "Trabaje con su equipo en un solo espacio para derribar las barreras geográficas, idiomáticas y de departamento. Manténgase al tanto de las cosas en diferentes equipos y proyectos con un seguimiento sencillo y una hoja de ruta completa.",
    high: "Alto Retorno de la Inversión",
    crowdsource:
      "Administre las tareas del día a día con una visión. Haga el trabajo mientras crea la próxima gran novedad con las personas que mejor conocen su negocio. Los cambios incrementales más las innovaciones revolucionarias generan miles de millones de dólares a cambio."
  },
  pricing_info: {
    save30: "Ahorre 30%",
    monthly: "Mensual",
    annual: "Anual",
    select_team_size: "Seleccione el tamaño de su equipo"
  },
  howworks: {
    title: "Funciona Así",
    ideate: "Idear & Coleccionar",
    harness:
      "Aproveche el poder innovador colectivo de su organización permitiendo que cualquiera presente una idea, de forma abierta o anónima. Encuentre y priorice las mejores ideas para implementar, y conviértalas en tareas para hacerlas realidad.",
    engage: "Planificar & Organizar",
    easysearch:
      "Cree proyectos dentro de sus equipos para administrar su flujo de trabajo. Puede utilizar proyectos para casi cualquier cosa, desde la gestión de sprints, la programación basada en fechas límite, los procesos en curso o el almacenamiento de trabajos pendientes.",
    evaluate: "Visualizar & Implementar",
    prioritize:
      "Cambie entre la vista de lista, tablero o calendario para visualizar su trabajo. Utilice la vista de lista para priorizar tareas y asignarlas. Utilice la vista de tablero para identificar las etapas de las tareas y centrarse en las cosas que tiene entre manos. Use la vista de calendario para trazar tareas a lo largo del tiempo y brindarle a todo el equipo una visión común para trabajar.",
  },
  trusty: {
    title: "Confiado por",
    identify:
      "Usamos IDEX para capturar ideas innovadoras de nuestros empleados, convertir esas ideas en elementos procesables y usar el lado de la administración de proyectos en diferentes equipos para administrar y rastrear el progreso de las tareas. Nos ahorra muchísimo tiempo tener toda nuestra información en un lugar central.",
    beforeIDEX:
      "Antes de IDEX, usábamos hilos de correo electrónico para intercambiar y administrar ideas. Ahora nuestros empleados pueden enviar fácilmente sus ideas en una plataforma. IDEX realmente nos ayuda a aumentar la eficiencia en la recopilación de ideas y la selección de ideas prácticas para implementar.",
    card1Name: "Matt Stewart",
    card1Position: "Gerente General",
    card2Name: "Jason Peterson",
    card2Position: "Director de Producto"
  },
  about_us: {
    title: "Sobre Nosotros",
    description1: "Somos una empresa impulsada por la innovación que vive de las nuevas ideas y tecnologías y permite que los equipos pequeños y grandes innoven y vean cómo sus ideas cobran vida.", 
    description2: "Somos un equipo de expertos en innovación y entusiastas de la tecnología. Valoramos tanto la contribución individual como el trabajo en equipo. Individualmente, somos los maestros en nuestro oficio; juntos formamos un equipo ágil que se adapta a los cambios.", 
    description3: "Trabajamos de forma remota. Algunos de nosotros nunca nos hemos conocido, pero trabajamos juntos hacia una visión común, es decir, hacer que la innovación funcione y que el trabajo sea eficiente.",
    valueTitle: "Nuestros valores mas importantes",
    valueSubtitle1: "Enfoque internacional",
    valueSubtitle2: "Cultura inclusiva y transparente",
    valueSubtitle3: "Entregando soluciones instantáneas",
    valueDescription1: "Hablamos diferentes idiomas pero nos entendemos mutuamente. Al desarrollar IDEX, siempre consideramos los aspectos multiculturales de los usuarios de todo el mundo.",
    valueDescription2: "A medida que trabajamos hacia los objetivos, nuestro enfoque es ser conscientes de las personas que nos rodean y de cómo podemos ayudarnos unos a otros.",
    valueDescription3: "Conocemos el valor del respeto bien merecido y la retroalimentación constructiva. Nunca nos conformamos con “lo suficientemente bueno” sabiendo que podemos hacerlo mejor.",
  },
  projectMgnt: {
    title: "Gestión de Proyectos",
    description: "Desde pequeñas tareas hasta el panorama general, la gestión de proyectos de IDEX le permite planificar, rastrear y entregar el mejor trabajo de su equipo, todo en un solo lugar. Con la ayuda de una excelente visualización, la gestión de tareas y el flujo de trabajo nunca ha sido tan fácil.",
    listviewTitle: "Vista de la lista",
    listviewDescription: "Con las listas, los equipos ven de inmediato lo que deben hacer, cuándo deben comenzar y terminar, qué tareas son prioritarias, su estado y el tiempo estimado para completarlas.",
    boardviewTitle: "Vista de tablero",
    boardviewDescription: "Identifique las etapas de las tareas y concéntrese en las cosas a mano fácilmente. Vea qué es importante y dónde se estancan las cosas. Concéntrese en sus tareas mientras tiene la imagen completa del proyecto.",
    calendarviewTitle: "Vista de calendario",
    calendarviewDescription: "Planifique las tareas a lo largo del tiempo, vea lo que se ha hecho, lo que está en curso y lo que está por venir. Brinde a todo el equipo una visión común para trabajar.",
  },
  ideaMgnt: {
    title: "Gestión de Ideas e Innovación",
    description: "IDEX le permite recopilar y seleccionar ideas desde cualquier lugar, desde una solicitud de función de un cliente, una nueva integración que ayudaría a agilizar un proceso interno, hasta una innovación revolucionaria en su industria, y luego transformarlas en elementos procesables.",
    teamIdeaTitle: "Recoge ideas de todas partes",
    teamIdeaDescription: "Reúna ideas de todos los involucrados en su negocio. Interfaz fácil de usar que le permite a su equipo agregar ideas en minutos.",
    ideaTaskTitle: "Refina tus ideas con facilidad",
    ideaTaskDescription: "Utilice la inteligencia colectiva de sus empleados para comentar las ideas, refinarlas y transformarlas en tareas en las que trabajar.",
    ideasOverviewTitle: "Evaluar y seleccionar las mejores ideas",
    ideasOverviewDescription1: "Identifique las mejores ideas para implementar de manera sencilla. E implemente esas ideas con",
    ideasOverviewDescription2: "la gestión de proyectos IDEX",
    ideasOverviewDescription3: ".",
  },
  trial: {
    title: "¿Listo para sumergirte?",
    button: "Prueba gratis",
    phrae: "Prueba gratuita de 14 días | No se necesita tarjeta de crédito | Empiece en minutos",
  },
  resource: {
    title: "Recursos",
    learn:
      "Obtenga más información sobre cómo gestionar con éxito la innovación y los proyectos en su organización.",
    by: "Por",
    articles: "Ver todos los articulos"
  },
  contact: {
    keepTouch: "Mantenerse en contacto",
    connect: "Conéctese con un especialista en innovación y gestión de proyectos.",
    name: "Nombre",
    company: "Empresa",
    workEmail: "Email del trabajo",
    message: "Mensaje",
    submit: "Enviar",
    empty_all: "Proporcione sus datos para continuar",
    empty_email: "Por favor, introduce una dirección de email válida",
    contact_success_message:
      "Tu mensaje ha sido enviado. Nos pondremos en contacto contigo en breve.",
    message_sent_successfull:
      "Su solicitud se ha enviado correctamente. En breve le enviaremos un enlace al sistema de prueba a su correo electrónico. Por favor revise su correo electrónico en 1 minuto."
  },
  pricing: pricing_content.ES,
  terms: terms_content.GB,

  blog: {
    blog: `Blog`,
    recent_articles: `Artículos Recientes`,
    read: `Leer`,
    read_more: `Lee mas`,
    previous_articles: `Artículo Anterior`,
    articles: blog_GB,
    next_article: "Artículo Siguiente",
    previous: "Anterior",
    by: "Por",
    share: "Compartir"
  },
  modalForm: {
    header: "IDEX | Software de Innovación y Gestión de Proyectos",
    try_free: "IDEX Software de Innovación y Gestión de Proyectos",
    para1:
      "Ingrese sus datos a continuación para comenzar su prueba gratuita de IDEX.",
    para2:
      "Tu propio espacio estará listo en menos de 60 segundos una vez que recibamos tu información.",
    fullName: "Nombre",
    emailAddress: "Email del trabajo",
    company: "Empresa",
    phoneNumber: "Número de teléfono",
    optional: "opcional",
    submit: "Enviar",
    close: "Cerrar",
    passwordNotMatch: "Las contraseñas no coinciden",
    required: "* Se requiere campo",
    password: "Contraseña",
    confirmPassword: "Confirmar contraseña",
    email_exists_error:
      "Correo electronico ya fue usado. Intente iniciar sesión o crear una nueva cuenta.",
    field_length_long: "%{field} ongitud excedida",
    field_length_short: "%{field} debe tener %{number} caracteres o más.",
    loadingTexts: {
      message0: "Comprobando su información ...",
      message1: "Registrando sus datos ...",
      message2: "Creando tu propio espacio ...",
      message3: "Aplicando colores ...",
      message4: "Ajuste de píxeles ...",
      message5: "Poniendo en marcha el servidor ...",
      message6: "Configurándote como administrador ...",
      message7: "Personalización de la aplicación ...",
      message8: "Finalizando la configuración ..."
    },
    password_placeholder: "8 caracteres o más",
    subdomain: "Subdominio",
    subdomain_in_use: "Este subdominio ya existe, por favor inicia sesión o usa un subdominio diferente"
  }
};
