export let variantArr = [
  {
    name: "Try-It-For-Free",
    content: "header.tryFree"
  },
  {
    name: "Experience-IDEX",
    content: "headSection.expIDEX"
  },
  {
    name: "Get-Started",
    content: "footer.getStarted"
  }
];
export default function d() {
  let locatStoregeItem = { ...localStorage };
  let finalArray = [];
  variantArr.forEach((value, i) => {
    let status = false;
    for (let curr in locatStoregeItem) {
      if (value.name === locatStoregeItem[curr]) {
        return (status = false);
      } else {
        status = true;
      }
    }
    if (status) finalArray.push(value);
  });

  return finalArray;
}


export const getLocales = lang => {
  switch (lang) {
    case 'en':
    case 'GB':
    case 'en_US':
    case 'en-US':
      return 'GB';
    case 'es':
    case 'ES':
    case 'es-ar':
    case 'es-bo':
    case 'es-cl':
    case 'es-co':
    case 'es-cr':
    case 'es-do':
    case 'es-ec':
    case 'es-sv':
    case 'es-gt':
    case 'es-hn':
    case 'es-mx':
    case 'es-ni':
    case 'es-pa':
    case 'es-ya':
    case 'es-pe':
    case 'es-pr':
    case 'es-uy':
    case 'es-ve':
      return "ES";
    case 'zh_TW':
    case 'zh-TW':
    case 'zh_HK':
    case 'zh-HK':
    case 'HK':
      return 'HK';
    case 'zh_CN':
    case 'zh-CN':
    case 'CN':
    case 'zh':
      return 'CN';
    default:
      return 'GB';
  }
};
