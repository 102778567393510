import React from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";

import "./Benefits.scss";
import bulbIcon from "../../assets/images/icons/bulb.svg";
import usersIcon from "../../assets/images/icons/users.svg";
import profitIcon from "../../assets/images/icons/profit.svg";

function Benefits() {
  return (
    <div className="benefits-section">
      <div className="benefits-item">
        <div>
          <img src={bulbIcon} alt="..." />
        </div>
        <p>{I18n.t("benefits.employee")}</p>
        <span>{I18n.t("benefits.engage")}</span>
      </div>
      <div className="side-arrow">
        <i className="fa fa-arrow-right" aria-hidden="true"></i>
      </div>
      <div className="benefits-item">
        <div>
          <img src={usersIcon} alt="..." />
        </div>
        <p>{I18n.t("benefits.continuous")}</p>
        <span>{I18n.t("benefits.stakeholder")}</span>
      </div>
      <div className="side-arrow">
        <i className="fa fa-arrow-right" aria-hidden="true"></i>
      </div>
      <div className="benefits-item">
        <div>
          <img src={profitIcon} alt="..." />
        </div>
        <p>{I18n.t("benefits.high")}</p>
        <span>{I18n.t("benefits.crowdsource")}</span>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    i18n: state.i18n
  };
}
export default connect(mapStateToProps)(Benefits);
