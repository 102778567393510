import blog_CN from '../blogsContent/blogs_CN';
import pricing_content from "../blogsContent/pricing";
import terms_content from "../blogsContent/terms";

export default {
  title: "畅意科技 - 创新及项目管理系统",
  header: {
    home: "首页",
    features: "产品功能",
    howitworks: "How It Works",
    testimonials: "Testimonials",
    blog: "资源",
    contactus: "Contact Us",
    pricing: "价格方案",
    tryFree: "免费体验"
  },
  modal: {
    try: `免费体验畅意创新及项目管理系统`,
    totry: `填写下方表格，提交后即可免费体验畅意创新及项目管理系统。我们将尽快与您联系为您提供登录信息。请放心，您的信息是绝对安全的。`,
    fullname: `姓名`,
    workemail: `工作邮箱`,
    company: `公司名称`,
    phone: `联系电话`,
    optional: `可选`,
    submit: `提交`,
    close: `关闭`
  },
  seo: {
    homePage: {
      title: "畅意科技 | 创新及项目管理系统",
      description: "畅意创新及项目管理系统，帮助企业收集，管理和实施来自员工和客户的创新想法，助力企业创新发展。",
      keyword: "创新管理, 创新想法管理, 创新管理工具, 创新想法管理工具, 企业创新, 创新管理软件, 项目管理, 项目管理系统, 项目管理软件, 项目管理工具",
    },
    blogPage: {
      title: "博客 | 畅意创新及项目管理系统",
      description: "畅意创新及项目管理系统，帮助企业收集，管理和实施来自员工和客户的创新想法，助力企业创新发展。",
      keyword: "创新管理, 创新想法管理, 创新管理工具, 创新想法管理工具, 企业创新, 创新管理软件, 创新管理资源, 项目管理, 项目管理系统, 项目管理软件, 项目管理工具",
    },
    pricingPage: {
      title: "价格方案 | 畅意创新及项目管理系统",
      description: "畅意创新及项目管理系统，帮助企业收集，管理和实施来自员工和客户的创新想法，助力企业创新发展。",
      keyword: "创新管理, 创新想法管理, 创新管理工具, 创新想法管理工具, 企业创新, 创新管理软件,项目管理, 项目管理系统, 项目管理软件, 项目管理工具",
    },
    termsPage: {
      title: "条款与条件 | 畅意创新及项目管理系统",
      description: "畅意创新及项目管理系统，帮助企业收集，管理和实施来自员工和客户的创新想法，助力企业创新发展。",
      keyword: "创新管理, 创新想法管理, 创新管理工具, 创新想法管理工具, 企业创新, 创新管理软件,项目管理, 项目管理系统, 项目管理软件, 项目管理工具",
    },
    aboutUsPage: {
      title: "关于我们 | 畅意创新及项目管理系统",
      description: "畅意创新及项目管理系统，帮助企业收集，管理和实施来自员工和客户的创新想法，助力企业创新发展。",
      keyword: "创新管理, 创新想法管理, 创新管理工具, 创新想法管理工具, 企业创新, 创新管理软件,项目管理, 项目管理系统, 项目管理软件, 项目管理工具",
    },
    projectMgntPage: {
      title: "项目管理 | 畅意创新及项目管理系统",
      description: "畅意创新及项目管理系统，帮助企业收集，管理和实施来自员工和客户的创新想法，助力企业创新发展。",
      keyword: "创新管理, 创新想法管理, 创新管理工具, 创新想法管理工具, 企业创新, 创新管理软件,项目管理, 项目管理系统, 项目管理软件, 项目管理工具",
    },
    ideaMgntPage: {
      title: "想法与创新管理 | 畅意创新及项目管理系统",
      description: "畅意创新及项目管理系统，帮助企业收集，管理和实施来自员工和客户的创新想法，助力企业创新发展。",
      keyword: "创新管理, 创新想法管理, 创新管理工具, 创新想法管理工具, 企业创新, 创新管理软件,项目管理, 项目管理系统, 项目管理软件, 项目管理工具",
    },
  },
  footer: {
    idex: "畅意科技",
    turnIdea: "将想法转化为创新成果",
    startCollecting: "现在就开始，在企业内部收集并实施有价值的创新想法！",
    getStarted: "即刻体验",
    productTitle: "产品",
    productPricing: "价格方案",
    productProjectMgnt: "项目管理",
    productIdeaMgnt: "想法与创新管理",
    companyTitle: "公司",
    companyBlog: "博客",
    companyAboutUs: "关于我们",
    companyTerms: "条款与条件",
  },
  headSection: {
    moveForward: "助力企业创新发展",
    transform: "畅意创新及项目管理系统，帮助企业收集，管理和实施创新想法",
    expIDEX: "即刻免费体验",
    video: "https://www.youtube.com/watch?v=Jb8lDt6EaxE"
  },
  benefits: {
    trial_info: "您可以免费试用商业版 14 天，并随时降级到基础版。",
    employee: "收集更多想法，创造更好的产品",
    engage:
      "让企业的所有利益相关者，包括企业的员工，股东，供应商等合作，完善和选择最佳想法。培养企业创新氛围和文化，提升产品，研发过程和客户满意度。",
    continuous: "保持有序运营，增强团队凝聚力",
    stakeholder:
      "在同一个空间里发散思维和管理项目，打破由部门，语言和地理带来的障碍。通过全面的路线图，掌握不同团队和项目的最新情况。",
    high: "高投资回报率",
    crowdsource:
      "在管理日常任务的同时心怀企业的愿景。在同一平台里完成任务，同时与最了解企业的人一起开创未来。逐步的变化外加突破性的创新，实现高投资回报率。"
  },
  pricing_info: {
    save30: "节省30%",
    monthly: "按月收费",
    annual: "按年收费",
    select_team_size: "选择您的团队规模"
  },
  howworks: {
    title: "产品概览",
    ideate: "发散思维 & 评估筛选",
    harness:
      "充分利用集体的创新力量，让每个员工都能提出创新想法，并选择公开或匿名提交。 发掘并选择最佳想法，并把它们转化为任务来实施。",
    engage: "制定计划 & 高效管理",
    easysearch:
      "创建项目来管理您的工作流程。您可将项目用于任何事情，从管理敏捷计划，日程安排，正在进行的流程或产品代办需求。",
    evaluate: "多维可视 & 高效实施",
    prioritize:
      "在列表、看板或日历视图之间切换以让您的工作高度可视。使用列表视图确定任务的优先级并分配它们。使用看板视图确定任务的阶段并专注于手头的事情。使用日历视图来规划一段时间内的任务，并为整个团队提供一个共同的工作愿景。",
  },
  trusty: {
    title: "我们的客户",
    identify:
      "我们使用畅意软件来捕捉我们员工的创新想法，将这些想法转化为可操作的项目，并使用项目管理进行任务的分配及进度的跟进。将我们所有的信息集中在一个平台，这为我们节省了大量时间。",
    beforeIDEX:
      "在使用畅意创新及项目系统之前，我们一直都在使用邮件交换和管理创新想法。现在我们的员工可以非常轻松地在一个平台上提交他们的想法。畅意平台确实帮我们在收集想法和选择可实施的想法上提高了不少效率。",
    card1Name: "Matt Stewart",
    card1Position: "总经理",
    card2Name: "Jason Peterson",
    card2Position: "产品总监"
  },
  about_us: {
    title: "关于我们",
    description1: "我们是一家以创新为导向的公司，为新的想法和技术而生，并支持大大小小的团队进行创新并让他们的想法变为现实。", 
    description2: "我们是一支由创新专家和技术达人组成的团队，我们重视个人贡献和团队合作。就个人而言，我们精通于自己的领域；组合在一起，我们是一个敏捷的团队，可以适应任何变化。", 
    description3: "我们整个团队都在远程工作。我们中的一些人素未谋面，但我们朝着共同的愿景，即，让创新发挥作用，让工作更有效率而共同努力。",
    valueTitle: "我们的核心价值观",
    valueSubtitle1: "多元的角度",
    valueSubtitle2: "包容透明的文化",
    valueSubtitle3: "提供即时的解决方案",
    valueDescription1: "我们说着不同的语言，但我们能够相互理解。在开发畅意软件时，我们总是考虑来自世界各地用户的多元文化。",
    valueDescription2: "当我们朝着目标努力时，我们不会忽视了周围的人，我们互相帮助以实现目标。",
    valueDescription3: "我们知道尊重彼此和建设性反馈的价值。我们永远不会满足于“足够好”，因为我们知道我们可以做得更好。",
  },
  projectMgnt: {
    title: "项目管理",
    description: "从小任务到大愿景，畅意项目管理让您可以在同一空间内计划，跟踪和交付团队的最佳工作。简洁明了的界面让您可以轻轻松松便管理多项任务和工作流程。",
    listviewTitle: "列表视图",
    listviewDescription: "通过列表视图，团队可以立即看到他们需要做什么，何时需要开始和完成，哪些任务具有优先级，它们的状态以及完成这些任务的预估时间。",
    boardviewTitle: "看板视图",
    boardviewDescription: "确定任务的阶段并轻松地专注于手头的事情。及时关注到重要的进展及任务面对的阻碍。专注于您的任务，同时了解整个项目的全貌。",
    calendarviewTitle: "日历视图",
    calendarviewDescription: "把所有任务直观的呈现在甘特图上。直观地查看已经完成，正在进行，以及即将发生的任务，给整个团队一个共同的愿景来努力。",
  },
  ideaMgnt: {
    title: "想法与创新管理",
    description: "畅意系统让您可以从任何地方收集和管理创新想法 - 从客户的功能请求，有助于简化内部流程的措施，到您所在行业的突破性创新 - 然后将这些想法转化为可操作的项目。",
    teamIdeaTitle: "收集来自各处的想法",
    teamIdeaDescription: "从企业的每一个利益相关者那里收集想法。畅意易于使用的界面能帮助您的团队在几分钟内添加有价值的想法。",
    ideaTaskTitle: "轻松完善想法",
    ideaTaskDescription: "利用员工的集体智慧来评论想法，完善它们，并将它们转化为任务来实施。",
    ideasOverviewTitle: "评估和选择最佳创新想法",
    ideasOverviewDescription1: "通过直观的方式选择最佳想法，并通过",
    ideasOverviewDescription2: "畅意项目管理",
    ideasOverviewDescription3: "实施这些想法。",
  },
  trial: {
    title: "为何不即刻试用？",
    button: "免费试用",
    phrae: "免费试用14天 | 无需付款信息 | 片刻即可开始使用",
  },
  resource: {
    title: "创新及项目管理资源",
    learn: "通过我们的博客，进一步了解如何在企业内有效地管理创新。",
    by: "作者",
    articles: "查看所有博客"
  },
  contact: {
    keepTouch: "与我们联系",
    connect: "与我们的创新专家联系，获取更多创新及项目管理资源。",
    name: "姓名",
    company: "公司名称",
    workEmail: "工作邮箱",
    message: "信息",
    submit: "提交",
    empty_all: "请填写您的信息之后再提交。",
    empty_email: "请提交有效的邮箱。",
    contact_success_message: "您的消息已成功发送，我们将尽快联系您。",
    message_sent_successfull:
      "您的试用请求已成功提交。我们将把系统邀请链接发至您的邮箱，请在1分钟后至您的邮箱查收。"
  },
  pricing: pricing_content.CN,
  terms: terms_content.CN,
  blog: {
    blog: `博客`,
    recent_articles: `最新博文`,
    read: `阅读`,
    read_more: `阅读更多`,
    previous_articles: `过往文章`,
    articles: blog_CN,
    next_article: "下一篇文章",
    previous: "以前",
    by: "作者",
    share: " 分享"
  },
  modalForm: {
    header: "免费试用 - 畅意创新及项目管理系统",
    try_free: "免费体验畅意创新及项目管理系统",
    para1: "填写下方表格，提交后我们将为您创建专属于贵企业的空间。",
    para2:
      "无需等待，从提交到系统设置完毕只需短短一分钟。",
    fullName: "姓名",
    emailAddress: "工作邮箱",
    company: "公司名称",
    phoneNumber: "联系电话",
    optional: "可选",
    submit: "提交",
    close: "关闭",
    required: "*为必填项",
    passwordNotMatch: "密码不正确",
    password: "密码",
    confirmPassword: "确认新密码",
    email_exists_error: "邮箱已存在，请登录或重新注册账号。",
    field_length_long: "%{field}长度过长",
    field_length_short: "%{field}必须至少%{number}个字符",
    loadingTexts: {
      message0: "正在检查您的信息…",
      message1: "注册您的信息...",
      message2: "创建您的创新及项目管理空间...",
      message3: "应用颜色...",
      message4: "微调像素...",
      message5: "启动服务器...",
      message6: "将您设置为管理员...",
      message7: "定制系统...",
      message8: "完成设置..."
    },
    password_placeholder: "8个字符或以上",
    subdomain: "子域名",
    subdomain_in_use: "子域名已存在，请登录或使用其他子域名"
  }
};
