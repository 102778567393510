import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";

import "./Terms.scss";

function Terms() {
  return (
    <div className="terms-wrapper">
      <h3>{I18n.t("terms.title")}</h3>
      <p className="subtitles">{I18n.t("terms.introduction")}</p>
      <p className="paragraghs">{I18n.t("terms.introduction_content1")}</p>
      <p className="paragraghs">{I18n.t("terms.introduction_content2")}</p>
      <p className="paragraghs">{I18n.t("terms.introduction_content3")}</p>
      <p className="paragraghs">{I18n.t("terms.introduction_content4")}</p>
      <p className="subtitles">{I18n.t("terms.access")}</p>
      <p className="section_titles">{I18n.t("terms.access_account")}</p>
      <p className="paragraghs">{I18n.t("terms.account_content1")}</p>
      <p className="paragraghs">{I18n.t("terms.account_content2")}</p>
      <p className="paragraghs">{I18n.t("terms.account_content3")}</p>
      <p className="section_titles">{I18n.t("terms.access_join")}</p>
      <p className="paragraghs">{I18n.t("terms.join_content1")}</p>
      <p className="paragraghs">{I18n.t("terms.join_content2")}</p>
      <p className="paragraghs">{I18n.t("terms.join_content3")}</p>
      <p className="section_titles">{I18n.t("terms.access_ownership")}</p>
      <p className="paragraghs">{I18n.t("terms.ownership_content1")}</p>
      <p className="paragraghs">{I18n.t("terms.ownership_content2")}</p>
      <p className="paragraghs">{I18n.t("terms.ownership_content3")}</p>
      <p className="paragraghs">{I18n.t("terms.ownership_content4")}</p>
      <p className="section_titles">{I18n.t("terms.access_inviting")}</p>
      <p className="paragraghs">{I18n.t("terms.inviting_content1")}</p>
      <p className="section_titles">{I18n.t("terms.access_party")}</p>
      <p className="paragraghs">{I18n.t("terms.party_content1")}</p>
      <p className="paragraghs">{I18n.t("terms.party_content2")}</p>
      <p className="paragraghs">{I18n.t("terms.party_content3")}</p>
      <p className="paragraghs">{I18n.t("terms.party_content4")}</p>
      <p className="paragraghs">{I18n.t("terms.party_content5")}</p>
      <p className="subtitles">{I18n.t("terms.content")}</p>
      <p className="paragraghs">{I18n.t("terms.content_content1")}</p>
      <p className="section_titles">{I18n.t("terms.content_who")}</p>
      <p className="paragraghs">{I18n.t("terms.who_content1")}</p>
      <p className="paragraghs">{I18n.t("terms.who_content2")}</p>
      <p className="paragraghs">{I18n.t("terms.who_content3")}</p>
      <p className="paragraghs">{I18n.t("terms.who_content4")}</p>
      <p className="paragraghs">{I18n.t("terms.who_content5")}</p>
      <p className="section_titles">{I18n.t("terms.content_rights")}</p>
      <p className="paragraghs">{I18n.t("terms.rights_content1")}</p>
      <p className="paragraghs">{I18n.t("terms.rights_content2")}</p>
      <p className="section_titles">{I18n.t("terms.content_improve")}</p>
      <p className="paragraghs">{I18n.t("terms.improve_content1")}</p>
      <p className="paragraghs">{I18n.t("terms.improve_content2")}</p>
      <p className="paragraghs">{I18n.t("terms.improve_content3")}</p>
      <p className="section_titles">{I18n.t("terms.content_protecting")}</p>
      <p className="paragraghs">{I18n.t("terms.protecting_content1")}</p>
      <p className="paragraghs">{I18n.t("terms.protecting_content2")}</p>
      <p className="paragraghs">{I18n.t("terms.protecting_content3")}</p>
      <p className="subtitles">{I18n.t("terms.customer")}</p>
      <p className="paragraghs">{I18n.t("terms.customer_content1")}</p>
      <p className="paragraghs">{I18n.t("terms.customer_content2")}</p>
      <p className="paragraghs">{I18n.t("terms.customer_content3")}</p>
      <p className="subtitles">{I18n.t("terms.subscriptions")}</p>
      <p className="paragraghs">{I18n.t("terms.subscriptions_content1")}</p>
      <p className="paragraghs">{I18n.t("terms.subscriptions_content2")}</p>
      <p className="section_titles">{I18n.t("terms.subscriptions_billing")}</p>
      <p className="paragraghs">{I18n.t("terms.billing_content1")}</p>
      <p className="paragraghs">{I18n.t("terms.billing_content2")}</p>
      <p className="paragraghs">{I18n.t("terms.billing_content3")}</p>
      <p className="paragraghs">{I18n.t("terms.billing_content4")}</p>
      <p className="paragraghs">{I18n.t("terms.billing_content5")}</p>
      <p className="section_titles">{I18n.t("terms.subscriptions_renewal")}</p>
      <p className="paragraghs">{I18n.t("terms.renewal_content1")}</p>
      <p className="paragraghs">{I18n.t("terms.renewal_content2")}</p>
      <p className="paragraghs">{I18n.t("terms.renewal_content3")}</p>
      <p className="section_titles">{I18n.t("terms.subscriptions_payment")}</p>
      <p className="paragraghs">{I18n.t("terms.payment_content1")}</p>
      <p className="paragraghs">{I18n.t("terms.payment_content2")}</p>
      <p className="paragraghs">{I18n.t("terms.payment_content3")}</p>
      <p className="paragraghs">{I18n.t("terms.payment_content4")}</p>
      <p className="section_titles">{I18n.t("terms.subscriptions_trials")}</p>
      <p className="paragraghs">{I18n.t("terms.trials_content1")}</p>
      <p className="paragraghs">{I18n.t("terms.trials_content2")}</p>
      <p className="section_titles">{I18n.t("terms.subscriptions_termination")}</p>
      <p className="paragraghs">{I18n.t("terms.termination_content1")}</p>
      <p className="paragraghs">{I18n.t("terms.termination_content2")}</p>
      <p className="paragraghs">{I18n.t("terms.termination_content3")}</p>
      <p className="subtitles">{I18n.t("terms.suspension")}</p>
      <p className="paragraghs">{I18n.t("terms.suspension_content1")}</p>
      <p className="paragraghs">{I18n.t("terms.suspension_content2")}</p>
      <p className="subtitles">{I18n.t("terms.warranty")}</p>
      <p className="paragraghs">{I18n.t("terms.warranty_content1")}</p>
      <p className="paragraghs">{I18n.t("terms.warranty_content2")}</p>
      <p className="paragraghs">{I18n.t("terms.warranty_content3")}</p>
      <p className="subtitles">{I18n.t("terms.liability")}</p>
      <p className="paragraghs">{I18n.t("terms.liability_content1")}</p>
      <p className="paragraghs">{I18n.t("terms.liability_content2")}</p>
      <p className="paragraghs">{I18n.t("terms.liability_content3")}</p>
      <p className="paragraghs">{I18n.t("terms.liability_content4")}</p>
      <p className="paragraghs">{I18n.t("terms.liability_content5")}</p>
      <p className="paragraghs">{I18n.t("terms.liability_content6")}</p>
      <p className="paragraghs">{I18n.t("terms.liability_content7")}</p>
      <p className="subtitles">{I18n.t("terms.indemnification")}</p>
      <p className="paragraghs">{I18n.t("terms.indemnification_content1")}</p>
      <p className="subtitles">{I18n.t("terms.general")}</p>
      <p className="section_titles">{I18n.t("terms.general_copyright")}</p>
      <p className="paragraghs">{I18n.t("terms.copyright_content1")}</p>
      <p className="paragraghs">{I18n.t("terms.copyright_content2")}</p>
      <p className="paragraghs">{I18n.t("terms.copyright_content3")}</p>
      <p className="section_titles">{I18n.t("terms.general_relationship")}</p>
      <p className="paragraghs">{I18n.t("terms.relationship_content1")}</p>
      <p className="section_titles">{I18n.t("terms.general_survival")}</p>
      <p className="paragraghs">{I18n.t("terms.survival_content1")}</p>
      <p className="section_titles">{I18n.t("terms.general_severability")}</p>
      <p className="paragraghs">{I18n.t("terms.severability_content1")}</p>
      <p className="paragraghs">{I18n.t("terms.severability_content1")}</p>
      <p className="section_titles">{I18n.t("terms.general_waiver")}</p>
      <p className="paragraghs">{I18n.t("terms.waiver_content1")}</p>
      <p className="section_titles">{I18n.t("terms.general_entire")}</p>
      <p className="paragraghs">{I18n.t("terms.entire_content1")}</p>
      <p className="paragraghs">{I18n.t("terms.entire_content2")}</p>
      <p className="section_titles">{I18n.t("terms.general_law")}</p>
      <p className="paragraghs">{I18n.t("terms.law_content1")}</p>
      <p className="section_titles">{I18n.t("terms.general_dispute")}</p>
      <p className="paragraghs">{I18n.t("terms.dispute_content1")}</p>
      <p className="paragraghs">{I18n.t("terms.dispute_content2")}</p>
      <p className="paragraghs">{I18n.t("terms.dispute_content3")}</p>
      <p className="paragraghs">{I18n.t("terms.dispute_content4")}</p>
      <p className="section_titles">{I18n.t("terms.general_notice")}</p>
      <p className="paragraghs">{I18n.t("terms.notice_content1")}</p>
      <p className="section_titles">{I18n.t("terms.general_majeure")}</p>
      <p className="paragraghs">{I18n.t("terms.majeure_content1")}</p>
      <p className="section_titles">{I18n.t("terms.general_question")}</p>
      <p className="paragraghs">{I18n.t("terms.question_content1")}</p>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    i18n: state.i18n
  };
};

export default connect(mapStateToProps)(Terms);
