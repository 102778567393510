import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../actions/constants";

const initialState = {
  tryItData: {
    data: {},
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
    isPollLoading: false,
    pollErrorMessage: "",
    isReady: false,
    pollingCount: 0,
    redirectionUrl: ""
  }
};

const sendTryItDetailRequest = (state, action) => {
  return update(state, {
    tryItData: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      isPollLoading: { $set: true }
    }
  });
};

const sendTryItDetailSuccess = (state, action) => {
  return update(state, {
    tryItData: {
      data: { $set: action.payload },
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" }
    }
  });
};

const sendTryItDetailError = (state, action) => {
  return update(state, {
    tryItData: {
      data: { $set: {} },
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload },
      isPollLoading: { $set: false }
    }
  });
};

const getPollProgressRequest = (state, action) => {
  return update(state, {
    tryItData: {
      isReady: { $set: false }
    }
  });
};

const getPollProgressSuccess = (state, action) => {
  return update(state, {
    tryItData: {
      isReady: { $set: true },
      isPollLoading: { $set: false },
      pollingCount: { $set: 0 },
      redirectionUrl: { $set: action.payload }
    }
  });
};

const getPollProgressError = (state, action) => {
  return update(state, {
    tryItData: {
      isPollLoading: { $set: false },
      pollErrorMessage: { $set: action.payload.message },
      isError: { $set: true },
      pollingCount: { $set: 0 }
    }
  });
};

const setPollProgressCounter = (state, action) => {
  return update(state, {
    tryItData: {
      pollingCount: {
        $apply: function(x) {
          return x + 1;
        }
      }
    }
  });
};

export default handleActions(
  {
    [constants.SEND_TRY_IT_DETAIL_REQUEST]: sendTryItDetailRequest,
    [constants.SEND_TRY_IT_DETAIL_SUCCESS]: sendTryItDetailSuccess,
    [constants.SEND_TRY_IT_DETAIL_ERROR]: sendTryItDetailError,
    [constants.GET_POLL_PROGRESS_REQUEST]: getPollProgressRequest,
    [constants.GET_POLL_PROGRESS_SUCCESS]: getPollProgressSuccess,
    [constants.GET_POLL_PROGRESS_ERROR]: getPollProgressError,
    [constants.SET_POLL_PROGRESS_COUNTER]: setPollProgressCounter
  },
  initialState
);
