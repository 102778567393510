import React, { useState } from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";

import "./AboutUs.scss";
import ContactForm from '../ContactForm/ContactForm';
import aboutUsGraphic from "../../assets/images/background/aboutUsGraphic.svg";
import valueIcon1 from "../../assets/images/icons/valueIcon1.svg";
import valueIcon2 from "../../assets/images/icons/valueIcon2.svg";
import valueIcon3 from "../../assets/images/icons/valueIcon3.svg";

function AboutUs () {
  const setError = useState('')[1];

  return (
    <div className="about-us-wrapper">
      <div className="about-us-intro align-left">
        <img className="about-us-graphic" src={aboutUsGraphic} alt="about us" />
        <div className="about-us-content">
          <h2 className="about-us-title">{I18n.t("about_us.title")}</h2>
          <p className="about-us-description">{I18n.t("about_us.description1")}</p>
          <p className="about-us-description">{I18n.t("about_us.description2")}</p>
          <p className="about-us-description">{I18n.t("about_us.description3")}</p>
        </div>
      </div>

      <div className="core-values-wrapper">
        <h2 className="core-value-title">{I18n.t("about_us.valueTitle")}</h2>
        <div className="core-values">
          <div className="values value1">
            <img className="icons" src={valueIcon1} alt="icon1" />
            <div className="value-content">
              <h2 className="value-title">{I18n.t("about_us.valueSubtitle1")}</h2>
              <p className="value-description">{I18n.t("about_us.valueDescription1")}</p>
            </div>
          </div>
          <div className="values value2">
            <img className="icons" src={valueIcon2} alt="icon2" />
            <div className="value-content">
              <h2 className="value-title">{I18n.t("about_us.valueSubtitle2")}</h2>
              <p className="value-description">{I18n.t("about_us.valueDescription2")}</p>
            </div>
          </div>
          <div className="values value3">
            <img className="icons" src={valueIcon3} alt="icon3" />
            <div className="value-content">
              <h2 className="value-title">{I18n.t("about_us.valueSubtitle3")}</h2>
              <p className="value-description">{I18n.t("about_us.valueDescription3")}</p>
            </div>
          </div>
        </div>
      </div>

      <ContactForm 
        sendDataToChild={(data) => setError(data.error)}
        className="contact-form"
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    i18n: state.i18n
  };
};

export default connect(mapStateToProps)(AboutUs);
