import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Row, Col } from 'react-bootstrap';

import './Trial.scss';
import trialImg from '../../assets/images/icons/trial.svg';

function Trial() {
  return (
    <div className="trial-wrapper">
      <Row className="grey-background">
          <Col 
            md={{ span: 2, order: 1 }}
            xs={{ span: 0, order: 2 }}
          />
          <Col 
            md={{ span: 4, order: 1 }}
            xs={{ span: 12, order: 2 }}
            className="trial-text-wrapper"
            >
              <p>{I18n.t('trial.title')}</p>
              <button 
                className="free-trial-button"
                onClick={() => {window.open("/free-trial");}}>
                {I18n.t("trial.button")}
              </button>
              <p className="trial-phrase">
                {I18n.t("trial.phrae")}
              </p>
          </Col>

          <Col 
            md={{ span: 6, order: 1 }}
            xs={{ span: 0, order: 2 }}
            className="trial-image">
            <img src={trialImg} alt="Trial" name="trial" />
          </Col>
      </Row>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    i18n: state.i18n
  };
};

export default connect(mapStateToProps)(Trial);