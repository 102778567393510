import React from 'react'
import { I18n } from 'react-redux-i18n'
import { Col, Row } from "react-bootstrap";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Trans } from 'react-i18next';

import teamIdeas from '../../assets/images/features/ideaMgnt/en/teamIdeas.svg';
import ideaTask from '../../assets/images/features/ideaMgnt/en/ideaTask.svg';
import ideasOverview from '../../assets/images/features/ideaMgnt/en/ideasOverview.svg';
import './IdeaMgnt.scss';

function IdeaMgnt() {
  return (
    <div className="ideaMgnt-container">
      <div className="ideaMgnt-header-text">
        <h2>{I18n.t('ideaMgnt.title')}</h2>
        <p>{I18n.t('ideaMgnt.description')}</p>
      </div>

      <Row className="ideaMgnt-item">
        <Col 
          md={{ span: 2, order: 1 }}
          xs={{ span: 0, order: 3 }}
        />
        <Col 
          md={{ span: 4, order: 1 }}
          xs={{ span: 12, order: 2 }}
          className="ideaMgnt-text-wrapper"
        >
          <h2>{I18n.t('ideaMgnt.teamIdeaTitle')}</h2>
          <p className="ideas-description">{I18n.t('ideaMgnt.teamIdeaDescription')}</p>
        </Col>
        <Col 
          md={{ span: 5, order: 1 }}
          xs={{ span: 12, order: 2 }}
          className="ideaMgnt-image-container"
        >
          <img src={teamIdeas} alt="team ideas" />
        </Col>
        <Col 
          md={{ span: 1, order: 1 }}
          xs={{ span: 0, order: 2 }}
        />
      </Row>

      <Row className="ideaMgnt-item reverse">
        <Col 
          md={{ span: 2, order: 1 }}
          xs={{ span: 0, order: 2 }}
        />
        <Col 
          className="ideaMgnt-text-wrapper"
          md={{ span: 4, order: 1 }}
          xs={{ span: 12, order: 2 }}
        >
          <h2>{I18n.t('ideaMgnt.ideaTaskTitle')}</h2>
          <p className="ideas-description">{I18n.t('ideaMgnt.ideaTaskDescription')}</p>
        </Col>
        <Col 
          className="ideaMgnt-image-container"
          md={{ span: 5, order: 1 }}
          xs={{ span: 12, order: 2 }}
        >
          <img src={ideaTask} alt="idea to task" />
        </Col>
        <Col 
          md={{ span: 1, order: 1 }}
          xs={{ span: 0, order: 2 }}
        />
      </Row>

      <Row className="ideaMgnt-item ">
        <Col 
          md={{ span: 2, order: 1 }}
          xs={{ span: 0, order: 2 }}
        />
        <Col 
          className="ideaMgnt-text-wrapper"
          md={{ span: 4, order: 1 }}
          xs={{ span: 12, order: 2 }}
        >
          <h2>{I18n.t('ideaMgnt.ideasOverviewTitle')}</h2>
          <Trans >
            <p className="ideas-overview-description">
              {I18n.t('ideaMgnt.ideasOverviewDescription1')} <Link to="/project-management">{I18n.t('ideaMgnt.ideasOverviewDescription2')}</Link>{I18n.t('ideaMgnt.ideasOverviewDescription3')}
            </p>
          </Trans>
        </Col>
        <Col 
          className="ideaMgnt-image-container"
          md={{ span: 5, order: 1 }}
          xs={{ span: 12, order: 2 }}
        >
          <img src={ideasOverview} alt="ideas overview" />
        </Col>
        <Col 
          md={{ span: 1, order: 1 }}
          xs={{ span: 0, order: 2 }}
        />
      </Row>      
    </div>
  );
};

function mapStateToProps(state) {
  return {
    i18n: state.i18n
  };
};

export default connect(mapStateToProps)(IdeaMgnt);