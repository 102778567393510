import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import ScrollMemory from 'react-router-scroll-memory';

import RouteComponent from "./RouteComponent";
import history from "./history";
import withTracker from './components/withTracker/withTracker';
import Homepage from "./views/Homepage";
import PricingPage from "./views/PricingPage";
import TermsPage from "./views/TermsPage";
import BlogPage from "./views/BlogPage";
import SelectedBlog from "./views/SelectedBlog";
import TrialPage from "./views/TrialPage";
import AboutUsPage from "./views/AboutUsPage";
import ProjectMgntPage from "./views/ProjectMgntPage";
import IdeaMgntPage from "./views/IdeaMgntPage";

function Routes() {
  return (
    <>
      <Router history={history}>
        <ScrollMemory />
        <RouteComponent>
          <Switch>
            <Route exact path="/" component={withTracker(Homepage)} />
            <Route exact path="/pricing" component={withTracker(PricingPage)} />
            <Route exact path="/terms" component={withTracker(TermsPage)} />
            <Route exact path="/blog" component={withTracker(BlogPage)} />
            <Route exact path="/blog/:route" component={withTracker(SelectedBlog)} />
            <Route exact path="/project-management" component={withTracker(ProjectMgntPage)} />
            <Route exact path="/idea-management" component={withTracker(IdeaMgntPage)} />
            <Route exact path="/free-trial" component={withTracker(TrialPage)} />
            <Route exact path="/about-us" component={withTracker(AboutUsPage)} />
            <Route path="*" component={Homepage} />
          </Switch>
        </RouteComponent>
      </Router>
    </>
  );
};

export default Routes;
