import React, { useState } from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";

import ContactForm from "../ContactForm/ContactForm";

function ContactUsModal(props) {
  const setError = useState(null)[1];

  const { show, handleCloseModal } = props;
  
  const sendDataToChild = data => {
    setError(data);
  };

  return (
    <>
      <Modal
        size={"lg"}
        show={show}
        onHide={handleCloseModal}
        className="modal-wrapper contactus-pricing"
      >
        <Modal.Header>
          <Modal.Title onClick={handleCloseModal}>
            <div className="cursor-pointer">
              <span>{I18n.t("modalForm.close")}</span>
              <i className="fa fa-times" aria-hidden="true"></i>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ContactForm
            sendDataToChild={sendDataToChild}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    i18n: state.i18n
  };
};

export default connect(mapStateToProps)(ContactUsModal);
