export const SEND_CONTACT_DETAIL_REQUEST = "SEND_CONTACT_DETAIL_REQUEST"
export const SEND_CONTACT_DETAIL_SUCCESS = "SEND_CONTACT_DETAIL_SUCCESS"
export const SEND_CONTACT_DETAIL_ERROR = "SEND_CONTACT_DETAIL_ERROR"

export const SEND_TRY_IT_DETAIL_REQUEST = "SEND_TRY_IT_DETAIL_REQUEST"
export const SEND_TRY_IT_DETAIL_SUCCESS = "SEND_TRY_IT_DETAIL_SUCCESS"
export const SEND_TRY_IT_DETAIL_ERROR = "SEND_TRY_IT_DETAIL_ERROR"

export const GET_POLL_PROGRESS_REQUEST = "GET_POLL_PROGRESS_REQUEST";
export const GET_POLL_PROGRESS_SUCCESS = "GET_POLL_PROGRESS_SUCCESS";
export const GET_POLL_PROGRESS_ERROR = "GET_POLL_PROGRESS_ERROR";
export const SET_POLL_PROGRESS_COUNTER = "SET_POLL_PROGRESS_COUNTER";
