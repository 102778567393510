import React, { useState } from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";

import "./ContactUs.scss";
import ContactUsModal from "../ContactUs/ContactUsModal";

function ContactUs(props) {
  const [show, setShow] = useState(false);

  const handelShowModal = () => {
    setShow(true);
  };

  const closeModalHanlder = () => {
    setShow(false);
  }

  return (
    <div className="contact_us_wrapper">
      <div className="row">
        <div className="col-md-12">
          <div className="stay_in_touch_div">
            <h3 className="stay_in_touch">
              {I18n.t("pricing.price4.stay_in_touch")}
            </h3>

            <ContactUsModal
              show={show}
              handleCloseModal={closeModalHanlder}
            />

            <button
              className="btn_contact"
              onClick={handelShowModal}
            >
              {I18n.t("pricing.price4.contact_us")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
  
function mapStateToProps(state) {
  return {
    i18n: state.i18n
  };
};

export default connect(mapStateToProps)(ContactUs);
